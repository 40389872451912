$dark_indigo: #0f2549;
$dusk_blue: #254778;
$white: #fff;
$white_two: #e7e7e7;
$warm_grey: #979797;
$white_three: #d8d8d8;
$white_four: #d6d6d6;
$white_five: #e1e1e1;
$dark_slate_blue: #16325b;
$dark_slate_blue_two: #224372;
$greyish: #b0b0b0;
$greyish_two: #b6b6b6;
$greyish_brown: #403c3c;
$greyish_brown_two: #4a4a4a;
$navy: #03142d;
$black: #000000;
$black_two: #393535;
$dark_salmon: #c95252;
$dropdown-link-disabled-color: #777;
$dropdown-bg: #fff;