.parameters {
    &__container {
        margin-top: 20px;
        height: 468px;
        border-radius: 5px;
        overflow: hidden;
        direction: rtl;
        display: grid;
        grid-template-columns: 180px 0.5fr;
        grid-template-rows: 1;
    }

    &__right-pane {
        grid-column-start: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #3f3f3f;
        font-size: 16px;
        font-weight: bold;
    }

    &__name {
        color: #707070;
        flex: 1;
        display: flex;
        align-items: center;
        padding-right: 10px;

        &--selected {
            background-color: #5e7188;
            color: white;
        }

        &:hover {
            color: white;
            cursor: pointer; //border: 1px solid #8a8888; // color header f9f9f9
        }
    }

    &__fields {
        grid-template-columns: repeat(3, 1fr);
        background-color: #e7e5e5;
        padding: 10px;
        height: 468px;
        overflow: auto;
    }

    &__field {
        padding-bottom: 10px; // display: flex;

        // align-items: center;
        &--small {
            width: 200px;
        }

        &--large {
            width: 300px;
        }

        &--row {
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            //justify-content: space-between;  
        }

        &-text__wrapper {
            border: 1px solid black;
            justify-content: space-between;
        }

        &-icon {
            margin-right: 5px;
            display: flex;
            align-items: center;
            fill: orangered;

            &:hover {
                fill: red;
            }
        }
    }
}

.indent {
    padding-right: 20px;
}

.react-switch {
    vertical-align: middle;
    margin-left: 7px;
    direction: ltr;
}

.scalegroups {
    &__items {}

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: solid 1px #555;
        border-radius: 5px;
        height: 30px;
        width: 90%;
        padding: 10px;
        color: inherit;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;

        &--selected {
            background-color: #ddddff;
        }

        &:hover {
            cursor: pointer;
        }

        &-icon {
            display: flex;
            align-items: center;
            fill: orangered;

            &:hover {
                fill: red;
            }
        }
    }
}

.param-tooltip-wrapper {
    display: flex;
}

/* Tooltip container */

.param-tooltip {
    position: relative;
}

.param-tooltip .tooltiptext {
    border: 2px solid white; //#c2e1f5;
    visibility: hidden;
    width: 240px;
    min-height: 50px;
    left: -250px;
    top: -10px;
    background-color: #245973;
    color: white;
    border-radius: 4px;
    font-size: 12pt;
    text-align: right;
    position: absolute;
    padding: 10px;
    z-index: 1;

}

.param-tooltip:hover .tooltiptext {
    visibility: visible;
}

.param-tooltip .tooltiptext:before,
.param-tooltip .tooltiptext:after {
    content: " ";
    position: absolute;
    top: 8px;
    left: 100%;
    border-style: solid;
}

.param-tooltip .tooltiptext:after {
    margin-top: -2px;
    border-width: 8px;
    border-color: transparent transparent transparent #245973;
}

.param-tooltip .tooltiptext:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: white; //#c2e1f5;
    border-width: 11px;
    margin-top: -5px;
}

.presetscales_container {
    display: flex;
    margin-bottom: 5px;
}

.presetscales_selectors {
    display: flex;
    flex: 1;
}

.sort_presetScales {
    direction: ltr;
    flex: 1;
    padding-bottom: 5px;
    padding-top: 16px;
    align-self: flex-end;
}

.reacttable_select_align {
    //width: 300px;
    line-height: 16px;
}

.ReactTable div.rt-td {
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    /* CAUSE PROBLEM WITH EDITING CELL    
    display: flex;
    align-items: center;
    justify-content: center;
*/
}