@font-face {
    font-family: 'font-icon';
    src: url('../fonts/font-icon.eot');
    src: url('../fonts/font-icon.eot#iefix') format('embedded-opentype'), 
         url('../fonts/font-icon.woff2') format('woff2'), 
         url('../fonts/font-icon.woff') format('woff'), 
         url('../fonts/font-icon.ttf') format('truetype'), 
         url('../fonts/font-icon.svg#font-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bolet';
    src: url('../fonts/bolet.eot?32y5rn');
    src: url('../fonts/bolet.eot?32y5rn#iefix') format('embedded-opentype'), 
         url('../fonts/bolet.ttf?32y5rn') format('truetype'), 
         url('../fonts/bolet.woff?32y5rn') format('woff'), 
         url('../fonts/bolet.svg?32y5rn#bolet') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "font-icon";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="bolet-icon-"]:before,
[class*="bolet-icon-"]:before {
    font-family: 'bolet';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* font-icon font */

.icon-arrow-down:before {
    content: '\e800';
}

.icon-arrow-right:before {
    content: '\e801';
}

.icon-arrow-up:before {
    content: '\e802';
}

.icon-clock:before {
    content: '\e803';
}

.icon-close:before {
    content: '\e804';
}

.icon-data:before {
    content: '\e805';
}

.icon-edit:before {
    content: '\e806';
}

.icon-home:before {
    content: '\e807';
}

.icon-left:before {
    content: '\e808';
}

.icon-lock:before {
    content: '\e809';
}

.icon-log-out:before {
    content: '\e80a';
}

.icon-media:before {
    content: '\e80b';
}

.icon-menu:before {
    content: '\e80c';
}

.icon-minus:before {
    content: '\e80d';
}

.icon-passed:before {
    content: '\e80e';
}

.icon-plus:before {
    content: '\e80f';
}

.icon-question:before {
    content: '\e810';
}

.icon-report:before {
    content: '\e811';
}

.icon-right:before {
    content: '\e812';
}

.icon-sales:before {
    content: '\e813';
}

.icon-search:before {
    content: '\e814';
}

.icon-transactions:before {
    content: '\e815';
}

.icon-update:before {
    content: '\e816';
}

.icon-user:before {
    content: '\e817';
}

.icon-vendors:before {
    content: '\e818';
}

.icon-excel:before {
    content: '\e81b';
}

.icon-pdf:before {
    content: '\e81c';
}

/*    bolet.ttf    */

.bolet-icon-calendar:before {
   content: "\e900";
}

.bolet-icon-edit:before {
    content: "\e903";
}

.bolet-icon-Fold-Down:before {
    content: "\e904";
}

.bolet-icon-Fold-Up:before {
    content: "\e905";
}

.bolet-icon-two-way-arrows:before {
    content: "\e906";
}

.bolet-icon-trash:before {
    content: "\e907";
}
