.table {
  table-layout: fixed;
}

::-webkit-scrollbar {
    width: 5px;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}


.table__wrap {
  margin-bottom: 20px;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 593px;
  .griddle-body > div > .table {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    width: 99.4%;
  }
  .griddle-body > div > div .table {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

@-moz-document url-prefix(){
  .table__wrap .griddle-body > div > .table {
    width: 99.1%;
  }
}

.table__body {
  // overflow-x: auto;
  // max-height: 593px;
  .table {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: none;
  }
}


.table__head {
  padding-right: 15px;
  .table {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
// .table__item {
//   width: 25%;
// }

.table__foot {
  .table {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .table__item {
    &:last-of-type {
      border-bottom-right-radius: 20px;
    }
  }
}

.table {
  width: 100%;
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  border-radius: 20px;
  border: 1px solid $white_two;
  background-color: #ebebeb;
  overflow: hidden;
  margin-bottom: 0;
  .table__row {
    .table__item {
      padding: 10px 25px;
      vertical-align: middle;
      // table-layout: fixed;
      // width: 10%;
    }
  }
  thead {
    .table__item {
      height: 41px;
      position: static;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
      &:first-of-type {
        border-top-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
      }
    }
    .table__row {
      .table__item {
        padding: 0;
      }
    }
  }
}

thead {
  color: $white_two;
  .table__item {
    background-color: $dark_slate_blue;
    cursor: pointer;
    &:first-of-type {
      .table__item_fix {
        border-top-left-radius: 20px;
      }
    }
    &:last-of-type {
      .table__item_fix {
        border-top-right-radius: 20px;
      }
    }
  }
}


// table tbody td,
// table thead td {
//     width: 140px;
//     border-right: 1px solid black;
// }

// table thead td:last-child {
//     width: 156px; /* 140px + 16px scrollbar width */
// }


tbody {
  color: $black;
  overflow-y: auto;
  overflow-x: hidden;
  .table__row {
    &:nth-of-type(odd) {
      background-color: $white;
    }
  }
}

.table__item {
  position: relative;
  border-top: none;
  border-right: 1px solid $white_five;
  &:last-of-type {
    border-right: none;
  }
  .table__arrow{
    position: absolute;
    right: -20px;
    // top: 22px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 10px;
  }
  .table__edit {
    font-size: 16px;
    color: $dark_slate_blue;
    cursor: pointer;
    &:before {
      display: block;
    }
  }
  .table__del {
    color: $dark_salmon;
    cursor: pointer;
    &:before {
      display: block;
    }
  }
  .checkbox {
    margin-bottom: 0;
    color: $black;
  }
  .checkbox__label {
    &:before {
      margin: 0;
    }
  }
}

.table__item_fix {
  position: absolute;
  top: 1px;
  padding: 10px 25px;
  background-color: $dark_slate_blue;
  cursor: pointer;
  z-index: 1;
}

.table__link {
  color: $black;
  word-wrap: break-word;
}

.table__title {
  display: inline-block;
  position: relative;
}

.table__icon {
  display: inline-block;
  margin-right: 15px;
  font-size: 18px;
}

.table__footer {
  background-color: $white;
  font-size: 16px;
  .table__row {
    border: none;
    .table__item {
      padding: 15px 20px;
    }
  }
  .table__item {
    border: none;
    text-align: center;
  }
}

.table__item-inside {
  display: inline-block;
}

.griddle-page,
.griddle-next,
.griddle-previous {
  display: inline-block;
  padding: 0 20px;
}

.griddle-next button,
.griddle-previous button {
  font-size: 0;
}

.griddle-next button:after,
.griddle-previous button:after {
  content: "\e801";
  font-family: "font-icon";
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

.griddle-previous {
  transform: scale(-1);
}

.griddle-next {
  float: right;
}

.griddle-body {
  tbody {
    + tbody {
      background: #16325b;
      color: #fff;
    }
  }
  select {
    color: #000;
  }
}

// .griddle-next {
//   display: inline-block;
//   padding-right: 20px;
// }

// .griddle-previous {
//   display: inline-block;
//   padding: 0 20px;
// }

@media only screen and (max-width: 767px) {
  .table {
    margin-bottom: 0;
    overflow: visible;
    border-radius: 0;
    .table__row {
      .table__item {
        padding: 10px;
      }
    }
  }
  .table__wrap {
    .title {
      padding-left: 15px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .table__wrap {
    max-height: 480px;
  }
}