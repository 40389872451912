.header {
  padding: 9px 0;
  background-color: $dark-indigo;
}

.logo {
  width: 165px;
}

.header__language {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  margin-top: 10px;
  margin-right: 25px;
  text-align: left;
  .custom-select {
    .Select-control {
      width: 70%;
      height: 34px;
      background-color: $dark_slate_blue_two;
      overflow: visible;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: none;
      color: $white;
    }
    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
      line-height: 32px;
      padding-left: 20px;
    }
    .Select--single > .Select-control .Select-value {
      color: $white;
    }
    .has-value.Select--single:not(.is-focused) > .Select-control > .Select-value .Select-value-label,
    .has-value.is-pseudo-focused.Select--single > .Select-control > .Select-value .Select-value-label {
      color: $white;
    }
    .is-open > .Select-control {
      border-color: $dark_slate_blue_two;
    }
    .is-open {
      .Select-arrow-zone {
        border-bottom-right-radius: 0;
      }
      .Select-arrow {
        transform: scale(-1);
      }
    }
    .Select-arrow-zone {
      position: absolute;
      top: 0;
      right: -32px;
      width: 30px;
      height: 34px;
      padding-right: 2px;
      line-height: 34px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: #224372;
    }
    .Select-arrow {
      border-color: $white transparent transparent;
    }
    .Select-arrow-zone:hover > .Select-arrow {
      border-top-color: $white;
    }
    .Select-input {
      height: 32px;
      padding-left: 20px;
    }

  }
}

.header__help {
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  margin-top: 10px;
  background-color: $dark_slate_blue_two;
  text-align: center;
  line-height: 36px;
  color: $white;
  border-radius: 50%;
  transition: .5s;
  cursor: pointer;
  i {
    color: $white;
  }
  &:hover {
    background-color: #19355c;
  }
}

@media only screen and (max-width: 480px) {
  .logo{
    width: 100%;
  }
  .header__language {
    margin-top: 0;
    margin-right: 10px;
    margin-left: -20px;
  }
  .header__help {
    margin-top: 0;
  }
}