.presets {
    &__container {
        direction: rtl;
        display: grid;
        grid-template-columns: 250px 1fr;
        grid-template-rows: auto;
        grid-template-areas: "groups options""groups info""items view";
    }

    &__options {
        direction: ltr;
        grid-area: options;
        border: solid 1px #555;
        border-radius: 11px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        img {
            margin: 5px 30px;
            width: 50px;
            height: 50px;
        }
    }

    &__info {
        grid-area: info;
        display: flex;
        justify-content: space-evenly;
        margin: 20px 0 10px;
    }

    &__view {
        grid-area: view;
        margin-bottom: 20px;
        min-width: 400px;
        min-height: 570px;
        background-color: #555;
        border-radius: 11px;
        direction: ltr;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(7, 1fr);
        grid-gap: 10px;
        padding: 10px;
    }

    &__groups {
        grid-area: groups;
        width: 90%;

        &-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
        }

        &-icon {}
    }

    &__groups {}

    &__items {
        &-container {
            width: 97%;

            .form__input-wrap {
                width: 90%;
                margin-bottom: 20px;

                .sale_input {
                    padding-right: 20px;
                }
            }
        }

        grid-area: items;
        height: 500px;
        overflow-y: auto;

        &-item {
            display: flex;
            align-items: center;
            border: solid 1px #555;
            border-radius: 11px;
            height: 30px;
            width: 90%;
            margin-bottom: 5px;
            padding-right: 10px;
        }
    }
}

.cell-enabled {
    color: #806E52;
    border-radius: 15px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #888));
    display: flex;
    flex-direction: column;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    &--selected {
        border: 2px solid red;
    }
}

.list-image {
    align-self: flex-end;
    right: 5px;
    top: 5px;
    position: relative;
}

.list-name {
    color: #333;
    font: 16pt "Arial";
    font-weight: bold;
    text-align: center;
}

.product-name {
    flex: 1;
    color: #333;
    font: 12pt "Arial";
    font-weight: bold;
    display: flex;
    text-align: center;
    align-self: center;
}

.product-details {
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 10px;
}

.product-price {
    color: #2B33A2;
    font: 10pt "Arial";
    font-weight: bold;
    text-align: right;
    margin-right: 10px;
}

.preset-num {
    position: relative;
    color: #000;
    font: 10pt "Arial";
    float: left;
    left: 3px;
    top: 5px;
}

.product-code {
    position: relative;
    color: #AE3800;
    font: 10pt "Arial";
    font-weight: bold;
    float: right;
    right: 3px;
    top: 5px;
}

.table-drag-select td.cell-enabled {
    color: #806E52;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #888));
}

.table-drag-select td.cell-selected {
    border: 2px solid red;
}

/* Cells that are in the process of being selected */

.table-drag-select td.cell-being-selected {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, black));
}

.table-drag-select {
    border-collapse: separate;
    border-spacing: 0.75em;
}

/* Cells rows */

.table-drag-select tr td {
    padding-bottom: 1em;
}

/* Cells columns */

.table-drag-select td {
    border: solid 2px #555;
    border-radius: 11px;
}

/* WILL DETERMINE STYLE WHEN DRAGGING OVER */

.md-dragging {
    border: 1px dotted;
}

/* WILL DETERMINE STYLE WHEN ACTIVE IN DRAGGING LIST */

.md-active {
    background: #DDDDFF;
}

.buttons-wrapper {
    width: 78%;
    border: solid 1px #555;
    border-radius: 11px;
    text-align: center;

    img {
        margin: 5px 30px;
        width: 50px;
        height: 50px;
    }
}

.color-picker {
    position: absolute;
    z-index: 100;
    left: 0px;
    top: 50px;
}

.page-number {
    position: relative;
    top: 25px;
    left: 20px;
}

.presets-number {
    position: relative;
    top: 25px;
    left: 120px;
}

.data-wrapper {
    display: flex;
}

.department {
    direction: rtl;
    position: absolute;
    top: 0px;
    right: 10px;
    width: 250px;
    text-align: right;

    #name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    #selection {
        width: 150px;
        font-size: 16px;
        margin-bottom: 10px;
        height: 40px;
        line-height: 30px;
    }

    #update {
        font-size: 16px;
        margin-bottom: 10px;
    }
}

.details-wrapper {
    flex: 1.3;
    margin-left: 20px;

    .departments-wrapper {
        margin-top: 0px;
        text-align: right;
        font-size: 20px;
        font-weight: bold;
    }

    .items-wrapper {
        margin-top: 30px;

        .items {
            text-align: right;
            overflow-y: scroll;
            direction: rtl;
            max-height: 730px;
        }

        .item {
            height: 30px;
            margin-left: 5px;
            margin-bottom: 5px;
            border: solid 1px #555;
            border-radius: 5px;

            span {
                margin-right: 5px;
                vertical-align: middle;
            }
        }
    }
}

.presets-wrapper {
    flex: 5;
    margin-top: 30px;
    margin-bottom: 20px;
    min-width: 400px;
    min-height: 513.1px;
    background-color: #555;
    border-radius: 11px;
}

.react-contextmenu {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    outline: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
}

.react-contextmenu-item {
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #373a3c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    color: #878a8c;
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item--divider {
    margin-bottom: 3px;
    padding: 2px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
}

.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item {}

.react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}

.presets__buttons_group {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}