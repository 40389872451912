.departments-grid {
    direction: rtl;
    background-color: red;
}

.align-center {
    color: red;
}

.header-wrapper {
    .plus-icon {
        position: relative;
        right: 20px;
    }
}

.groups-wrapper {
    //border-left: 1px solid #E7E7E7;
    margin-top: 0px;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    overflow-y: scroll;
    max-height: 550px;

    &:hover {
        overflow-y: scroll;

    }

    .items-wrapper {
        margin-top: 30px;

        .items-header {
            color: blue;
        }

        .items {
            text-align: right;
            overflow-y: scroll;
            direction: rtl;
            max-height: 730px;
        }


        .item {
            display: flex;
            align-items: center;
            height: 30px;
            margin-left: 5px;
            margin-bottom: 5px;
            border: solid 1px #555;
            border-radius: 5px;
            justify-content: space-between;

            span {
                margin-right: 5px;
                vertical-align: middle;
            }

            .bolet-icon-trash {
                color: orangered;
                position: relative;
                left: 5px;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .item-selected {
            display: flex;
            align-items: center;
            height: 30px;
            margin-left: 5px;
            margin-bottom: 5px;
            border: solid 1px #555;
            border-radius: 5px;
            background-color: #ddddff;
            justify-content: space-between;

            span {
                margin-right: 5px;
                vertical-align: middle;
            }

            .bolet-icon-trash {
                color: orangered;
                position: relative;
                left: 5px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.ReactTable {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 -3px 0 0 rgba(255, 255, 0, 0.6);
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgba(255, 255, 0, 0.6);
}

.ReactTable .rt-expander:after {
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    transform: translate(-50%, -50%) rotate(90deg);
}

.ReactTable .rt-expander.-open:after {
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-th {
    font-size: 16px;
    color: $white;
    background-color: $dark_slate_blue;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ReactTable .rt-th:first-child {
    border-top-right-radius: 20px;
    border: none;
    overflow: hidden;
}

.ReactTable .rt-th:last-child {
    border-top-left-radius: 20px;
    overflow: hidden;
}

.ReactTable .rt-td {
    overflow: visible;
}

.ReactTable .pagination-bottom {
    // ADD MARGIN BELOW THE LINES margin-top: 100px;
}

.react-select-container {}

.react-select {
    &__control {}

    &__value-container {}

    &__indicators {}

    &__menu {
        height: 85px;

        &-list {
            height: 85px;
        }
    }

    &__option {}
}

.group {
    &__container {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        display: flex;
        margin-bottom: 25px;
    }

    &__details {
        display: flex;
        flex-direction: column;
    }

    &__row {
        display: flex;

        &-item {
            &:not(:first-child) {
                margin-right: 10px;
            }

            display: flex;
            flex-direction: column;
        }

        margin-bottom: 10px;
    }

    &__item {
        &--small {
            flex: 1;
        }

        &--large {
            flex: 3;
        }
    }
}