.btn-primary {
  border-radius: 20px;
  background-color: $dark_slate_blue;
  border-color: $dark_slate_blue;
  &:hover {
    background: linear-gradient(to bottom, #1e4f83 0%, #0c2549 100%);
  }
}

.btn:focus:active,
.btn:focus,
.btn:active {
  outline: none;
}
