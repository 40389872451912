/*
section {
    width: 400px;
	left: 0px;
	position: relative;
	overflow: visible;
}
*/
#popup-template-select-box-section {
	z-index: 20;
	height: 1024px;
}
#popup-sale-codes-box {
	position: relative;
	top: 100.8px;
	left: 8.5px;
	width: 1051.2px;
	height: 822.4px;
	z-index: 10;
	box-shadow: 0px 2px 45px 0px rgba(0,0,0,0.235);
	background-color: rgb(255, 255, 255);
	border-radius: 10px;
	right: -8.5px;
	margin: auto;	
}
#blur {
    position: absolute;
    top: 0px;
    left: 0%;
    width: 101.2%;
    height: 1024px;
    opacity: 0.6;
    background-color: rgb(79, 77, 77);
    right: 0%;
    margin: auto;
	z-index: 5;
}
#popup-sale-codes-header-image {
	position: absolute;
	top:0px;
	left:430px;
	z-index: 10;
}
#popup-sale-codes-h1 {
    position: absolute;
    top:120px;
    width:100%;
    z-index: 20;
    text-align: center;
    color: rgb(13, 27, 56);
    font-family: ArialMT;
    font-size: 30px;
    white-space: nowrap;
    direction: rtl;
}
#popup-sale-codes-select-box {
    width: 300px;
    height: 36px;
    z-index: 150;
    border: 1px solid rgb(15, 38, 73);
    border-radius: 4px;
    margin: auto;
}
#popup-sale-codes-select-text {
    position: absolute;
    top: 8.6px;
	left: -15px;
	
    width: 100%;
    z-index: 130;
    text-align: right;
    color: rgb(13, 27, 56);
    font-family: ArialMT;
    font-size: 14px;
    white-space: nowrap;
    direction: rtl;
    right: 35px;
    margin: auto;
}
#popup-sale-codes-h2 {
    position: absolute;
    top: 510px;
    right: 40px;
    z-index: 20;
    color: rgb(13, 27, 56);
    font-family: ArialMT;
    font-size: 16px;
    white-space: nowrap;
    direction: rtl;
    margin: auto;
}
#popup-sale-codes-image {
    position: absolute;
    top: 0.5px;
    left: -133.4px;
    width: 33.2px;
    height: 33.1px;
    z-index: 160;
    right: 133.4px;
    margin: auto;
}
#popup-sale-codes-item-name {
    position: absolute;
    top: 60.6px;
    left: 22.5px;
    width: 251px;
    z-index: 490;
    text-align: right;
    color: rgb(13, 27, 56);
    font-family: ArialMT;
    font-size: 14px;
    white-space: nowrap;
    direction: rtl;
    right: -72.5px;
    margin: auto;
}
#popup-sale-codes-searched-item-group {
    position: absolute;
    top: 180px;
    right:340px;
    z-index: 480;    
    margin: auto;
}
#popup-sale-codes-selected-item-group {
    position: absolute;
    top: 490px;
    right: 340px;
    z-index: 480;    
    margin: auto;
}
#popup-sale-codes-searched-item-box {
    position: absolute;
    top: 50px;
    left: 0px;
    width: 300px;
    height: 36px;
    z-index: 500;
    border: 1px solid rgb(15, 38, 73);
    border-radius: 4px;
    right: 0px;
    margin: auto;
}
#popup-sale-codes-searched-item-checkbox {
    position: absolute;
    top: 11px;
    left: 134.5px;
    width: 12px;
    height: 12px;
    z-index: 520;
    border: 1.5px solid rgb(15, 38, 73);
    background-color: rgb(255, 255, 255);
    right: -134.5px;
    margin: auto;
}
#popup-sale-codes-searched-item-checkbox-checked {
    position: absolute;
    top: 1.5px;
    left: -1.2px;
    width: 8.6px;
    height: 6.5px;
    z-index: 1310;
    right: 1.2px;
    margin: auto;
}
#popup-sale-codes-btn-text {
    position: absolute;
    top: 8.6px;
    left: 0.4px;
    width: 53px;
    z-index: 20;
    text-align: center;
    color: rgb(255, 254, 254);
    font-weight: 700;
    font-family: Arial;
    font-size: 14px;
    white-space: nowrap;
    direction: rtl;
    right: -0.4px;
    margin: auto;
}
#popup-sale-codes-add-btn-box {
    position: absolute;
    bottom: 40px;
    left: 68.5px;
    width: 99px;
    height: 35px;
    z-index: 40;
    background: rgb(62, 196, 168);
    border-radius: 17.5px;
    right: -68.5px;
    margin: auto;
    cursor: pointer;
    outline: none;
    box-sizing: initial;
    border: none;
    transition: all 0.5s;
}
#popup-sale-codes-cancel-btn-box {
    position: absolute;
    bottom: 40px;
    left: -50.5px;
    width: 99px;
    height: 35px;
    z-index: 20;
    background: rgb(155, 155, 155);
    border-radius: 17.5px;
    right: 50.5px;
    margin: auto;
    cursor: pointer;
    outline: none;
    box-sizing: initial;
    border: none;
    transition: all 0.5s;
}
#sale-h1 {
	position: absolute;
	top: 171.1px;
	left: -155.5px;
	width: 62px;
	z-index: 440;
	text-align: right;
	color: rgb(13, 27, 56);
	font-weight: 700;
	font-family: Arial;
	font-size: 16px;
	white-space: nowrap;
	direction: rtl;
	right: 155.5px;
	margin: auto;
}
#sale-h2 {
	position: absolute;
	top: 250.4px;
	left: -151px;
	width: 53px;
	z-index: 450;
	text-align: right;
	color: rgb(13, 27, 56);
	font-weight: 700;
	font-family: Arial;
	font-size: 16px;
	white-space: nowrap;
	direction: rtl;
	right: 151px;
	margin: auto;
}
#sale-h3 {
	position: absolute;
	top: 388px;
	left: -144.5px;
	width: 40px;
	z-index: 460;
	text-align: right;
	color: rgb(13, 27, 56);
	font-weight: 700;
	font-family: Arial;
	font-size: 16px;
	white-space: nowrap;
	direction: rtl;
	right: 144.5px;
	margin: auto;
}
#sale-add-user-message-link {
	position: absolute;
	top: 203.5px;
	left: -196px;
	width: 143px;
	z-index: 470;
	text-align: right;
	white-space: nowrap;
	font-family: Arial;
	font-weight: 700;
	font-size: 16px;
	line-height: 1;
	color: rgb(54, 186, 151);
	direction: rtl;
	right: 196px;
	margin: auto;
	text-decoration: none;
	cursor: pointer;
}
#sale-add-customer-image-link {
	position: absolute;
	top: 282.8px;
	left: -165.5px;
	width: 82px;
	z-index: 480;
	text-align: right;
	white-space: nowrap;
	font-family: Arial;
	font-weight: 700;
	font-size: 16px;
	line-height: 1;
	color: rgb(54, 186, 151);
	direction: rtl;
	right: 165.5px;
	margin: auto;
	text-decoration: none;
	cursor: pointer;
}
#sale-add-customer-movie-link {
	position: absolute;
	top: 315px;
	left: -167px;
	width: 85px;
	z-index: 490;
	text-align: right;
	white-space: nowrap;
	font-family: Arial;
	font-weight: 700;
	font-size: 16px;
	line-height: 1;
	color: rgb(54, 186, 151);
	direction: rtl;
	right: 167px;
	margin: auto;
	text-decoration: none;
	cursor: pointer;
}
#sale-add-customer-template-link {
	position: absolute;
	top: 347px;
	left: -168px;
	width: 87px;
	z-index: 500;
	text-align: right;
	white-space: nowrap;
	font-family: Arial;
	font-weight: 700;
	font-size: 16px;
	line-height: 1;
	color: rgb(54, 186, 151);
	direction: rtl;
	right: 168px;
	margin: auto;
	text-decoration: none;
	cursor: pointer;
}
#sale-edit-name-link {
	position: absolute;
	top: 411.6px;
	left: -172px;
	width: 95px;
	z-index: 510;
	text-align: right;
	color: rgb(71, 70, 89);
	font-family: ArialMT;
	font-size: 16px;
	white-space: nowrap;
	text-decoration: none;
	direction: rtl;
	right: 172px;
	margin: auto;
	cursor: pointer;
}
#sale-edit-scales-link {
	position: absolute;
	top: 435px;
	left: -188px;
	width: 127px;
	z-index: 520;
	text-align: right;
	color: rgb(71, 70, 89);
	font-family: ArialMT;
	font-size: 16px;
	white-space: nowrap;
	text-decoration: none;
	direction: rtl;
	right: 188px;
	margin: auto;
	cursor: pointer;
}
#sale-edit-codes-link {
	position: absolute;
	top: 459px;
	left: -171px;
	width: 93px;
	z-index: 530;
	text-align: right;
	color: rgb(71, 70, 89);
	font-family: ArialMT;
	font-size: 16px;
	white-space: nowrap;
	text-decoration: none;
	direction: rtl;
	right: 171px;
	margin: auto;
	cursor: pointer;
}
#sale-filled-h1 {
	position: absolute;
	top: 2.1px;
	left: 22px;
	width: 62px;
	z-index: 460;
	text-align: right;
	color: rgb(13, 27, 56);
	font-weight: 700;
	font-family: Arial;
	font-size: 16px;
	white-space: nowrap;
	direction: rtl;
	right: -22px;
	margin: auto;
}
#sale-filled-h2 {
	position: absolute;
	top: 108.4px;
	left: 26.5px;
	width: 53px;
	z-index: 470;
	text-align: right;
	color: rgb(13, 27, 56);
	font-weight: 700;
	font-family: Arial;
	font-size: 16px;
	white-space: nowrap;
	direction: rtl;
	right: -26.5px;
	margin: auto;
}
#sale-filled-h3 {
	position: absolute;
	top: 328.3px;
	left: 33px;
	width: 40px;
	z-index: 480;
	text-align: right;
	color: rgb(13, 27, 56);
	font-weight: 700;
	font-family: Arial;
	font-size: 16px;
	white-space: nowrap;
	direction: rtl;
	right: -33px;
	margin: auto;
}
#sale-filled-edit-name-link {
	position: absolute;
	top: 351.8px;
	left: 5.5px;
	width: 95px;
	z-index: 490;
	text-align: right;
	color: rgb(71, 70, 89);
	font-family: ArialMT;
	font-size: 16px;
	white-space: nowrap;
	text-decoration: none;
	direction: rtl;
	right: -5.5px;
	margin: auto;
	cursor: pointer;
}
#sale-filled-edit-scales-link {
	position: absolute;
	top: 375.3px;
	left: -10.5px;
	width: 127px;
	z-index: 500;
	text-align: right;
	color: rgb(71, 70, 89);
	font-family: ArialMT;
	font-size: 16px;
	white-space: nowrap;
	text-decoration: none;
	direction: rtl;
	right: 10.5px;
	margin: auto;
	cursor: pointer;
}
#sale-filled-edit-codes-link {
	position: absolute;
	top: 399.3px;
	left: 6.5px;
	width: 93px;
	z-index: 510;
	text-align: right;
	color: rgb(71, 70, 89);
	font-family: ArialMT;
	font-size: 16px;
	white-space: nowrap;
	text-decoration: none;
	direction: rtl;
	right: -6.5px;
	margin: auto;
	cursor: pointer;
}
#sale-filled-line {
	position: absolute;
	top: -184.1px;
	left: 83.5px;
	width: 1.8px;
	height: 799.6px;
	z-index: 520;
	right: -83.5px;
	margin: auto;
}
#sale-filled-group {
	position: relative;
	top: 100px;
	left: 100px;
	width: 300px;
	height: 300px;
}
#sale-filled-edit-image1 {
	position: absolute;
	top: 55.3px;
	left: 40.3px;
	width: 49.6px;
	height: 22.1px;
	z-index: 1350;
	right: -40.3px;
	margin: auto;
}
#sale-filled-edit-images2 {
	position: absolute;
	top: 273.1px;
	left: 40.3px;
	width: 49.6px;
	height: 22.1px;
	z-index: 1360;
	right: -40.3px;
	margin: auto;
}
#popup-template-select-box {
	position: absolute;
	top: 217.5px;
	left: 8.5px;
	width: 891px;
	height: 589px;
	z-index: 20;
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 2px 45px 0px rgba(0,0,0,0.235);
	border-radius: 10px;
	right: -8.5px;
	margin: auto;
}
#popup-template-select-box-border {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 890.6px;
	height: 588.6px;
	z-index: 50;
	box-shadow: 0px 2px 45px 0px rgba(0,0,0,0.235);
	background-color: rgb(255, 255, 255);
	border-radius: 10px;
	right: 0px;
	margin: auto;
}
#popup-template-select-discount-btn-text {
	position: absolute;
	top: 411.8px;
	left: -284px;
	width: 40px;
	z-index: 130;
	text-align: center;
	color: rgb(13, 27, 56);
	font-family: ArialMT;
	font-size: 14px;
	white-space: nowrap;
	direction: rtl;
	right: 284px;
	margin: auto;
}
#popup-template-select-price-btn-text {
	position: absolute;
	top: 411.8px;
	left: 283.6px;
	width: 70px;
	z-index: 80;
	text-align: center;
	color: rgb(13, 27, 56);
	font-family: ArialMT;
	font-size: 14px;
	white-space: nowrap;
	direction: rtl;
	right: -283.6px;
	margin: auto;
}
#popup-template-select-buyget-btn-text {
	position: absolute;
	top: 411.8px;
	left: 0px;
	width: 96px;
	z-index: 120;
	text-align: center;
	white-space: nowrap;
	font-family: ArialMT;
	font-size: 14px;
	line-height: 1;
	color: rgb(13, 27, 56);
	direction: rtl;
	right: 0px;
	margin: auto;
}
#popup-template-select-h2 {
	position: absolute;
	top: 144.3px;
	left: -0.4px;
	width: 100px;
	z-index: 70;
	text-align: center;
	color: rgb(13, 27, 56);
	font-family: ArialMT;
	font-size: 14px;
	white-space: nowrap;
	direction: rtl;
	right: 0.4px;
	margin: auto;
}
#popup-template-select-h1 {
	position: absolute;
	top: 106.3px;
	left: -0.4px;
	width: 157px;
	z-index: 60;
	text-align: center;
	color: rgb(13, 27, 56);
	font-family: ArialMT;
	font-size: 30px;
	white-space: nowrap;
	direction: rtl;
	right: 0.4px;
	margin: auto;
}
#popup-template-select-buyget-btn-box {
	position: absolute;
	top: 234.5px;
	left: -0.5px;
	width: 225px;
	height: 160px;
	z-index: 140;
	background: rgb(62, 196, 168);
	right: 0.5px;
	margin: auto;
	cursor: pointer;
	outline: none;
	box-sizing: initial;
	border: none;
	transition: all 0.5s;
}
#popup-template-select-discount-btn-box {
	position: absolute;
	top: 234.5px;
	left: -284px;
	width: 226px;
	height: 160px;
	z-index: 170;
	background: rgb(248, 95, 88);
	right: 284px;
	margin: auto;
	cursor: pointer;
	outline: none;
	box-sizing: initial;
	border: none;
	transition: all 0.5s;
}
#popup-template-select-price-btn-box {
	position: absolute;
	top: 234.5px;
	left: 284px;
	width: 226px;
	height: 160px;
	z-index: 200;
	background: rgb(22, 51, 90);
	right: -284px;
	margin: auto;
	cursor: pointer;
	outline: none;
	box-sizing: initial;
	border: none;
	transition: all 0.5s;
}
#popup-template-select-price-btn-image {
	position: absolute;
	top: -12.2px;
	left: -0.9px;
	width: 183.8px;
	height: 183.8px;
	z-index: 220;
	right: 0.9px;
	margin: auto;
}
#popup-template-select-discount-btn-image {
	position: absolute;
	top: -12.9px;
	left: -0.5px;
	width: 183.8px;
	height: 185.7px;
	z-index: 190;
	right: 0.5px;
	margin: auto;
}
#popup-template-select-buyget-btn-image {
	position: absolute;
	top: 11.4px;
	left: -0.6px;
	width: 113.6px;
	height: 136.8px;
	z-index: 160;
	right: 0.6px;
	margin: auto;
}
#popup-template-buy-box {
	position: absolute;
	top: 217.5px;
	left: 8.5px;
	width: 890.6px;
	height: 588.6px;
	z-index: 20;
	box-shadow: 0px 2px 45px 0px rgba(0,0,0,0.235);
	background-color: rgb(255, 255, 255);
	border-radius: 10px;
	right: -8.5px;
	margin: auto;
}
#popup-template-buy-box-section {
	z-index: 20;
	height: 1024px;
}
#popup-template-buy-h1 {
	position: absolute;
	top: 61.7px;
	left: -0.4px;
	width: 79px;
	z-index: 70;
	text-align: center;
	color: rgb(13, 27, 56);
	font-family: ArialMT;
	font-size: 14px;
	white-space: nowrap;
	direction: rtl;
	right: 0.4px;
	margin: auto;
}
#popup-template-buy-btn-group {
	position: absolute;
	top: 523px;
	left: 0.5px;
	width: 218px;
	height: 35px;
	z-index: 280;
	right: -0.5px;
	margin: auto;
}
#popup-template-buy-h2 {
	position: absolute;
	top: 23.1px;
	left: -0.4px;
	width: 287px;
	z-index: 60;
	text-align: center;
	white-space: nowrap;
	font-family: ArialMT;
	font-size: 30px;
	line-height: 1;
	color: rgb(13, 27, 56);
	direction: rtl;
	right: 0.4px;
	margin: auto;
}
#popup-template-buy-image-box {
	position: absolute;
	top: 99.5px;
	left: 163.8px;
	width: 290px;
	height: 206px;
	z-index: 30;
	background-color: rgb(62, 196, 168);
	right: -163.8px;
	margin: auto;
}
#popup-template-buy-fields-group {
	position: absolute;
	top: 100.39px;
	left: -158.2px;
	width: 302px;
	height: 385.79px;
	z-index: 80;
	right: 158.2px;
	margin: auto;
}
#popup-template-buy-product-name-group {
	position: absolute;
	top: 56px;
	left: -1px;
	width: 300px;
	height: 37px;
	z-index: 90;
	right: 1px;
	margin: auto;
}
#popup-template-buy-product-code-group {
	position: absolute;
	top: 113.7px;
	left: -1px;
	width: 300px;
	height: 37px;
	z-index: 120;
	right: 1px;
	margin: auto;
}
#popup-template-buy-product-buy-group {
	position: absolute;
	top: 170.7px;
	left: -1px;
	width: 300px;
	height: 37px;
	z-index: 150;
	right: 1px;
	margin: auto;
}
#popup-template-buy-product-get-group {
	position: absolute;
	top: 227.7px;
	left: -1px;
	width: 300px;
	height: 37px;
	z-index: 180;
	right: 1px;
	margin: auto;
}
#popup-template-buy-remark-group {
	position: absolute;
	top: 284.8px;
	left: -1px;
	width: 300px;
	height: 101px;
	z-index: 210;
	right: 1px;
	margin: auto;
}
#popup-template-buy-search-group {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 302px;
	height: 38px;
	z-index: 240;
	right: 0px;
	margin: auto;
}
#popup-template-buy-search-box {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 300px;
	height: 36px;
	z-index: 260;
	border: 1px solid rgb(15, 38, 73);
	border-radius: 4px;
	right: 0px;
	margin: auto;
}
#popup-template-buy-search-image {
	position: absolute;
	top: 0.5px;
	left: -133.4px;
	width: 33.2px;
	height: 33.1px;
	z-index: 270;
	right: 133.4px;
	margin: auto;
}
#popup-template-buy-product-remark-box {
	position: absolute;
	top: 0.3px;
	left: 0px;
	width: 290px;
	height: 99.9px;
	z-index: 230;
	border: 1px solid rgb(15, 38, 73);
	border-radius: 4px;
	right: 0px;
	margin: auto;
	outline: none;
	background: none;
	font-size: 14px;
	font-family: ArialMT;
	color: rgb(13, 27, 56);
	padding-left: 10px;
}
#popup-template-buy-product-get-box {
	position: absolute;
	top: 0.3px;
	left: 0px;
	width: 290px;
	height: 36px;
	z-index: 200;
	border: 1px solid rgb(15, 38, 73);
	border-radius: 4px;
	right: 0px;
	margin: auto;
	outline: none;
	background: none;
	font-size: 14px;
	font-family: ArialMT;
	color: rgb(13, 27, 56);
	padding-left: 10px;
}
#popup-template-buy-product-buy-box {
	position: absolute;
	top: 0.3px;
	left: 0px;
	width: 290px;
	height: 36px;
	z-index: 170;
	border: 1px solid rgb(15, 38, 73);
	border-radius: 4px;
	right: 0px;
	margin: auto;
	outline: none;
	background: none;
	font-size: 14px;
	font-family: ArialMT;
	color: rgb(13, 27, 56);
	padding-left: 10px;
}
#popup-template-buy-product-code-box {
	position: absolute;
	top: 0.3px;
	left: 0px;
	width: 290px;
	height: 36px;
	z-index: 140;
	border: 1px solid rgb(15, 38, 73);
	border-radius: 4px;
	right: 0px;
	margin: auto;
	outline: none;
	background: none;
	font-size: 14px;
	font-family: ArialMT;
	color: rgb(13, 27, 56);
	padding-left: 10px;
}
#popup-template-buy-text {
	position: absolute;
	top: 9px;
	left: 113px;
	width: 48px;
	z-index: 100;
	text-align: right;
	white-space: nowrap;
	font-family: ArialMT;
	font-size: 14px;
	line-height: 1;
	color: rgb(13, 27, 56);
	direction: rtl;
	right: -113px;
	margin: auto;
}
#popup-template-buy-product-name-box {
	position: absolute;
	top: 0.3px;
	left: 0px;
	width: 290px;
	height: 36px;
	z-index: 110;
	border: 1px solid rgb(15, 38, 73);
	border-radius: 4px;
	right: 0px;
	margin: auto;
	outline: none;
	background: none;
	font-size: 14px;
	font-family: ArialMT;
	color: rgb(13, 27, 56);
	padding-left: 10px;
}
#popup-template-buy-image-group {
	position: absolute;
	top: 14.6px;
	left: -0.9px;
	width: 146.2px;
	height: 176.1px;
	z-index: 50;
	right: 0.9px;
	margin: auto;
}
#popup-template-buy-add-btn-box {
	position: absolute;
	top: 0px;
	left: 59.5px;
	width: 99px;
	height: 35px;
	z-index: 290;
	background: rgb(62, 196, 168);
	border-radius: 17.5px;
	right: -59.5px;
	margin: auto;
	cursor: pointer;
	outline: none;
	box-sizing: initial;
	border: none;
	transition: all 0.5s;
}
#popup-template-buy-cancel-btn-box {
	position: absolute;
	top: 0px;
	left: -59.5px;
	width: 99px;
	height: 35px;
	z-index: 330;
	background: rgb(155, 155, 155);
	border-radius: 17.5px;
	right: 59.5px;
	margin: auto;
	cursor: pointer;
	outline: none;
	box-sizing: initial;
	border: none;
	transition: all 0.5s;
}
#popup-template-buy-btn-text {
	position: absolute;
	top: 8.6px;
	left: 0.4px;
	width: 53px;
	z-index: 310;
	text-align: center;
	color: rgb(255, 254, 254);
	font-weight: 700;
	font-family: Arial;
	font-size: 14px;
	white-space: nowrap;
	direction: rtl;
	right: -0.4px;
	margin: auto;
}
#popup-template-discount-box {
	position: absolute;
	top: 217.5px;
	left: 8.5px;
	width: 890.6px;
	height: 588.6px;
	z-index: 20;
	box-shadow: 0px 2px 45px 0px rgba(0,0,0,0.235);
	background-color: rgb(255, 255, 255);
	border-radius: 10px;
	right: -8.5px;
	margin: auto;
}
#popup-template-discount-box-section {
	z-index: 20;
	height: 1024px;
}
#popup-template-discount-h1 {
	position: absolute;
	top: 61.7px;
	left: -0.4px;
	width: 79px;
	z-index: 40;
	text-align: center;
	color: rgb(13, 27, 56);
	font-family: ArialMT;
	font-size: 14px;
	white-space: nowrap;
	direction: rtl;
	right: 0.4px;
	margin: auto;
}
#popup-template-discount-h2 {
	position: absolute;
	top: 23.1px;
	left: -0.4px;
	width: 202px;
	z-index: 30;
	text-align: center;
	color: rgb(13, 27, 56);
	font-family: ArialMT;
	font-size: 30px;
	white-space: nowrap;
	direction: rtl;
	right: 0.4px;
	margin: auto;
}
#popup-template-discount-btn-group {
	position: absolute;
	top: 523px;
	left: 0.5px;
	width: 218px;
	height: 35px;
	z-index: 250;
	right: -0.5px;
	margin: auto;
}
#popup-template-discount-image-box {
	position: absolute;
	top: 100.5px;
	left: 164.2px;
	width: 291px;
	height: 207px;
	z-index: 330;
	background-color: rgb(248, 95, 88);
	right: -164.2px;
	margin: auto;
}
#popup-template-discount-fields-group {
	position: absolute;
	top: 100.39px;
	left: -158.7px;
	width: 303px;
	height: 329.1px;
	z-index: 50;
	right: 158.7px;
	margin: auto;
}
#popup-template-discount-product-name-group {
	position: absolute;
	top: 56px;
	left: -0.5px;
	width: 300px;
	height: 37px;
	z-index: 60;
	right: 0.5px;
	margin: auto;
}
#popup-template-discount-product-code-group {
	position: absolute;
	top: 113.7px;
	left: -0.5px;
	width: 300px;
	height: 37px;
	z-index: 90;
	right: 0.5px;
	margin: auto;
}
#popup-template-discount-discount-group {
	position: absolute;
	top: 170.7px;
	left: -1px;
	width: 301px;
	height: 37px;
	z-index: 120;
	right: 1px;
	margin: auto;
}
#popup-template-discount-remark-group {
	position: absolute;
	top: 228.1px;
	left: -0.5px;
	width: 300px;
	height: 101px;
	z-index: 180;
	right: 0.5px;
	margin: auto;
}
#popup-template-discount-search-group {
	position: absolute;
	top: 0px;
	left: 0.5px;
	width: 302px;
	height: 38px;
	z-index: 210;
	right: -0.5px;
	margin: auto;
}
#popup-template-discount-product-remark-box {
	position: absolute;
	top: 0.3px;
	left: 0px;
	width: 300px;
	height: 99.9px;
	z-index: 200;
	border: 1px solid rgb(15, 38, 73);
	border-radius: 4px;
	right: 0px;
	margin: auto;
}
#popup-template-discount-product-discount-box {
	position: absolute;
	top: 0.3px;
	left: 56.4px;
	width: 190.2px;
	height: 36px;
	z-index: 140;
	border: 1px solid rgb(15, 38, 73);
	border-radius: 4px;
	right: -56.4px;
	margin: auto;
}
#group {
	position: absolute;
	top: 0px;
	left: -99.5px;
	width: 102px;
	height: 37px;
	z-index: 150;
	right: 99.5px;
	margin: auto;
	pointer-events: none;
}
#popup-template-discount-currency {
	position: absolute;
	top: 9px;
	left: 31px;
	width: 12px;
	z-index: 160;
	text-align: right;
	color: rgb(13, 27, 56);
	font-family: ArialMT;
	font-size: 14px;
	white-space: nowrap;
	direction: ltr;
	right: -31px;
	margin: auto;
	pointer-events: none;
}
#popup-template-discount-currency-box {
	position: absolute;
	top: 0.3px;
	left: 0.1px;
	width: 101.5px;
	height: 36px;
	z-index: 170;
	border: 1px solid rgb(15, 38, 73);
	border-radius: 4px;
	right: -0.1px;
	margin: auto;
	outline: none;
	-webkit-appearance: none;
	cursor: pointer;
	background: none;
	font-size: 14px;
	font-family: ArialMT;
	color: rgb(13, 27, 56);
	padding-left: 10px;
}
#popup-template-discount-dropdown-image {
	position: absolute;
	top: 8.4px;
	left: -35.75px;
	width: 11px;
	height: 21.9px;
	z-index: 360;
	right: 35.75px;
	margin: auto;
	pointer-events: none;
}
#popup-template-discount-product-code-box {
	position: absolute;
	top: 0.3px;
	left: 0px;
	width: 300px;
	height: 36px;
	z-index: 110;
	border: 1px solid rgb(15, 38, 73);
	border-radius: 4px;
	right: 0px;
	margin: auto;
}
#popup-template-discount-text {
	position: absolute;
	top: 9px;
	left: 113px;
	width: 48px;
	z-index: 70;
	text-align: right;
	white-space: nowrap;
	font-family: ArialMT;
	font-size: 14px;
	line-height: 1;
	color: rgb(13, 27, 56);
	direction: rtl;
	right: -113px;
	margin: auto;
}
#popup-template-discount-product-name-box {
	position: absolute;
	top: 0.3px;
	left: 0px;
	width: 300px;
	height: 36px;
	z-index: 80;
	border: 1px solid rgb(15, 38, 73);
	border-radius: 4px;
	right: 0px;
	margin: auto;
}
#popup-template-discount-image-group {
	position: absolute;
	top: 2.1px;
	left: -6.3px;
	width: 215.1px;
	height: 215.1px;
	z-index: 350;
	right: 6.3px;
	margin: auto;
}
#popup-template-discount-add-btn-box {
	position: absolute;
	top: 0px;
	left: 59.5px;
	width: 99px;
	height: 35px;
	z-index: 260;
	background-color: rgb(62, 196, 168);
	border-radius: 17.5px;
	right: -59.5px;
	margin: auto;
}
#popup-template-discount-cancel-btn-box {
	position: absolute;
	top: 0px;
	left: -59.5px;
	width: 99px;
	height: 35px;
	z-index: 300;
	background-color: rgb(155, 155, 155);
	border-radius: 17.5px;
	right: 59.5px;
	margin: auto;
}
#popup-template-discount-btn-text {
	position: absolute;
	top: 8.6px;
	left: 0.4px;
	width: 53px;
	z-index: 280;
	text-align: center;
	color: rgb(255, 254, 254);
	font-weight: 700;
	font-family: Arial;
	font-size: 14px;
	white-space: nowrap;
	direction: rtl;
	right: -0.4px;
	margin: auto;
}
#popup-template-discount-search-box {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 300px;
    height: 36px;
    z-index: 230;
    border: 1px solid rgb(15, 38, 73);
    border-radius: 4px;
    right: 0px;
    margin: auto;
} 
#popup-template-discount-search-image {
    position: absolute;
    top: 0.5px;
    left: -133.4px;
    width: 33.2px;
    height: 33.1px;
    z-index: 240;
    right: 133.4px;
    margin: auto;
} 
#sale-list-header {
	position: absolute;
	top: 173.6px;
	left: 338px;
	width: 51px;
	z-index: 320;
	text-align: right;
	color: rgb(13, 27, 56);
	font-weight: 700;
	font-family: Arial;
	font-size: 16px;
	white-space: nowrap;
	direction: rtl;
	right: -338px;
	margin: auto;
}
#sale-list-item-selected {
	position: absolute;
	top: 200.1px;
	left: 318px;
	width: 91px;
	z-index: 360;
	text-align: right;
	color: rgb(13, 27, 56);
	font-weight: 700;
	font-family: Arial;
	font-size: 13px;
	white-space: nowrap;
	direction: rtl;
	right: -318px;
	margin: auto;
}
fieldset, img {
	border: 0;
}
#sale-list-edit {
	position: absolute;
	top: 182.1px;
	left: 216.7px;
	width: 46.6px;
	height: 98.1px;
	z-index: 370;
	right: -216.7px;
	margin: auto;
}
#sale-list-item {
	position: absolute;
	top: 221.6px;
	left: 214.8px;
	width: 150px;	
	z-index: 380;
	text-align: right;
	color: rgb(107, 117, 127);
	font-family: ArialMT;
	font-size: 13px;
	white-space: nowrap;
	direction: rtl;
	right: -360px;
	margin: auto;
	.bolet-icon-trash {
		position: absolute;
		left: -10px; 
	}
	.bolet-icon-edit {
		position: absolute;
		left: 10px; 
	}
}
#sale-list-btn-new {
	position: absolute;
	top: 279.6px;
	left: 296.5px;
	width: 133.9px;
	height: 35px;
	z-index: 400;
	background: rgb(62, 196, 168);
	border-radius: 17.5px;
	right: -296.5px;
	margin: auto;
	cursor: pointer;
	outline: none;
	box-sizing: initial;
	border: none;
	transition: all 0.5s;
}
#sale-list-btn-image-new {
	position: absolute;
	top: 163.48px;
	left: 214.8px;
	width: 36.8px;
	height: 36.8px;
	z-index: 350;
	right: -214.8px;
	margin: auto;
	cursor: pointer;
	outline: none;
	box-sizing: initial;
	border: none;
	transition: all 0.5s;
}

#sale-list-btn-text { 
	position: absolute;
	top: 8.6px;
	left: 0.7px;
	width: 100px;
	z-index: 420;
	text-align: center;
	color: rgb(255, 254, 254);
	font-weight: 700;
	font-family: Arial;
	font-size: 14px;
	white-space: nowrap;
	direction: rtl;
	right: -0.7px;
	margin: auto;
}
