.sort {
  margin-bottom: 40px;
}

.sort__descr {
  display: inline-block;
  vertical-align: middle;
  width: 120px;
  font-size: 16px;
  color: $black_two;
  display: none;
}

.form__radio {
  display: inline-block;
  vertical-align: top;
  width: 19.8%;
  margin-right: 1px;
  background-color: $white_two;
  text-align: center;
  color: $black;
  cursor: pointer;
  transition: .5s;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    background-color: $dark_slate_blue;
    .form__label {
      color: $white;
    }
  }
  .form__label {
    width: 100%;
    padding: 11px 5px;
    cursor: pointer;
    color: $black;
  }
  .form__input:checked + .form__label {
    background-color: $dark_slate_blue;
    color: $white;
  }
}



.sort__mobile {
  display: none;
  .custom-select {
    .Select-control {
      border-radius: 0;
      background-color: $white_two;
      border: none;
    }
    .Select-placeholder, 
    .Select--single > .Select-control .Select-value {
      color: $black;
    }
  }
}

.info {
  margin-bottom: 40px;
  background-color: $dark_slate_blue;
  color: $white;
}

.info__item {
  padding: 10px 20px;
}

.info__icon {
  display: inline-block;
  vertical-align: top;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  margin-right: 30px;
  background-color: $white;
  text-align: center;
  color: $greyish_brown;
  font-size: 20px;
  &.icon-passed {
    font-size: 28px;
  }
  &.icon-transactions {
    font-size: 23px;
  }
}

.info__descr {
  display: inline-block;
  vertical-align: top;
  width: 130px;
  margin-top: 5px;
}

.info__title {
  margin-bottom: 5px;
  font-size: 14px;
}

.info__val {
  font-size: 20px;
  font-weight: 700;
}

.graph {
  margin-bottom: 30px;
  img {
    width: 100%;
  }
}

.graph__image {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-right: 20px;
}

.graph__image g:last-of-type {
  pointer-events: none;
}

.graph__descr {
  display: inline-block;
  vertical-align: top;
  width: 80px;
}

.graph__category {
  width: 150px;
  font-size: 16px;
  margin-bottom: 20px;
  display: none;
  .custom-select {
    .Select-control {
      border: none;
      &:hover {
        box-shadow: none;
      }
    }
    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
      color: $black_two;
    }
  }
}

@media only screen and (max-width: 991px) {
  .sort__list {
    margin-bottom: 20px;
  }
  .graph__category {
    display: block;
  }
  .graph__descr {
    display: none;
  }
}

@media only screen and (max-width: 767px) { 
  .sort {
    margin-bottom: 20px;
  }
  .sort__descr {
    display: inline-block;
    width: 100px;
    margin-right: 10px;
  }
  .sort__block {
    display: inline-block;
    vertical-align: top;
  }
  .sort__list {
    display: none;
  }
  .sort__mobile {
    display: inline-block;
    vertical-align: middle;
    width: 180px;
  }
  .choose__select {
    margin-top: 20px;
  }
  .dashboard {
    .row {
      .col-sm-6 {
        &:nth-of-type(2) {
          padding: 0;
        }
      }
    }
  }
  .graph {
    overflow: hidden;
    .responsive-chart {
      overflow: scroll;
    }
    .title {
      padding-left: 15px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .graph {
    h2 {
      margin-bottom: 0;
    }
  }
}
