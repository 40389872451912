.three.columns {
  display: flex;
  .app__language_he & {
    direction: rtl;
  }
}

.column {
  flex: 1;
}

.two.columns {
  display: flex;
  .app__language_he & {
    direction: rtl;
  }
}

.column {
  flex: 1;
}

