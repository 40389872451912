.datepicker {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  color: $black;
  transition: .5s;
  input {
    height: 39px;
    line-height: 37px;
    border: 1px solid $dark_slate_blue;
    border-radius: 20px;
    text-align: center;
  }
}

.datepicker__descr {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  width: 80px;
  color: $black;
}

.react-datepicker__input-container {
  width: 100%;
  input {
    width: 100%;
  }
}

.react-datepicker {
  border-radius: 20px;
  overflow: hidden;
  border-color: $white_four;
  font-size: 11px;
}

.react-datepicker__header {
  background-color: $white;
  border: none;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle,
.react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
  display: none;
}

.react-datepicker__navigation--previous {
  background: url(../../images/left-arrow.png) no-repeat;
  width: 25px;
  height: 25px;
  border: none;
}
.react-datepicker__navigation--next {
  background: url(../../images/right-arrow.png) no-repeat;
  width: 25px;
  height: 25px;
  border: none;
}

.react-datepicker__current-month {
  margin-top: 8px;
  font-size: 13px;
  color: $greyish;
}

.react-datepicker__day-name {
  margin-top: 15px;
}

.react-datepicker__day--outside-month {
  color: $greyish;
}

.react-datepicker__day {
  width: 26px;
  padding-right: 5px;
  line-height: 20px;
  background-color: $white_two;
  text-align: right;
  &:hover,
  &.react-datepicker__day--selected {
    background-color: $dark_slate_blue;
    color: $white;
  }
  &.react-datepicker__day--outside-month {
    background-color: $white;
    &:hover {
      color: $greyish;
    }
  }
}

