*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  min-height: 100%;
  font-size: 14px;
  line-height: 1.2;
  min-width: 320px;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

html {
  height: 100%;
}

html * {
  max-height: 1000000px;

  &:focus {
    outline: none;
  }
}

input {
  border: none;
}

a:link,
a:visited,
a:hover,
a:focus,
a:active {
  outline: 0;
  text-decoration: none;
  // color: inherit;
}

input:focus,
button,
button:active,
button:focus,
span:focus {
  outline: 0;
}

textarea,
textarea:focus,
textarea:active {
  border: 0;
  outline: 0;
  resize: none;
}

button {
  border: none;
  cursor: pointer;
  background: transparent;
}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner {
  border: none;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  list-style: none;
}

label {
  margin: 0;
}

input[type=checkbox],
input[type=file],
input[type="radio"] {
  display: none;
}

.title {
  position: relative;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 700;
  color: $dark_slate_blue;
}

.table>tbody>tr>td {
  border-top: none;
}

.checkbox {
  margin-top: 0;
  margin-bottom: 20px;
}

.checkbox+.checkbox {
  margin-top: 0;
}

.regular-checkbox {
  position: absolute;
  bottom: -20px;
  right: 2px;
}