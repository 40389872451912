.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
  z-index: 1;
}
.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
  &.app__language_he {
    direction: rtl;
    .close {
      float: left;
    }
    .trans__top {
      transform: scale(-1, 1);
      .btn {
        transform: scale(-1, 1);
      }
    }
    .form__search .form__input-wrap {
      transform: scale(-1, 1);
    }
  }
}
.ReactModal__Content--after-open {
  padding: 10px;
  height: 100%;
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
  .modal-content {
    max-width: 1140px;
    margin: 0 auto;
  }
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}
.ReactModal__Content.modal-dialog {
  border: none;
  background-color: transparent;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.modal-content {
  border-radius: 20px;
  overflow: hidden;
}

// .vertical-alignment-helper {
//   display: table;
//   height: 100%;
//   width: 100%;
// }

// .vertical-align-center {
//   display: table-cell;
//   vertical-align: middle;
// }

.modal-dialog {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.modal-header {
  padding: 20px 40px;
  background-color: $white_two;
  color: $dark_slate_blue;
  border-bottom: 1px solid $greyish_two;
  .close {
    margin-top: 10px;
    font-size: 18px;
    color: $dark_indigo;
    opacity: 1;
  }
}

.modal-title {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
}

.modal-body {
  padding: 40px 35px;
  .griddle-body > div > .table {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .griddle-body > div > div .table {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.modal__txt {
  margin-bottom: 20px;
  color: $greyish_brown_two;
}

.modal__help {
  width: 500px;
  margin: 0 auto;
}

.modal-footer {
  display: none;
}

.modal__trans {
  width: 100%;
  .modal-title {
    font-size: 24px;
  }
  .modal-body {
    padding: 20px 30px;
  }
  .modal-content {
    max-width: 80%;//1140px;
    margin: 0 auto;
  }
  .report__button {
    padding: 9px 12px;
    background-color: #e7e7e7;
    border-radius: 20px;
    font-size: 14px;
    color: #16325b;
  }
}

@media only screen and (max-width: 1200px) {
  .modal__trans {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .modal-body {
    padding: 20px;
  }
  .modal__help {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .modal__trans .modal-body {
    padding: 20px 15px;
  }
}