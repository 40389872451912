@charset "UTF-8";
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

body {
  min-height: 100%;
  font-size: 14px;
  line-height: 1.2;
  min-width: 320px;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box; }

html {
  height: 100%; }

html * {
  max-height: 1000000px; }
  html *:focus {
    outline: none; }

input {
  border: none; }

a:link,
a:visited,
a:hover,
a:focus,
a:active {
  outline: 0;
  text-decoration: none; }

input:focus,
button,
button:active,
button:focus,
span:focus {
  outline: 0; }

textarea,
textarea:focus,
textarea:active {
  border: 0;
  outline: 0;
  resize: none; }

button {
  border: none;
  cursor: pointer;
  background: transparent; }

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner {
  border: none; }

p {
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0; }

ul {
  margin: 0;
  list-style: none; }

label {
  margin: 0; }

input[type=checkbox],
input[type=file],
input[type="radio"] {
  display: none; }

.title {
  position: relative;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 700;
  color: #16325b; }

.table > tbody > tr > td {
  border-top: none; }

.checkbox {
  margin-top: 0;
  margin-bottom: 20px; }

.checkbox + .checkbox {
  margin-top: 0; }

.regular-checkbox {
  position: absolute;
  bottom: -20px;
  right: 2px; }

.app__language_he h2,
.app__language_he .form__element,
.app__language_he .form__search .form__input,
.app__language_he .form_datepicker,
.app__language_he tbody .table__item,
.app__language_he .sort,
.app__language_he .info,
.app__language_he .category__graph,
.app__language_he .report-list,
.app__language_he .vendors,
.app__language_he .nav {
  direction: rtl;
  text-align: right; }

.app__language_he .vendors .table__wrap {
  direction: initial; }
  .app__language_he .vendors .table__wrap .table {
    direction: rtl; }

.app__language_he tbody .table__item {
  border-color: #e1e1e1; }
  .app__language_he tbody .table__item.table__item-date {
    direction: ltr; }

.app__language_he .profile__logout .profile__icon {
  margin-right: 20px; }

.app__language_he .nav .he__profile {
  left: 83.33333%; }

.app__language_he .nav .he__logout {
  left: auto;
  right: 16.66667%; }

.app__language_he .nav .he__menu {
  right: auto;
  left: 41.66667%; }

.app__language_he .nav .he__breadcrumbs {
  right: 25%; }

.app__language_he .sort .col-md-4 {
  right: 66.66667%; }

.app__language_he .sort .col-md-8 {
  left: 33.33333%; }

.app__language_he .sort .custom-select .Select-arrow-zone {
  padding-left: 20px; }

.app__language_he .menu__wrap {
  left: 82%; }

.app__language_he .main__content-wrap {
  right: 10%; }

.app__language_he .menu__item {
  direction: rtl; }
  .app__language_he .menu__item .dropdown {
    text-align: right; }
    .app__language_he .menu__item .dropdown:before {
      right: auto;
      left: 20px;
      transform: scale(-1); }
  .app__language_he .menu__item .dropdown__link {
    padding-right: 45px;
    padding-left: 10px; }
  .app__language_he .menu__item .no_items {
    font-size: 16px;
    padding-right: 30px;
    padding-left: 10px; }

.app__language_he .info__icon {
  margin-right: 0;
  margin-left: 30px; }

.app__language_he .category__graph .graph__wrap {
  left: 50%; }

.app__language_he .category__graph .griddle__wrap {
  right: 50%; }

.app__language_he .table__icon {
  margin-right: 0;
  margin-left: 15px; }

.app__language_he .summary .table__item:last-of-type {
  border: none; }

.app__language_he .table__item:last-of-type {
  border-right: 1px solid #e1e1e1; }

.app__language_he .vendors .datepicker__group .scale__wrap {
  left: 50%; }

.app__language_he .vendors .datepicker__group .trans__wrap {
  right: 25%; }

.app__language_he .vendors .buttons__group {
  display: flex; }

.app__language_he .create-report .form__label:before {
  margin-right: 10px;
  margin-left: 10px; }

.app__language_he .checkbox__label:before {
  margin-right: 0;
  margin-left: 10px; }

.app__language_he .table__item .checkbox {
  text-align: center; }

.app__language_he .search .checkbox {
  margin-left: 0;
  margin-right: 12px; }

.app__language_he thead .table__item:first-of-type .table__item_fix {
  border-top-left-radius: 0;
  border-top-right-radius: 20px; }

.app__language_he thead .table__item:last-of-type .table__item_fix {
  border-top-right-radius: 0;
  border-top-left-radius: 20px; }

.app__language_he .header__language .custom-select .has-value.Select--single:not(.is-focused) > .Select-control > .Select-value .Select-value-label,
.app__language_he .header__language .custom-select .has-value.is-pseudo-focused.Select--single > .Select-control > .Select-value .Select-value-label {
  color: #fff; }

.app__language_he .header__language .custom-select .Select--single > .Select-control .Select-value {
  color: #fff; }

.app__language_he .report-page .datepicker input {
  direction: ltr; }

.app__language_he .griddle-next {
  float: left;
  transform: scale(-1); }

.app__language_he .griddle-previous {
  transform: scale(1); }

.app__language_he .form_datepicker .form__input-wrap {
  direction: ltr; }

.app__language_he .table thead .table__item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 20px; }

.app__language_he .table thead .table__item:last-of-type {
  border-top-right-radius: 0;
  border-top-left-radius: 20px; }

.app__language_he .table__foot .table__item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 20px; }

.content {
  padding-bottom: 59px; }

.report-list .sort__descr {
  width: auto;
  padding-left: 15px;
  margin-bottom: 20px;
  display: block; }

.report-list .sort__list {
  margin-bottom: 20px; }

.report-list .form__radio {
  width: 24.8%; }

.report-list .table__wrap {
  margin-bottom: 20px;
  overflow-y: auto; }

.report-list .table__item .icon-arrow-down {
  right: -20px; }

.report-list .table__head {
  color: #fff; }
  .report-list .table__head .table__item {
    height: 46px; }

.report-list .table__header_bg {
  height: 46px; }

.create-report .form__label {
  padding-left: 15px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #000000; }
  .create-report .form__label.icon-report {
    font-size: 16px; }
  .create-report .form__label:before {
    display: inline-block;
    margin-right: 10px;
    font-size: 19px;
    color: #16325b; }

.create-report .form__input {
  height: 39px;
  line-height: 37px; }

.create-report .title {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 18px; }

.create-report .checkbox .checkbox__label {
  padding-left: 15px; }

.create-report .table__item .checkbox .checkbox__label {
  padding-left: 0; }

.create-report .table__item .icon-arrow-down {
  right: -20px; }

.report-page .react-datepicker__input-container {
  width: 160px; }
  .report-page .react-datepicker__input-container:before {
    content: "\e805";
    position: absolute;
    right: 15px;
    top: 8px;
    font-family: 'font-icon';
    font-size: 18px;
    color: #fff;
    z-index: 1;
    cursor: pointer;
    pointer-events: none; }
  .report-page .react-datepicker__input-container:after {
    content: "";
    width: 45px;
    height: 39px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #16325b;
    cursor: pointer;
    pointer-events: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px; }

.report-page .datepicker input {
  padding-left: 20px;
  padding-right: 45px;
  text-align: left; }

.report-page .form {
  margin-top: 10px; }

.report-page .report__button {
  padding: 9px 12px;
  background-color: #e7e7e7;
  border-radius: 20px;
  font-size: 14px;
  color: #16325b; }

.report-page .table__wrap {
  max-height: 550px;
  margin-bottom: 50px; }

.report-page .table {
  border: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.report-page .table__row {
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7; }

.report-page .table__body {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden; }
  .report-page .table__body .table__item {
    text-align: center; }

.report-page .table__item .icon-arrow-down {
  right: -20px; }

.report-page .table__wrap .griddle-body > div > .table {
  width: 100%; }

.griddle-body .checkbox {
  text-align: center; }

.modal-content .table {
  border: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .modal-content .table .table__head .table__row .table__item {
    padding: 10px 15px;
    padding-right: 20px; }

.modal-content .table__item .icon-arrow-down {
  right: -15px; }

.modal__trans .table__wrap {
  max-height: inherit; }

.modal__trans .table {
  width: 100%; }
  .modal__trans .table .table__head .table__row .table__item {
    text-align: left; }
  .modal__trans .table .table__row .table__item {
    padding: 10px 15px;
    text-align: center; }

.trans__table {
  min-height: 670px; }

@media only screen and (max-width: 1200px) {
  .modal-dialog .table__found-items {
    float: none; } }

@media only screen and (max-width: 991px) {
  .create-report .search .form__search {
    display: block; }
  .create-report .form__label {
    padding-left: 5px; }
  .report-list__wrapper .griddle-container,
  .report-list__wrapper .table__foot {
    width: 925px; }
  .app__language_he .nav .he__profile {
    left: 50%; }
  .app__language_he .nav .he__logout {
    right: 50%; }
  .app__language_he .nav .he__menu {
    left: 83.3333%; }
  .app__language_he .nav .he__breadcrumbs {
    right: 18%; }
  .app__language_he .sort .col-md-4 {
    right: 0; }
  .app__language_he .sort .col-md-8 {
    left: 0; }
  .app__language_he .profile__logout {
    text-align: left; }
  .trans__table .griddle-container,
  .trans__table .table__foot {
    width: 1280px; } }

@media only screen and (max-width: 767px) {
  .content {
    padding-bottom: 88px; }
  .content__wrap .col-sm-9,
  .content__wrap .col-sm-12 {
    padding: 0; }
  .dashboard .row {
    margin: 0; }
  .dashboard .info .col-sm-4 {
    padding: 0; }
  .report-list .sort__descr {
    width: 100px;
    display: inline-block; }
  .report-list .sort__mobile {
    vertical-align: top; }
  .report-list .table__wrap {
    margin-bottom: 0; }
  .report-page .datepicker__group,
  .report-page .buttons__group {
    margin: 0; }
  .report-page .title {
    padding: 0 15px; }
  .create-report .row {
    margin: 0; }
  .create-report .table__wrap {
    margin-bottom: 20px; }
  .create-report .search {
    padding: 0 15px; }
  .create-report .form__label {
    padding-left: 15px; }
  .app__language_he .sort__descr {
    margin-left: 10px;
    margin-right: 0; }
  .app__language_he .title {
    padding-right: 15px; }
  .app__language_he .category__graph .graph__wrap {
    left: 0; }
    .app__language_he .category__graph .graph__wrap .title {
      padding-right: 0; }
  .app__language_he .category__graph .griddle__wrap {
    right: 0; }
  .app__language_he .menu__wrap {
    left: 0; }
  .app__language_he .main__content-wrap {
    right: 0; }
  .app__language_he .sort__mobile {
    margin-right: 15px; }
    .app__language_he .sort__mobile .custom-select .Select-arrow-zone {
      padding-left: 20px;
      padding-right: 0; }
  .app__language_he .create-report_top .checkbox {
    padding: 0 15px; }
  .app__language_he .vendors .datepicker__group .scale__wrap {
    left: 0; }
  .app__language_he .vendors .datepicker__group .trans__wrap {
    right: 0; }
  .text-danger,
  .text-success {
    padding: 0 15px; } }

@media only screen and (max-width: 480px) {
  .report-list .table__wrap .griddle-container {
    width: 616px; }
  .report-list .sort__block {
    margin-left: 15px; }
  .report-list .form__search {
    margin-top: 20px; }
  .create-report .search .checkbox {
    display: block; }
  .create-report .col-sm-8 {
    padding: 0; }
  .create-report .griddle-container {
    width: 500px; }
  .report-page .col-lg-9 {
    padding: 0; }
  .trans__table {
    min-height: inherit; } }

.text-danger,
.text-success {
  margin-bottom: 10px; }

.submit__block {
  margin-top: 30px; }

@media only screen and (max-width: 320px) {
  .modal__trans .table__wrap {
    max-height: 300px; } }

@font-face {
  font-family: 'font-icon';
  src: url("../fonts/font-icon.eot");
  src: url("../fonts/font-icon.eot#iefix") format("embedded-opentype"), url("../fonts/font-icon.woff2") format("woff2"), url("../fonts/font-icon.woff") format("woff"), url("../fonts/font-icon.ttf") format("truetype"), url("../fonts/font-icon.svg#font-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'bolet';
  src: url("../fonts/bolet.eot?32y5rn");
  src: url("../fonts/bolet.eot?32y5rn#iefix") format("embedded-opentype"), url("../fonts/bolet.ttf?32y5rn") format("truetype"), url("../fonts/bolet.woff?32y5rn") format("woff"), url("../fonts/bolet.svg?32y5rn#bolet") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "font-icon";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="bolet-icon-"]:before,
[class*="bolet-icon-"]:before {
  font-family: 'bolet';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* font-icon font */
.icon-arrow-down:before {
  content: '\e800'; }

.icon-arrow-right:before {
  content: '\e801'; }

.icon-arrow-up:before {
  content: '\e802'; }

.icon-clock:before {
  content: '\e803'; }

.icon-close:before {
  content: '\e804'; }

.icon-data:before {
  content: '\e805'; }

.icon-edit:before {
  content: '\e806'; }

.icon-home:before {
  content: '\e807'; }

.icon-left:before {
  content: '\e808'; }

.icon-lock:before {
  content: '\e809'; }

.icon-log-out:before {
  content: '\e80a'; }

.icon-media:before {
  content: '\e80b'; }

.icon-menu:before {
  content: '\e80c'; }

.icon-minus:before {
  content: '\e80d'; }

.icon-passed:before {
  content: '\e80e'; }

.icon-plus:before {
  content: '\e80f'; }

.icon-question:before {
  content: '\e810'; }

.icon-report:before {
  content: '\e811'; }

.icon-right:before {
  content: '\e812'; }

.icon-sales:before {
  content: '\e813'; }

.icon-search:before {
  content: '\e814'; }

.icon-transactions:before {
  content: '\e815'; }

.icon-update:before {
  content: '\e816'; }

.icon-user:before {
  content: '\e817'; }

.icon-vendors:before {
  content: '\e818'; }

.icon-excel:before {
  content: '\e81b'; }

.icon-pdf:before {
  content: '\e81c'; }

/*    bolet.ttf    */
.bolet-icon-calendar:before {
  content: "\e900"; }

.bolet-icon-edit:before {
  content: "\e903"; }

.bolet-icon-Fold-Down:before {
  content: "\e904"; }

.bolet-icon-Fold-Up:before {
  content: "\e905"; }

.bolet-icon-two-way-arrows:before {
  content: "\e906"; }

.bolet-icon-trash:before {
  content: "\e907"; }

.header {
  padding: 9px 0;
  background-color: #0f2549; }

.logo {
  width: 165px; }

.header__language {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  margin-top: 10px;
  margin-right: 25px;
  text-align: left; }
  .header__language .custom-select .Select-control {
    width: 70%;
    height: 34px;
    background-color: #224372;
    overflow: visible;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    color: #fff; }
  .header__language .custom-select .Select-placeholder,
  .header__language .custom-select .Select--single > .Select-control .Select-value {
    line-height: 32px;
    padding-left: 20px; }
  .header__language .custom-select .Select--single > .Select-control .Select-value {
    color: #fff; }
  .header__language .custom-select .has-value.Select--single:not(.is-focused) > .Select-control > .Select-value .Select-value-label,
  .header__language .custom-select .has-value.is-pseudo-focused.Select--single > .Select-control > .Select-value .Select-value-label {
    color: #fff; }
  .header__language .custom-select .is-open > .Select-control {
    border-color: #224372; }
  .header__language .custom-select .is-open .Select-arrow-zone {
    border-bottom-right-radius: 0; }
  .header__language .custom-select .is-open .Select-arrow {
    transform: scale(-1); }
  .header__language .custom-select .Select-arrow-zone {
    position: absolute;
    top: 0;
    right: -32px;
    width: 30px;
    height: 34px;
    padding-right: 2px;
    line-height: 34px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #224372; }
  .header__language .custom-select .Select-arrow {
    border-color: #fff transparent transparent; }
  .header__language .custom-select .Select-arrow-zone:hover > .Select-arrow {
    border-top-color: #fff; }
  .header__language .custom-select .Select-input {
    height: 32px;
    padding-left: 20px; }

.header__help {
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  margin-top: 10px;
  background-color: #224372;
  text-align: center;
  line-height: 36px;
  color: #fff;
  border-radius: 50%;
  transition: .5s;
  cursor: pointer; }
  .header__help i {
    color: #fff; }
  .header__help:hover {
    background-color: #19355c; }

@media only screen and (max-width: 480px) {
  .logo {
    width: 100%; }
  .header__language {
    margin-top: 0;
    margin-right: 10px;
    margin-left: -20px; }
  .header__help {
    margin-top: 0; } }

.nav {
  padding: 18px 0;
  margin-bottom: 30px;
  background-color: #16325b; }

.nav__separator {
  margin-bottom: 10px;
  height: 1px;
  background-color: #fff;
  clear: both; }

.profile {
  background-color: #0f2549;
  border-radius: 20px; }
  .profile .profile__icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    background-color: #fff;
    color: #16325b; }
  .profile .profile__descr {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 16px; }

.profile__icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  color: #fff;
  text-align: center; }

.profile__descr {
  display: inline-block;
  vertical-align: middle;
  color: #e7e7e7; }

.profile__logout {
  padding-top: 6px; }
  .profile__logout .profile__icon {
    margin-left: 20px; }

.menu__button {
  display: block;
  margin: 0 auto;
  padding-top: 6px;
  font-size: 20px;
  color: #fff; }

.menu__list {
  background-color: #e7e7e7;
  text-align: right;
  transition: .5s;
  z-index: 1; }

.menu__item:last-of-type .dropdown {
  border-bottom: none; }

.menu__icon {
  object-fit: contain; }

.dropdown {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  color: #393535; }
  .dropdown.dropdown_open:before {
    transform: rotate(45deg); }
  .dropdown:before {
    position: absolute;
    right: 20px;
    top: 22px;
    color: #16325b;
    font-size: 10px; }
  .dropdown i {
    display: inline-block;
    margin-left: 10px;
    margin-right: 20px;
    font-size: 21px; }

.dropdown__menu {
  width: 100%;
  margin-top: 15px; }

.dropdown__link {
  display: block;
  padding: 12px 0;
  padding-left: 45px;
  font-size: 14px;
  color: #393535; }
  .dropdown__link.dropdown__link_active, .dropdown__link:hover {
    color: #16325b;
    background-color: #d8d8d8; }

.breadcrumbs__wrapper {
  padding-top: 9px; }

.breadcrumbs {
  letter-spacing: 0.3px;
  font-size: 13px;
  display: inline-block;
  vertical-align: top; }

.breadcrumbs__item {
  display: inline-block;
  vertical-align: middle;
  color: grey; }
  .breadcrumbs__item:first-child:before {
    content: '/';
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    color: #e7e7e7; }
  .breadcrumbs__item:after {
    content: '/';
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    color: #e7e7e7; }
  .breadcrumbs__item:last-of-type:after {
    display: none; }
  .breadcrumbs__item a {
    color: #e7e7e7;
    cursor: pointer; }
    .breadcrumbs__item a:hover {
      color: #e7e7e7;
      text-decoration: underline; }

.breadcrumbs__home-link {
  display: inline-block;
  vertical-align: top;
  color: #fff; }

@media only screen and (max-width: 991px) {
  .profile {
    margin-bottom: 10px; }
  .menu__button {
    margin: 0; } }

@media only screen and (max-width: 767px) {
  .menu__list {
    margin-bottom: 20px; } }

@media only screen and (max-width: 480px) {
  .breadcrumbs {
    max-width: inherit;
    margin-left: -15px; }
  .breadcrumbs__item:first-of-type {
    margin-left: 15px; } }

.status__header_wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }

.status__header {
  display: flex;
  align-items: center;
  padding-bottom: 15px; }

.status__sum {
  font-size: 14px; }

.status__select {
  width: 155px;
  height: 36px;
  border-radius: 4px;
  margin-left: 100px;
  border: 1px solid #0f2649;
  appearance: none;
  padding: 2px 2px 2px 2px;
  background: transparent url("../../images/dropdown-arrow.png") no-repeat 60px;
  background-position: 5px; }

.status__buttons-header {
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #0f2649; }

.status__button {
  width: 58px;
  height: 20px;
  border-radius: 10px;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding-top: 2px;
  color: #ffffff;
  margin-left: 10px;
  background-color: #f85f58; }
  .status__button:hover {
    color: #ffffff; }
  .status__button--green {
    background-color: #3ec4a8; }
  .status__button--yellow {
    background-color: #f2c819; }
  .status__button--red {
    background-color: #f85f58; }
  .status__button--gray {
    background-color: #d8d8d8; }
  .status__button--darkgray {
    background-color: #a5a5a5; }

.status__grid-header {
  display: grid;
  grid-template-columns: repeat(12, 86px);
  grid-template-rows: 44px;
  background-color: #f6f6f6;
  color: black; }
  .status__grid-header-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #0f2649; }
  .status__grid-header-fixed {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-right: 4px;
    font-size: 12px;
    cursor: pointer; }
  .status__grid-header-button {
    grid-column-start: 12; }

.status__grid-container {
  display: grid;
  grid-template-columns: repeat(11, 81px);
  grid-auto-rows: 64px;
  grid-gap: 5px;
  max-height: 480px;
  overflow-y: auto; }

.status__grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.status__item-image {
  padding-bottom: 4px; }
  .status__item-image--small {
    width: 26px;
    height: 30px; }
  .status__item-image--large {
    width: 40px;
    height: 40px; }

.status__item-text {
  font-size: 11px;
  letter-spacing: 1px; }

.status__item--green {
  background-color: #3ec4a8; }

.status__item--yellow {
  background-color: #f2c819; }

.status__item--red {
  background-color: #f85f58; }

.status__item--gray {
  background-color: #e7e7e7; }

.status__item--darkgray {
  background-color: #a5a5a5; }

.return-button {
  width: 100px;
  height: 20px;
  border-radius: 10px;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding-top: 2px;
  color: #ffffff;
  background-color: #16325b;
  margin-right: 320px; }
  .return-button:hover {
    width: 110px;
    color: #ffffff; }

/* Tooltip container */
.item-tooltip {
  position: relative; }

.item-tooltip .tooltiptext {
  visibility: hidden;
  width: 170px;
  height: 270px;
  left: -170px;
  top: 0px;
  background-color: #f6f6f6;
  border: 1px solid #b8b3b3;
  color: black;
  text-align: right;
  padding: 5px 0;
  position: absolute;
  z-index: 1; }
  .item-tooltip .tooltiptext pre {
    border: none; }

.item-tooltip:hover .tooltiptext {
  visibility: visible; }

.item-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 30px;
  left: 100%;
  /* To the right of the tooltip */
  margin-top: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #f6f6f6; }

.details__name {
  font-size: 16px;
  font-weight: bold;
  color: #0f2649;
  padding-bottom: 10px; }

.details__container {
  box-sizing: border-box;
  margin: 0 auto;
  display: flex; }

.details__right {
  width: 154px;
  height: 468px;
  background-color: #f6f6f6; }

.details__center {
  margin-right: 30px;
  margin-top: 20px;
  width: 45%;
  display: grid;
  grid-template-columns: repeat(6, 80px) auto;
  grid-auto-rows: 35px; }

.details__left {
  position: relative;
  top: -90px;
  width: 411px;
  margin-right: 50px;
  display: flex;
  flex-direction: column; }
  .details__left-image-container {
    height: 321px;
    background-color: #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center; }
  .details__left-image {
    width: 164px;
    height: auto;
    cursor: pointer; }
  .details__left-button {
    position: relative;
    display: block;
    width: 81px;
    height: 28px;
    top: -14px;
    right: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
    font-weight: bold; }
  .details__left-text-container {
    height: 400px;
    background-color: #f6f6f6; }
  .details__left-name {
    margin-right: 50px;
    margin-bottom: 20px;
    font-size: 34px;
    color: #0f2649; }
  .details__left-text {
    display: grid;
    grid-template-columns: 170px 1fr;
    margin-right: 30px;
    color: #0f2649;
    font-size: 16px;
    line-height: 30px; }
    .details__left-text strong {
      font-size: 18px; }

.line {
  grid-column: 1 / -1;
  height: 2px;
  border-bottom: 1px solid #cecbcb;
  width: 100%;
  padding-bottom: 10px; }

.row__name {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center; }

.row__items {
  display: flex; }

.row__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .row__item-text {
    font-size: 9px;
    color: #9b9b9b;
    padding-bottom: 4px; }
  .row__item-button {
    display: block;
    width: 58px;
    height: 20px;
    border-radius: 10px;
    font-family: Arial;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding-top: 2px;
    color: #ffffff;
    margin-left: 10px; }
    .row__item-button--green {
      background-color: #3ec4a8; }
    .row__item-button--yellow {
      background-color: #f2c819; }
    .row__item-button--red {
      background-color: #f85f58; }
    .row__item-button--gray {
      background-color: #d8d8d8; }
    .row__item-button--darkgray {
      background-color: #a5a5a5; }

.scales-formatter {
  background-color: red; }

.red-icon {
  text-align: center; }

.meir {
  width: 700px;
  position: relative;
  top: 23px;
  right: 340px; }

.pointer {
  width: 100px;
  height: 40px;
  position: relative;
  background: red; }
  .pointer:hover {
    cursor: pointer; }

.pointer:after {
  content: "";
  position: absolute;
  right: -5px;
  bottom: 0;
  width: 0;
  height: 0;
  transform: rotate(-180deg);
  border-left: 20px solid white;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; }

.pointer:before {
  content: "";
  position: absolute;
  right: 60px;
  bottom: 0;
  width: 0;
  height: 0;
  transform: rotate(-180deg);
  border-left: 20px solid red;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; }

.advd {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: 800px;
  top: 5px;
  right: 50px;
  background-color: black; }

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 17px 0;
  background-color: #0f2549; }

.copyright {
  color: #fff;
  font-size: 13px;
  opacity: .5; }

.update {
  color: #fff;
  font-size: 16px; }

.update__icon {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  margin-left: 20px; }

.update__descr {
  display: inline-block;
  vertical-align: middle; }

@media only screen and (max-width: 767px) {
  .copyright,
  .update {
    text-align: center; }
  .update {
    margin-bottom: 15px; } }

.form {
  border-radius: 20px;
  background-color: #fff; }

.form__body {
  padding: 30px; }

.form__title {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px 30px;
  border-bottom: 1px solid #979797;
  background-color: #e7e7e7;
  color: #16325b;
  font-size: 30px;
  font-weight: bold; }

.form__input-wrap {
  position: relative;
  margin-bottom: 15px; }
  .form__input-wrap:hover .input__icon {
    color: #fff; }

.form__input-wrap2 {
  position: relative; }
  .form__input-wrap2:hover .input__icon {
    display: none; }

.form__input {
  width: 100%;
  background-color: #fff;
  border: 1px solid #16325b;
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 60px;
  transition: .5s; }
  .form__input:hover {
    background-color: #e7e7e7;
    border-color: #e7e7e7; }

.form__input_error {
  border-color: #a94442; }

.input__icon {
  position: absolute;
  right: 20px;
  top: 16px;
  color: #b6b6b6;
  font-size: 18px;
  transition: .5s;
  pointer-events: none; }

.form__label {
  color: #16325b;
  font-weight: normal; }

.form__login {
  width: 460px; }
  .form__login .form__input {
    width: 100%;
    height: 52px;
    line-height: 50px; }
  .form__login .form__label {
    margin-left: 27px;
    margin-bottom: 5px; }
  .form__login .form__button {
    display: block;
    width: 100%;
    height: 52px;
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 2px 2px #03142d; }

.form__link {
  float: right;
  margin-bottom: 15px;
  font-size: 16px;
  color: #16325b;
  border-bottom: 1px dashed transparent;
  transition: .5s; }
  .form__link:hover {
    color: #16325b;
    border-color: #16325b; }

.form__search .form__input-wrap {
  margin-bottom: 25px; }

.form__search .form__input {
  height: 39px;
  padding-left: 60px;
  line-height: 37px; }

.form__search .input__icon {
  right: auto;
  left: 15px;
  top: 10px;
  font-size: 19px;
  color: #16325b; }

.checkbox .checkbox__label {
  padding-left: 0; }

.checkbox__label {
  min-height: auto; }
  .checkbox__label:before {
    content: "";
    display: inline-block;
    vertical-align: top;
    width: 17px;
    height: 17px;
    line-height: 15px;
    margin-right: 10px;
    border: 1px solid #d6d6d6;
    background-color: #fff;
    text-align: center; }

.checkbox__input:checked + .checkbox__label:before {
  content: "\e808";
  font-family: 'font-icon';
  transform: rotate(-90deg); }

@media only screen and (max-width: 767px) {
  .form__search {
    margin: 0 15px;
    margin-top: 20px; } }

@media only screen and (max-width: 480px) {
  .form__login {
    width: 100%; }
  .form__body {
    padding: 30px 15px; } }

.btn-primary {
  border-radius: 20px;
  background-color: #16325b;
  border-color: #16325b; }
  .btn-primary:hover {
    background: linear-gradient(to bottom, #1e4f83 0%, #0c2549 100%); }

.btn:focus:active,
.btn:focus,
.btn:active {
  outline: none; }

.custom-select .Select-control {
  position: relative;
  height: 39px;
  border-radius: 20px;
  border: 1px solid #16325b; }

.custom-select .Select-clear-zone {
  display: none; }

.custom-select .Select-placeholder,
.custom-select .Select--single > .Select-control .Select-value {
  padding-left: 30px;
  line-height: 37px; }

.custom-select .Select-arrow-zone {
  padding-right: 20px; }

.custom-select .Select-arrow {
  border-width: 7px 5px 0;
  border-color: #16325b transparent transparent; }

.custom-select .Select-input {
  height: 37px;
  line-height: 37px;
  padding-left: 30px; }

.custom-select .is-open .Select-control {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.header__language .has-value:not(.Select--multi):not(.is-focused) > .Select-control > .Select-value .Select-value-label, .header__language .has-value.is-pseudo-focused:not(.Select--multi) > .Select-control > .Select-value .Select-value-label {
  color: #0ff; }

.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5); }

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
  z-index: 1; }

.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg); }
  .ReactModal__Content.app__language_he {
    direction: rtl; }
    .ReactModal__Content.app__language_he .close {
      float: left; }
    .ReactModal__Content.app__language_he .trans__top {
      transform: scale(-1, 1); }
      .ReactModal__Content.app__language_he .trans__top .btn {
        transform: scale(-1, 1); }
    .ReactModal__Content.app__language_he .form__search .form__input-wrap {
      transform: scale(-1, 1); }

.ReactModal__Content--after-open {
  padding: 10px;
  height: 100%;
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in; }
  .ReactModal__Content--after-open .modal-content {
    max-width: 1140px;
    margin: 0 auto; }
  .ReactModal__Content--after-open:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in; }

.ReactModal__Content.modal-dialog {
  border: none;
  background-color: transparent; }

.ReactModal__Body--open {
  overflow: hidden; }

.modal-content {
  border-radius: 20px;
  overflow: hidden; }

.modal-dialog {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  margin: 0; }

.modal-header {
  padding: 20px 40px;
  background-color: #e7e7e7;
  color: #16325b;
  border-bottom: 1px solid #b6b6b6; }
  .modal-header .close {
    margin-top: 10px;
    font-size: 18px;
    color: #0f2549;
    opacity: 1; }

.modal-title {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2; }

.modal-body {
  padding: 40px 35px; }
  .modal-body .griddle-body > div > .table {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .modal-body .griddle-body > div > div .table {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }

.modal__txt {
  margin-bottom: 20px;
  color: #4a4a4a; }

.modal__help {
  width: 500px;
  margin: 0 auto; }

.modal-footer {
  display: none; }

.modal__trans {
  width: 100%; }
  .modal__trans .modal-title {
    font-size: 24px; }
  .modal__trans .modal-body {
    padding: 20px 30px; }
  .modal__trans .modal-content {
    max-width: 80%;
    margin: 0 auto; }
  .modal__trans .report__button {
    padding: 9px 12px;
    background-color: #e7e7e7;
    border-radius: 20px;
    font-size: 14px;
    color: #16325b; }

@media only screen and (max-width: 1200px) {
  .modal__trans {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .modal-body {
    padding: 20px; }
  .modal__help {
    width: 100%; } }

@media only screen and (max-width: 480px) {
  .modal__trans .modal-body {
    padding: 20px 15px; } }

.table {
  table-layout: fixed; }

::-webkit-scrollbar {
  width: 5px; }

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px; }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }

.table__wrap {
  margin-bottom: 20px;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 593px; }
  .table__wrap .griddle-body > div > .table {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    width: 99.4%; }
  .table__wrap .griddle-body > div > div .table {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }

@-moz-document url-prefix() {
  .table__wrap .griddle-body > div > .table {
    width: 99.1%; } }

.table__body .table {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: none; }

.table__head {
  padding-right: 15px; }
  .table__head .table {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.table__foot .table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px; }

.table__foot .table__item:last-of-type {
  border-bottom-right-radius: 20px; }

.table {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #e7e7e7;
  background-color: #ebebeb;
  overflow: hidden;
  margin-bottom: 0; }
  .table .table__row .table__item {
    padding: 10px 25px;
    vertical-align: middle; }
  .table thead .table__item {
    height: 41px;
    position: static;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center; }
    .table thead .table__item:first-of-type {
      border-top-left-radius: 20px; }
    .table thead .table__item:last-of-type {
      border-top-right-radius: 20px; }
  .table thead .table__row .table__item {
    padding: 0; }

thead {
  color: #e7e7e7; }
  thead .table__item {
    background-color: #16325b;
    cursor: pointer; }
    thead .table__item:first-of-type .table__item_fix {
      border-top-left-radius: 20px; }
    thead .table__item:last-of-type .table__item_fix {
      border-top-right-radius: 20px; }

tbody {
  color: #000000;
  overflow-y: auto;
  overflow-x: hidden; }
  tbody .table__row:nth-of-type(odd) {
    background-color: #fff; }

.table__item {
  position: relative;
  border-top: none;
  border-right: 1px solid #e1e1e1; }
  .table__item:last-of-type {
    border-right: none; }
  .table__item .table__arrow {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 10px; }
  .table__item .table__edit {
    font-size: 16px;
    color: #16325b;
    cursor: pointer; }
    .table__item .table__edit:before {
      display: block; }
  .table__item .table__del {
    color: #c95252;
    cursor: pointer; }
    .table__item .table__del:before {
      display: block; }
  .table__item .checkbox {
    margin-bottom: 0;
    color: #000000; }
  .table__item .checkbox__label:before {
    margin: 0; }

.table__item_fix {
  position: absolute;
  top: 1px;
  padding: 10px 25px;
  background-color: #16325b;
  cursor: pointer;
  z-index: 1; }

.table__link {
  color: #000000;
  word-wrap: break-word; }

.table__title {
  display: inline-block;
  position: relative; }

.table__icon {
  display: inline-block;
  margin-right: 15px;
  font-size: 18px; }

.table__footer {
  background-color: #fff;
  font-size: 16px; }
  .table__footer .table__row {
    border: none; }
    .table__footer .table__row .table__item {
      padding: 15px 20px; }
  .table__footer .table__item {
    border: none;
    text-align: center; }

.table__item-inside {
  display: inline-block; }

.griddle-page,
.griddle-next,
.griddle-previous {
  display: inline-block;
  padding: 0 20px; }

.griddle-next button,
.griddle-previous button {
  font-size: 0; }

.griddle-next button:after,
.griddle-previous button:after {
  content: "\e801";
  font-family: "font-icon";
  display: inline-block;
  font-size: 14px;
  cursor: pointer; }

.griddle-previous {
  transform: scale(-1); }

.griddle-next {
  float: right; }

.griddle-body tbody + tbody {
  background: #16325b;
  color: #fff; }

.griddle-body select {
  color: #000; }

@media only screen and (max-width: 767px) {
  .table {
    margin-bottom: 0;
    overflow: visible;
    border-radius: 0; }
    .table .table__row .table__item {
      padding: 10px; }
  .table__wrap .title {
    padding-left: 15px; } }

@media only screen and (max-width: 480px) {
  .table__wrap {
    max-height: 480px; } }

.rc-tree-title {
  color: #333;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px; }

.rc-tree li {
  margin-bottom: 12px; }

.rc-tree-child-tree li {
  margin-bottom: 12px; }
  .rc-tree-child-tree li span.rc-tree-switcher {
    display: none; }

.rc-tree-child-tree .rc-tree-title {
  font-weight: 400;
  font-size: 14px; }

.rc-tree li .rc-tree-child-tree {
  margin-top: 12px;
  margin-left: 20px; }

.rc-tree li span.rc-tree-checkbox {
  width: 17px;
  height: 17px;
  line-height: 15px;
  margin-right: 10px;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  background-image: none;
  position: relative; }
  .rc-tree li span.rc-tree-checkbox.rc-tree-checkbox-checked:before {
    content: "\E808";
    font-family: font-icon;
    transform: rotate(-90deg);
    position: absolute;
    top: 0;
    left: 5px; }

.rc-tree li span.rc-tree-switcher {
  width: 17px;
  height: 17px;
  line-height: 15px;
  position: relative;
  background: none; }
  .rc-tree li span.rc-tree-switcher:before {
    content: "+";
    position: absolute;
    left: 0;
    top: 0;
    line-height: 1;
    font-size: 17px;
    font-weight: 700;
    cursor: pointer; }
  .rc-tree li span.rc-tree-switcher.rc-tree-noline_open:before {
    content: "-"; }

.tree__childs_opened,
.tree__childs_closed {
  display: inline-block;
  vertical-align: top;
  width: 17px;
  height: 17px;
  line-height: 17px;
  font-size: 0;
  font-weight: bold;
  cursor: pointer;
  text-align: center; }

.tree__childs_opened:before {
  content: "-";
  font-size: 18px; }

.tree__childs_closed:before {
  content: "+";
  font-size: 18px; }

.tree__parent .checkbox {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0; }

.tree__parent .checkbox__label {
  color: #333;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px; }

.tree__child .checkbox__label {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px; }

.tree__child .checkbox {
  margin-bottom: 12px; }

.datepicker {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  color: #000000;
  transition: .5s; }
  .datepicker input {
    height: 39px;
    line-height: 37px;
    border: 1px solid #16325b;
    border-radius: 20px;
    text-align: center; }

.datepicker__descr {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  width: 80px;
  color: #000000; }

.react-datepicker__input-container {
  width: 100%; }
  .react-datepicker__input-container input {
    width: 100%; }

.react-datepicker {
  border-radius: 20px;
  overflow: hidden;
  border-color: #d6d6d6;
  font-size: 11px; }

.react-datepicker__header {
  background-color: #fff;
  border: none; }

.react-datepicker__tether-element-attached-top .react-datepicker__triangle,
.react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
  display: none; }

.react-datepicker__navigation--previous {
  background: url(../../images/left-arrow.png) no-repeat;
  width: 25px;
  height: 25px;
  border: none; }

.react-datepicker__navigation--next {
  background: url(../../images/right-arrow.png) no-repeat;
  width: 25px;
  height: 25px;
  border: none; }

.react-datepicker__current-month {
  margin-top: 8px;
  font-size: 13px;
  color: #b0b0b0; }

.react-datepicker__day-name {
  margin-top: 15px; }

.react-datepicker__day--outside-month {
  color: #b0b0b0; }

.react-datepicker__day {
  width: 26px;
  padding-right: 5px;
  line-height: 20px;
  background-color: #e7e7e7;
  text-align: right; }
  .react-datepicker__day:hover, .react-datepicker__day.react-datepicker__day--selected {
    background-color: #16325b;
    color: #fff; }
  .react-datepicker__day.react-datepicker__day--outside-month {
    background-color: #fff; }
    .react-datepicker__day.react-datepicker__day--outside-month:hover {
      color: #b0b0b0; }

.preloader-wrapper {
  position: relative;
  height: 80px;
  margin-top: 20px; }

.sk-circle {
  display: inline-block;
  width: 40px;
  height: 32px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto; }

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg); }

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg); }

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg); }

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg); }

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg); }

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg); }

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg); }

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg); }

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.media-list {
  max-height: calc(100vh - 300px);
  overflow: auto; }
  .media-list img {
    width: 300px;
    height: auto; }
  .media-list ul {
    list-style: none;
    padding: 0; }
  .media-list li {
    margin: 20px 0; }

.sign-in {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 121px);
  padding: 20px;
  background: url(../../images/bg1.png) no-repeat center top #254778;
  background-size: cover; }

.sort {
  margin-bottom: 40px; }

.sort__descr {
  display: inline-block;
  vertical-align: middle;
  width: 120px;
  font-size: 16px;
  color: #393535;
  display: none; }

.form__radio {
  display: inline-block;
  vertical-align: top;
  width: 19.8%;
  margin-right: 1px;
  background-color: #e7e7e7;
  text-align: center;
  color: #000000;
  cursor: pointer;
  transition: .5s; }
  .form__radio:last-of-type {
    margin-right: 0; }
  .form__radio:hover {
    background-color: #16325b; }
    .form__radio:hover .form__label {
      color: #fff; }
  .form__radio .form__label {
    width: 100%;
    padding: 11px 5px;
    cursor: pointer;
    color: #000000; }
  .form__radio .form__input:checked + .form__label {
    background-color: #16325b;
    color: #fff; }

.sort__mobile {
  display: none; }
  .sort__mobile .custom-select .Select-control {
    border-radius: 0;
    background-color: #e7e7e7;
    border: none; }
  .sort__mobile .custom-select .Select-placeholder,
  .sort__mobile .custom-select .Select--single > .Select-control .Select-value {
    color: #000000; }

.info {
  margin-bottom: 40px;
  background-color: #16325b;
  color: #fff; }

.info__item {
  padding: 10px 20px; }

.info__icon {
  display: inline-block;
  vertical-align: top;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  margin-right: 30px;
  background-color: #fff;
  text-align: center;
  color: #403c3c;
  font-size: 20px; }
  .info__icon.icon-passed {
    font-size: 28px; }
  .info__icon.icon-transactions {
    font-size: 23px; }

.info__descr {
  display: inline-block;
  vertical-align: top;
  width: 130px;
  margin-top: 5px; }

.info__title {
  margin-bottom: 5px;
  font-size: 14px; }

.info__val {
  font-size: 20px;
  font-weight: 700; }

.graph {
  margin-bottom: 30px; }
  .graph img {
    width: 100%; }

.graph__image {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-right: 20px; }

.graph__image g:last-of-type {
  pointer-events: none; }

.graph__descr {
  display: inline-block;
  vertical-align: top;
  width: 80px; }

.graph__category {
  width: 150px;
  font-size: 16px;
  margin-bottom: 20px;
  display: none; }
  .graph__category .custom-select .Select-control {
    border: none; }
    .graph__category .custom-select .Select-control:hover {
      box-shadow: none; }
  .graph__category .custom-select .Select-placeholder,
  .graph__category .custom-select .Select--single > .Select-control .Select-value {
    color: #393535; }

@media only screen and (max-width: 991px) {
  .sort__list {
    margin-bottom: 20px; }
  .graph__category {
    display: block; }
  .graph__descr {
    display: none; } }

@media only screen and (max-width: 767px) {
  .sort {
    margin-bottom: 20px; }
  .sort__descr {
    display: inline-block;
    width: 100px;
    margin-right: 10px; }
  .sort__block {
    display: inline-block;
    vertical-align: top; }
  .sort__list {
    display: none; }
  .sort__mobile {
    display: inline-block;
    vertical-align: middle;
    width: 180px; }
  .choose__select {
    margin-top: 20px; }
  .dashboard .row .col-sm-6:nth-of-type(2) {
    padding: 0; }
  .graph {
    overflow: hidden; }
    .graph .responsive-chart {
      overflow: scroll; }
    .graph .title {
      padding-left: 15px; } }

@media only screen and (max-width: 480px) {
  .graph h2 {
    margin-bottom: 0; } }

.choose__filter {
  margin-left: 10px; }

.choose__dropdown {
  position: relative;
  font-size: 16px; }
  .choose__dropdown:before {
    content: "+";
    position: absolute;
    left: -20px;
    top: 0;
    line-height: 1;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer; }

.choose__dropdown_open:before {
  content: "-";
  font-size: 24px;
  top: -5px; }

.choose__dropdown_open .choose__list {
  display: block; }

.choose__list {
  margin-top: 12px;
  margin-left: 20px;
  font-size: 14px;
  display: none; }
  .choose__list .checkbox__label {
    font-weight: normal; }
  .choose__list .choose__item {
    margin-bottom: 12px; }

.search .form__search {
  display: inline-block;
  vertical-align: middle;
  width: 260px; }

.search .checkbox {
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px; }

.report__button {
  width: 100%;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold; }

.choose__filter_mobile {
  padding: 0 15px;
  margin-bottom: 20px;
  display: none; }
  .choose__filter_mobile .title {
    cursor: pointer; }
    .choose__filter_mobile .title:before {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -4px;
      font-size: 7px; }

.table__found-items {
  margin-left: 10px;
  margin-bottom: 15px; }

.table__found-item {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: #16325b;
  border-color: #16325b;
  color: #fff; }

@media only screen and (max-width: 1200px) {
  .table__found-items {
    float: right; } }

@media only screen and (max-width: 767px) {
  .choose__filter_mobile {
    display: block; }
  .table__found-items {
    float: none; } }

.summary__check {
  font-size: 16px; }

.summary__title {
  font-weight: bold; }

.summary__item {
  background-color: #16325b;
  color: #fff; }

.report-page .datepicker .form_datepicker {
  display: inline-block; }

.three.columns {
  display: flex; }
  .app__language_he .three.columns {
    direction: rtl; }

.column {
  flex: 1; }

.two.columns {
  display: flex; }
  .app__language_he .two.columns {
    direction: rtl; }

.column {
  flex: 1; }

strong {
  font-weight: bold; }

.managment-system {
  direction: rtl;
  padding: 15px 0 60px; }
  .managment-system .hide-div {
    display: none; }
  .managment-system .col-xs-1,
  .managment-system .col-xs-2,
  .managment-system .col-xs-3,
  .managment-system .col-xs-4,
  .managment-system .col-xs-5,
  .managment-system .col-xs-6,
  .managment-system .col-xs-7,
  .managment-system .col-xs-8,
  .managment-system .col-xs-9,
  .managment-system .col-xs-10,
  .managment-system .col-xs-11,
  .managment-system .col-xs-12 {
    float: right; }
  .managment-system aside.sidebar .sidebar-logo {
    height: 77.9px;
    text-align: center;
    border: 1px solid #e7e7e7;
    padding: 10px; }
    .managment-system aside.sidebar .sidebar-logo img.logo {
      max-height: 100%;
      width: auto; }
  .managment-system aside.sidebar nav.sidebar-navigation {
    min-height: 513.1px;
    background-color: #ebebeb; }
    .managment-system aside.sidebar nav.sidebar-navigation ul {
      height: 100%;
      width: 100%; }
      .managment-system aside.sidebar nav.sidebar-navigation ul li {
        padding: 13px 10px;
        transition: background-color 0.5s ease; }
        .managment-system aside.sidebar nav.sidebar-navigation ul li.Selected, .managment-system aside.sidebar nav.sidebar-navigation ul li:hover {
          background-color: #d8d8d8; }
        .managment-system aside.sidebar nav.sidebar-navigation ul li a {
          font-size: 14px;
          color: #0f2649;
          letter-spacing: 0; }
  .managment-system .weight-groups {
    margin: 40px -15px 0;
    border-left: 1px solid #e7e7e7;
    height: 550px;
    padding-left: 5px;
    overflow-y: auto;
    overflow-x: hidden; }
    .managment-system .weight-groups h2 {
      font-size: 16px;
      line-height: 21px;
      color: #0f2649;
      letter-spacing: 0;
      font-weight: 700;
      margin-bottom: 5px; }
      .managment-system .weight-groups h2 a {
        width: 21px;
        height: 21px;
        border-radius: 100%;
        text-align: center;
        float: left;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
        align-items: center; }
        .managment-system .weight-groups h2 a i img {
          width: 21px;
          height: 21px; }
    .managment-system .weight-groups .scalegroups__item {
      margin-bottom: 5px; }
    .managment-system .weight-groups .weight-group-list p {
      font-size: 13px;
      line-height: 15px;
      color: #7f8892; }
    .managment-system .weight-groups .weight-group-list .weight-group .weight-group-header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: flex-start;
      align-items: flex-start;
      margin-top: 10px; }
      .managment-system .weight-groups .weight-group-list .weight-group .weight-group-header h3 {
        font-size: 13px;
        color: #7f8892;
        order: 0;
        flex: 1 1 auto;
        align-self: auto; }
      .managment-system .weight-groups .weight-group-list .weight-group .weight-group-header .group-buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: flex-start;
        order: 0;
        flex: 0 1 auto;
        align-self: flex-end; }
        .managment-system .weight-groups .weight-group-list .weight-group .weight-group-header .group-buttons a {
          font-size: 10px; }
          .managment-system .weight-groups .weight-group-list .weight-group .weight-group-header .group-buttons a:first-child {
            color: #163382;
            margin-left: 5px; }
          .managment-system .weight-groups .weight-group-list .weight-group .weight-group-header .group-buttons a:last-child {
            color: #f85f58; }
  .managment-system button.sides {
    float: left;
    margin-bottom: 61px; }
    .managment-system button.sides i {
      margin-right: 5px; }
  .managment-system .sales {
    margin: 40px -15px 0;
    min-height: 513.1px;
    min-width: 200px;
    border-left: 1px solid #e7e7e7; }
    .managment-system .sales h2 {
      font-size: 16px;
      color: #0f2649;
      font-weight: 700;
      line-height: 21px;
      margin-bottom: 5px; }
    .managment-system .sales .selected {
      background-color: #ddddff; }
    .managment-system .sales .list__buttons {
      display: flex; }
    .managment-system .sales .list__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: solid 1px #555;
      border-radius: 5px;
      height: 30px;
      width: 100%;
      margin-bottom: 5px;
      padding-right: 10px;
      color: inherit;
      font-size: 16px;
      font-weight: bold; }
      .managment-system .sales .list__item:hover {
        cursor: pointer; }
      .managment-system .sales .list__item .bolet-icon-trash {
        color: orangered; }
      .managment-system .sales .list__item .bolet-icon-edit {
        margin-left: 5px;
        margin-right: 5px; }
    .managment-system .sales .item-selected {
      background-color: #ddddff; }
    .managment-system .sales .btn-new {
      position: absolute;
      left: 15px;
      top: 0px;
      margin: auto;
      cursor: pointer; }
  .managment-system .sales-items {
    margin: 40px 0 0; }
  .managment-system .sales-customer-view {
    margin: 0px -60px 0;
    min-width: 200px; }
    .managment-system .sales-customer-view .sale-add-link {
      display: block;
      margin-bottom: 10px;
      text-align: right;
      white-space: nowrap;
      font-family: Arial;
      font-weight: 700;
      font-size: 16px;
      line-height: 1;
      color: #36ba97;
      direction: rtl;
      text-decoration: none;
      cursor: pointer; }
    .managment-system .sales-customer-view h2 {
      font-size: 16px;
      color: #0f2649;
      font-weight: 700;
      line-height: 21px;
      margin-bottom: 10px; }
    .managment-system .sales-customer-view .readonly-editor-wrapper {
      height: 110px;
      direction: rtl; }
      .managment-system .sales-customer-view .readonly-editor-wrapper .editor {
        z-index: -1000;
        position: relative;
        border: solid 1px lightgray;
        height: 110px;
        width: 270px;
        font-family: inherit; }
    .managment-system .sales-customer-view .bolet-icon-trash {
      color: orangered; }
  .managment-system .sales-details {
    margin: 40px -40px 0;
    min-height: 513.1px;
    min-width: 200px; }
    .managment-system .sales-details h2 {
      font-size: 16px;
      color: #0f2649;
      font-weight: 700;
      line-height: 21px;
      margin-bottom: 10px; }
    .managment-system .sales-details .sale-add-link {
      display: block;
      margin-bottom: 10px;
      text-align: right;
      white-space: nowrap;
      font-family: Arial;
      font-weight: 700;
      font-size: 16px;
      line-height: 1;
      color: #36ba97;
      direction: rtl;
      text-decoration: none;
      cursor: pointer; }
    .managment-system .sales-details .sale-edit-link {
      display: block;
      margin-bottom: 10px;
      text-align: right;
      color: #474659;
      font-family: ArialMT;
      font-size: 16px;
      cursor: pointer; }
    .managment-system .sales-details .readonly-editor-wrapper {
      height: 110px;
      direction: rtl; }
      .managment-system .sales-details .readonly-editor-wrapper .editor {
        position: relative;
        border: solid 1px lightgray;
        height: 110px;
        width: 300px;
        font-family: inherit; }
    .managment-system .sales-details .sale-user-text {
      border: solid 1px lightgray;
      padding: 5px;
      width: 300px;
      height: 100px; }
    .managment-system .sales-details .details-image,
    .managment-system .sales-details .details-template,
    .managment-system .sales-details .details-movie {
      width: 176px;
      height: auto;
      margin-bottom: 10px; }
      .managment-system .sales-details .details-image .details-image img,
      .managment-system .sales-details .details-template .details-image img,
      .managment-system .sales-details .details-movie .details-image img {
        width: 100%;
        height: auto;
        max-height: 100%; }
    .managment-system .sales-details .duration {
      margin-top: 10px; }
      .managment-system .sales-details .duration label {
        width: 200px;
        font-size: 14px;
        color: #0f2649;
        transition: background-color 0.5s ease;
        font-weight: 400;
        margin-bottom: 5px; }
    .managment-system .sales-details .bolet-icon-trash {
      color: orangered; }
  .managment-system .details {
    margin: 40px -60px 0; }
    .managment-system .details h2 {
      font-size: 16px;
      color: #0f2649;
      font-weight: 700;
      line-height: 21px;
      margin-bottom: 10px; }
    .managment-system .details .big-image img {
      width: 390px;
      height: 252px; }
    .managment-system .details .big-image .duration {
      margin-top: 10px;
      margin-right: 15px;
      order: 0;
      flex: 1 1 auto;
      width: 55%;
      position: relative; }
      .managment-system .details .big-image .duration label {
        font-size: 14px;
        color: #0f2649;
        transition: background-color 0.5s ease;
        font-weight: 400;
        margin-bottom: 5px; }
      .managment-system .details .big-image .duration .select-duration {
        width: 150px; }
    .managment-system .details form fieldset {
      margin-top: 20px;
      margin-right: 20px;
      margin-bottom: 20px; }
      .managment-system .details form fieldset .radio-options {
        display: block; }
        .managment-system .details form fieldset .radio-options .radio-group {
          position: relative; }
          .managment-system .details form fieldset .radio-options .radio-group input + label.radio-option-label {
            font-size: 14px;
            color: #0f2649;
            font-weight: 400;
            position: relative;
            transition: background-color 0.5s ease;
            width: 100%; }
            .managment-system .details form fieldset .radio-options .radio-group input + label.radio-option-label:before {
              content: "";
              display: inline-block;
              border-radius: 100%;
              border: 1px solid #0f2649;
              width: 8px;
              height: 8px;
              margin-left: 3px; }
          .managment-system .details form fieldset .radio-options .radio-group input:checked + label.radio-option-label:after {
            content: "";
            display: inline-block;
            border-radius: 100%;
            width: 8px;
            height: 8px;
            border: 1px solid #0f2649;
            background-color: #0f2649;
            position: absolute;
            top: 5px;
            right: 0px;
            box-shadow: inset 0 0 0px 1.5px #ffffff; }
          .managment-system .details form fieldset .radio-options .radio-group .date-picker-inputs {
            margin-top: 7px;
            display: flex;
            width: 230px;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-content: flex-start;
            align-items: flex-start; }
            .managment-system .details form fieldset .radio-options .radio-group .date-picker-inputs .from-date {
              margin: 0 0 0 10%; }
            .managment-system .details form fieldset .radio-options .radio-group .date-picker-inputs .from-date,
            .managment-system .details form fieldset .radio-options .radio-group .date-picker-inputs .until-date {
              order: 0;
              flex: 1 1 auto;
              align-self: auto;
              width: 45%;
              position: relative; }
              .managment-system .details form fieldset .radio-options .radio-group .date-picker-inputs .from-date label,
              .managment-system .details form fieldset .radio-options .radio-group .date-picker-inputs .until-date label {
                font-size: 14px;
                color: #0f2649;
                transition: background-color 0.5s ease;
                font-weight: 400;
                margin-bottom: 5px; }
              .managment-system .details form fieldset .radio-options .radio-group .date-picker-inputs .from-date .react-datepicker__input-container,
              .managment-system .details form fieldset .radio-options .radio-group .date-picker-inputs .until-date .react-datepicker__input-container {
                border: 1px solid #0f2649;
                transition: background-color 0.5s ease;
                border-radius: 4px;
                height: 36px;
                line-height: 36px; }
                .managment-system .details form fieldset .radio-options .radio-group .date-picker-inputs .from-date .react-datepicker__input-container input,
                .managment-system .details form fieldset .radio-options .radio-group .date-picker-inputs .until-date .react-datepicker__input-container input {
                  background-color: transparent;
                  padding: 0 4px 0 25px;
                  font-size: 14px;
                  color: #0f2649;
                  transition: background-color 0.5s ease;
                  position: relative; }
              .managment-system .details form fieldset .radio-options .radio-group .date-picker-inputs .from-date .bolet-icon-calendar,
              .managment-system .details form fieldset .radio-options .radio-group .date-picker-inputs .until-date .bolet-icon-calendar {
                position: absolute;
                font-size: 16px;
                color: #0f2649;
                transition: background-color 0.5s ease;
                left: 6px;
                top: 30px; }
          .managment-system .details form fieldset .radio-options .radio-group .hours-picker-inputs {
            margin-top: 7px;
            display: flex; }
            .managment-system .details form fieldset .radio-options .radio-group .hours-picker-inputs .from-time,
            .managment-system .details form fieldset .radio-options .radio-group .hours-picker-inputs .until-time {
              margin-left: 28px;
              position: relative; }
              .managment-system .details form fieldset .radio-options .radio-group .hours-picker-inputs .from-time label,
              .managment-system .details form fieldset .radio-options .radio-group .hours-picker-inputs .until-time label {
                font-size: 14px;
                color: #0f2649;
                transition: background-color 0.5s ease;
                font-weight: 400;
                margin-bottom: 5px; }
            .managment-system .details form fieldset .radio-options .radio-group .hours-picker-inputs .select-time__control {
              width: 100px; }
            .managment-system .details form fieldset .radio-options .radio-group .hours-picker-inputs .select-time__menu {
              height: 95px; }
              .managment-system .details form fieldset .radio-options .radio-group .hours-picker-inputs .select-time__menu-list {
                height: 95px; }
          .managment-system .details form fieldset .radio-options .radio-group .days-picker-inputs {
            margin-top: 7px;
            display: grid;
            grid-template-columns: repeat(2, 50px);
            grid-template-rows: repaet(4, 10px);
            grid-gap: 10px; }
          .managment-system .details form fieldset .radio-options .radio-group input + label .date-picker-inputs .from-date label,
          .managment-system .details form fieldset .radio-options .radio-group input + label .date-picker-inputs .until-date label {
            color: #e7e7e7; }
          .managment-system .details form fieldset .radio-options .radio-group input + label .date-picker-inputs .from-date .react-datepicker__input-container,
          .managment-system .details form fieldset .radio-options .radio-group input + label .date-picker-inputs .until-date .react-datepicker__input-container {
            border: 1px solid #e7e7e7; }
            .managment-system .details form fieldset .radio-options .radio-group input + label .date-picker-inputs .from-date .react-datepicker__input-container input,
            .managment-system .details form fieldset .radio-options .radio-group input + label .date-picker-inputs .until-date .react-datepicker__input-container input {
              color: #e7e7e7; }
          .managment-system .details form fieldset .radio-options .radio-group input + label .date-picker-inputs .from-date .bolet-icon-calendar,
          .managment-system .details form fieldset .radio-options .radio-group input + label .date-picker-inputs .until-date .bolet-icon-calendar {
            color: #e7e7e7; }
          .managment-system .details form fieldset .radio-options .radio-group input:checked + label.radio-option-label .date-picker-inputs .from-date label,
          .managment-system .details form fieldset .radio-options .radio-group input:checked + label.radio-option-label .date-picker-inputs .until-date label {
            color: #0f2649; }
          .managment-system .details form fieldset .radio-options .radio-group input:checked + label.radio-option-label .date-picker-inputs .from-date .react-datepicker__input-container,
          .managment-system .details form fieldset .radio-options .radio-group input:checked + label.radio-option-label .date-picker-inputs .until-date .react-datepicker__input-container {
            border: 1px solid #0f2649; }
            .managment-system .details form fieldset .radio-options .radio-group input:checked + label.radio-option-label .date-picker-inputs .from-date .react-datepicker__input-container input,
            .managment-system .details form fieldset .radio-options .radio-group input:checked + label.radio-option-label .date-picker-inputs .until-date .react-datepicker__input-container input {
              color: #0f2649; }
          .managment-system .details form fieldset .radio-options .radio-group input:checked + label.radio-option-label .date-picker-inputs .from-date .bolet-icon-calendar,
          .managment-system .details form fieldset .radio-options .radio-group input:checked + label.radio-option-label .date-picker-inputs .until-date .bolet-icon-calendar {
            color: #0f2649; }
  .managment-system .images-gallery-container {
    margin-top: 40px;
    width: 250px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start; }
    .managment-system .images-gallery-container h2 {
      font-size: 16px;
      color: #0f2649;
      font-weight: 700;
      line-height: 21px;
      margin-bottom: 10px; }
    .managment-system .images-gallery-container .small-images-container {
      background-color: #d8d8d8;
      width: 100%;
      padding: 15px;
      float: left; }
      .managment-system .images-gallery-container .small-images-container .saved-detailes {
        display: none;
        padding-bottom: 8px; }
        .managment-system .images-gallery-container .small-images-container .saved-detailes.shown {
          display: block; }
        .managment-system .images-gallery-container .small-images-container .saved-detailes p {
          font-size: 16px;
          font-weight: 700;
          color: #4f4d4d;
          line-height: 18px; }
      .managment-system .images-gallery-container .small-images-container ul {
        padding: 20px 0 10px;
        direction: ltr;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: flex-start; }
        .managment-system .images-gallery-container .small-images-container ul li {
          display: inline-block;
          padding-bottom: 10px; }
          .managment-system .images-gallery-container .small-images-container ul li .img-wrapper {
            background-color: #ebebeb;
            width: 58px;
            height: 48px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-content: center;
            align-items: center;
            position: relative; }
            .managment-system .images-gallery-container .small-images-container ul li .img-wrapper a.delete-images-button {
              position: absolute;
              top: 50px;
              left: 0px; }
              .managment-system .images-gallery-container .small-images-container ul li .img-wrapper a.delete-images-button i {
                font-size: 12px;
                color: orangered; }
            .managment-system .images-gallery-container .small-images-container ul li .img-wrapper .place-holder {
              width: 45px;
              height: 29px; }
            .managment-system .images-gallery-container .small-images-container ul li .img-wrapper .real-image {
              width: 52px;
              height: 42px; }
            .managment-system .images-gallery-container .small-images-container ul li .img-wrapper .real-movie {
              width: 45px;
              height: 29px; }
          .managment-system .images-gallery-container .small-images-container ul li .time-select {
            font-size: 13px;
            color: #0f2649;
            margin-top: 5px;
            padding: 0 5px;
            direction: rtl;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-content: flex-start;
            align-items: flex-start; }
            .managment-system .images-gallery-container .small-images-container ul li .time-select.editor {
              width: 100%;
              display: block;
              position: relative;
              padding: 0;
              margin: 0; }
              .managment-system .images-gallery-container .small-images-container ul li .time-select.editor input {
                width: 100%;
                max-width: 58px;
                padding: 4px 5px 4px 20px; }
              .managment-system .images-gallery-container .small-images-container ul li .time-select.editor a {
                position: absolute;
                left: 3px;
                top: 3px; }
            .managment-system .images-gallery-container .small-images-container ul li .time-select .duration-read {
              max-width: 35px; }
              .managment-system .images-gallery-container .small-images-container ul li .time-select .duration-read:disabled {
                background-color: transparent;
                color: #0f2649; }
              .managment-system .images-gallery-container .small-images-container ul li .time-select .duration-read::placeholder {
                color: #0f2649; }
            .managment-system .images-gallery-container .small-images-container ul li .time-select i {
              color: #0f2649; }

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: hidden;
  z-index: -9999;
  top: 0;
  right: 0;
  background-color: rgba(79, 77, 77, 0.6);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: linear 0.5s ease; }
  .popup.shown-popup {
    opacity: 1;
    visibility: visible;
    z-index: 9999; }
  .popup .pop-up-wrapper {
    background-color: #ffffff;
    box-shadow: 0 2px 45px 0 rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    padding: 5% 6%;
    max-width: 487px;
    min-height: 376px;
    width: 100%;
    text-align: center; }
    .popup .pop-up-wrapper .popup-image {
      margin-bottom: 10px;
      width: 100%; }
    .popup .pop-up-wrapper .pop-up-text {
      width: 100%;
      height: 20%; }
      .popup .pop-up-wrapper .pop-up-text h2 {
        font-size: 30px;
        color: #0f2649;
        line-height: 34px;
        display: block;
        text-align: center;
        margin: 0 0 40px;
        font-weight: 400; }
      .popup .pop-up-wrapper .pop-up-text form {
        margin: 0 0 36px; }
        .popup .pop-up-wrapper .pop-up-text form label {
          text-align: right;
          display: block;
          font-size: 14px;
          font-weight: 400;
          color: #0f2649; }
        .popup .pop-up-wrapper .pop-up-text form input {
          border: 1px solid #0f2649;
          border-radius: 4px;
          width: 100%;
          height: 36px;
          margin-top: 5px;
          padding: 5px 10px; }
    .popup .pop-up-wrapper .pop-up-empty-fields {
      height: 45%; }
    .popup .pop-up-wrapper .pop-up-fields {
      text-align: right;
      height: 50%; }
      .popup .pop-up-wrapper .pop-up-fields .pop-up-error {
        font-size: 20px;
        font-weight: bold;
        color: red;
        text-align: center; }
    .popup .pop-up-wrapper .pop-up-buttons {
      width: 40%;
      height: 30%;
      display: flex;
      text-align: bottom;
      justify-content: space-evenly; }
      .popup .pop-up-wrapper .pop-up-buttons button {
        height: 35px;
        width: 99px;
        border-radius: 100px; }
    .popup .pop-up-wrapper .center-text {
      width: 100%; }

.edit-items {
  direction: rtl;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 20px;
  right: 20px;
  height: auto;
  width: auto; }
  .edit-items .header-image {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .edit-items .header-text {
    position: relative;
    top: -25px;
    text-align: center;
    font-size: 30px;
    color: #0f2649;
    letter-spacing: 0;
    line-height: 35px; }
  .edit-items .header-search {
    position: relative;
    text-align: center;
    direction: rtl;
    top: 100px; }
  .edit-items .searched {
    position: relative;
    right: 20px;
    height: 220px;
    width: 90%;
    margin: auto;
    overflow-y: auto; }
  .edit-items .sub-header-text {
    position: relative;
    top: 15px;
    right: 80px;
    text-align: right;
    font-size: 16px;
    color: #0f2649;
    letter-spacing: 0; }
  .edit-items .selected {
    position: relative;
    right: 20px;
    top: 20px;
    height: 134px;
    padding-bottom: 40px;
    width: 90%;
    margin: auto;
    overflow-y: auto; }
  .edit-items .item-label {
    float: right;
    margin: 10px; }
  .edit-items .sale_search {
    position: relative;
    top: -120px;
    width: 300px;
    height: 36px; }
  .edit-items .sale_input {
    border: 1px solid #0f2649;
    border-radius: 4px; }
  .edit-items ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: right; }
    .edit-items ul li {
      margin: 4px;
      padding: 0;
      width: 32%; }
      .edit-items ul li .item-wrapper {
        width: 300px;
        height: 36px;
        border: 1px solid #0f2649;
        border-radius: 4px; }
        .edit-items ul li .item-wrapper .checkbox {
          float: right;
          margin: 8px;
          margin-bottom: 0; }
          .edit-items ul li .item-wrapper .checkbox input {
            margin: 0; }
          .edit-items ul li .item-wrapper .checkbox input + label.days-option-label {
            font-size: 14px;
            color: #0f2649;
            font-weight: 400;
            line-height: 20px;
            vertical-align: middle; }
            .edit-items ul li .item-wrapper .checkbox input + label.days-option-label:before {
              content: "";
              border: 1px solid #0f2649;
              width: 12px;
              height: 12px;
              margin-left: 5px;
              display: inline-block;
              position: relative;
              top: 1px; }
            .edit-items ul li .item-wrapper .checkbox input + label.days-option-label:after {
              content: "";
              position: absolute;
              right: 3.5px;
              top: 5px;
              width: 5px;
              height: 8px;
              border: solid #163382;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              opacity: 0; }
          .edit-items ul li .item-wrapper .checkbox input:checked + label.days-option-label:after {
            opacity: 1; }
  .edit-items .pop-up-buttons {
    position: relative;
    top: 40px;
    display: flex;
    justify-content: space-around; }
    .edit-items .pop-up-buttons button {
      height: 40px;
      width: 200px;
      border-radius: 100px;
      margin: auto; }

.advertising-popup {
  direction: rtl; }
  .advertising-popup .pop-up-wrapper {
    width: 20%;
    height: 45%;
    padding: 3%;
    direction: rtl; }
    .advertising-popup .pop-up-wrapper .col-sm-1,
    .advertising-popup .pop-up-wrapper .col-sm-2,
    .advertising-popup .pop-up-wrapper .col-sm-3,
    .advertising-popup .pop-up-wrapper .col-sm-4,
    .advertising-popup .pop-up-wrapper .col-sm-5,
    .advertising-popup .pop-up-wrapper .col-sm-6,
    .advertising-popup .pop-up-wrapper .col-sm-7,
    .advertising-popup .pop-up-wrapper .col-sm-8,
    .advertising-popup .pop-up-wrapper .col-sm-9,
    .advertising-popup .pop-up-wrapper .col-sm-10,
    .advertising-popup .pop-up-wrapper .col-sm-11,
    .advertising-popup .pop-up-wrapper .col-sm-12 {
      float: right; }
    .advertising-popup .pop-up-wrapper .pop-up-buttons {
      width: 100%;
      padding: 3%;
      margin: auto;
      text-align: center; }
      .advertising-popup .pop-up-wrapper .pop-up-buttons button {
        height: 35px;
        width: 99px;
        border-radius: 100px; }

.user-text .pop-up-wrapper {
  max-width: 60%;
  min-height: 60%;
  max-height: 90%;
  padding: 3%;
  direction: rtl; }
  .user-text .pop-up-wrapper .col-sm-1,
  .user-text .pop-up-wrapper .col-sm-2,
  .user-text .pop-up-wrapper .col-sm-3,
  .user-text .pop-up-wrapper .col-sm-4,
  .user-text .pop-up-wrapper .col-sm-5,
  .user-text .pop-up-wrapper .col-sm-6,
  .user-text .pop-up-wrapper .col-sm-7,
  .user-text .pop-up-wrapper .col-sm-8,
  .user-text .pop-up-wrapper .col-sm-9,
  .user-text .pop-up-wrapper .col-sm-10,
  .user-text .pop-up-wrapper .col-sm-11,
  .user-text .pop-up-wrapper .col-sm-12 {
    float: right; }
  .user-text .pop-up-wrapper h2 {
    text-align: center;
    font-size: 30px;
    color: #0f2649;
    letter-spacing: 0;
    line-height: 35px; }
  .user-text .pop-up-wrapper .template-wrapper {
    width: 800px;
    height: 600px; }
  .user-text .pop-up-wrapper .editor-wrapper {
    direction: ltr; }
    .user-text .pop-up-wrapper .editor-wrapper .editor {
      border: solid 1px lightgray;
      min-height: 300px;
      font-family: inherit; }
  .user-text .pop-up-wrapper .pop-up-buttons {
    width: 300px;
    margin: auto;
    text-align: center; }
    .user-text .pop-up-wrapper .pop-up-buttons button {
      height: 35px;
      width: 99px;
      border-radius: 100px; }

.template-selection .pop-up-wrapper {
  max-width: 50%;
  min-height: 30%;
  max-height: 90%;
  padding: 3%;
  direction: rtl; }
  .template-selection .pop-up-wrapper .col-sm-1,
  .template-selection .pop-up-wrapper .col-sm-2,
  .template-selection .pop-up-wrapper .col-sm-3,
  .template-selection .pop-up-wrapper .col-sm-4,
  .template-selection .pop-up-wrapper .col-sm-5,
  .template-selection .pop-up-wrapper .col-sm-6,
  .template-selection .pop-up-wrapper .col-sm-7,
  .template-selection .pop-up-wrapper .col-sm-8,
  .template-selection .pop-up-wrapper .col-sm-9,
  .template-selection .pop-up-wrapper .col-sm-10,
  .template-selection .pop-up-wrapper .col-sm-11,
  .template-selection .pop-up-wrapper .col-sm-12 {
    float: right; }
  .template-selection .pop-up-wrapper h1 {
    text-align: center;
    font-size: 20px;
    color: #0f2649;
    letter-spacing: 0; }
  .template-selection .pop-up-wrapper h2 {
    text-align: center;
    font-size: 30px;
    color: #0f2649;
    letter-spacing: 0;
    line-height: 35px; }
  .template-selection .pop-up-wrapper h3 {
    direction: rtl;
    margin: 20px 20px 0px 20px;
    text-align: right;
    font-size: 20px;
    font-style: italic;
    color: #0f2649;
    letter-spacing: 0;
    line-height: 35px; }
  .template-selection .pop-up-wrapper .image-wrapper {
    border-radius: 4px;
    border: 1px solid #4b4b4b;
    padding: 5px;
    width: 150px;
    height: 140px;
    margin-bottom: 10px; }
    .template-selection .pop-up-wrapper .image-wrapper .image-box {
      width: 100%;
      height: auto; }
    .template-selection .pop-up-wrapper .image-wrapper .gallery-image-box {
      max-height: 100%;
      object-fit: contain;
      overflow: hidden;
      background-color: white;
      margin: 0; }
    .template-selection .pop-up-wrapper .image-wrapper .gallery-image-details {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 2px 10px; }
    .template-selection .pop-up-wrapper .image-wrapper .bolet-icon-trash {
      color: orangered;
      position: relative;
      right: 10px; }
  .template-selection .pop-up-wrapper .name-box {
    text-align: right;
    white-space: nowrap;
    font-family: Arial;
    font-size: 14px;
    color: #0d1b38;
    direction: rtl;
    margin: 20px;
    margin-bottom: 30px;
    border: 1px solid #0f2649;
    width: 250px;
    height: 35px;
    border-radius: 4px;
    padding-right: 5px;
    display: block; }
    .template-selection .pop-up-wrapper .name-box .label {
      font-family: Arial;
      font-size: 14px;
      color: #0d1b38;
      line-height: 35px;
      position: relative;
      bottom: 28px;
      left: 185px; }
  .template-selection .pop-up-wrapper .remark-box {
    position: relative;
    left: 4px;
    text-align: right;
    white-space: nowrap;
    font-family: Arial;
    font-size: 14px;
    color: #0d1b38;
    direction: rtl;
    border: 1px solid #0f2649;
    width: 250px;
    height: 100px;
    border-radius: 4px;
    padding-right: 5px; }
  .template-selection .pop-up-wrapper .sale-color-picker {
    margin: 0px 15px;
    direction: rtl;
    text-align: right;
    position: relative;
    bottom: 20px; }
    .template-selection .pop-up-wrapper .sale-color-picker span {
      position: relative;
      top: 18px;
      right: 40px; }
  .template-selection .pop-up-wrapper .pop-up-buttons {
    width: 300px;
    padding-top: 30px;
    margin: auto;
    text-align: center; }
    .template-selection .pop-up-wrapper .pop-up-buttons button {
      height: 35px;
      width: 99px;
      border-radius: 100px; }
  .template-selection .pop-up-wrapper .images-box {
    margin: 0;
    padding: 0;
    border: 0; }
  .template-selection .pop-up-wrapper .fields-box {
    margin: 0;
    padding: 0;
    border: 0;
    left: 40px;
    width: 300px; }
  .template-selection .pop-up-wrapper .sale-images {
    position: relative;
    top: 40px;
    margin: 20px;
    line-height: 40px;
    direction: rtl;
    text-align: right; }
  .template-selection .pop-up-wrapper .sale-add-link {
    color: darkblue;
    cursor: pointer; }
  .template-selection .pop-up-wrapper #c1,
  .template-selection .pop-up-wrapper #c2,
  .template-selection .pop-up-wrapper #c3 {
    display: inline-block;
    margin: 20px 30px 0 20px;
    width: 200px;
    height: 140px; }
    .template-selection .pop-up-wrapper #c1 img,
    .template-selection .pop-up-wrapper #c2 img,
    .template-selection .pop-up-wrapper #c3 img {
      height: 140px;
      width: auto;
      transform: translateY(5%); }
    .template-selection .pop-up-wrapper #c1 label,
    .template-selection .pop-up-wrapper #c2 label,
    .template-selection .pop-up-wrapper #c3 label {
      display: inline-block; }
  .template-selection .pop-up-wrapper #c1 {
    background-color: #16335a; }
  .template-selection .pop-up-wrapper #c2 {
    background-color: #3ec4a8; }
  .template-selection .pop-up-wrapper #c3 {
    background-color: #f85f58; }
  .template-selection .pop-up-wrapper #template-labels-wrapper #label {
    display: inline-block;
    margin: 0 30px;
    width: 220px; }

.template-payget .pop-up-wrapper {
  min-width: 20%;
  max-width: 45%;
  min-height: 50%;
  max-height: 90%;
  padding: 3%;
  direction: rtl; }
  .template-payget .pop-up-wrapper .col-sm-1,
  .template-payget .pop-up-wrapper .col-sm-2,
  .template-payget .pop-up-wrapper .col-sm-3,
  .template-payget .pop-up-wrapper .col-sm-4,
  .template-payget .pop-up-wrapper .col-sm-5,
  .template-payget .pop-up-wrapper .col-sm-6,
  .template-payget .pop-up-wrapper .col-sm-7,
  .template-payget .pop-up-wrapper .col-sm-8,
  .template-payget .pop-up-wrapper .col-sm-9,
  .template-payget .pop-up-wrapper .col-sm-10,
  .template-payget .pop-up-wrapper .col-sm-11,
  .template-payget .pop-up-wrapper .col-sm-12 {
    float: right; }
  .template-payget .pop-up-wrapper h2 {
    text-align: center;
    font-size: 30px;
    color: #0f2649;
    letter-spacing: 0; }
  .template-payget .pop-up-wrapper h1 {
    text-align: center;
    font-size: 20px;
    color: #0f2649;
    letter-spacing: 0;
    line-height: 40px; }
  .template-payget .pop-up-wrapper .item_search {
    position: relative;
    left: 253px;
    width: 300px;
    height: 36px; }
  .template-payget .pop-up-wrapper .item_input {
    border: 1px solid #0f2649;
    border-radius: 4px; }
  .template-payget .pop-up-wrapper .name-box {
    text-align: right;
    white-space: nowrap;
    font-family: Arial;
    font-size: 14px;
    color: #0d1b38;
    direction: rtl;
    margin: 20px;
    border: 1px solid #0f2649;
    width: 300px;
    height: 35px;
    border-radius: 4px;
    padding-right: 5px;
    display: block; }
  .template-payget .pop-up-wrapper .image-box {
    width: 290px;
    height: 206px;
    background-color: #3ec4a8; }
  .template-payget .pop-up-wrapper .remark-box {
    position: relative;
    left: 253px;
    text-align: right;
    white-space: nowrap;
    font-family: Arial;
    font-size: 14px;
    color: #0d1b38;
    direction: rtl;
    border: 1px solid #0f2649;
    width: 300px;
    height: 100px;
    border-radius: 4px;
    padding-right: 5px; }
  .template-payget .pop-up-wrapper .pop-up-buttons {
    width: 300px;
    padding-top: 30px;
    margin: auto;
    text-align: center; }
    .template-payget .pop-up-wrapper .pop-up-buttons button {
      height: 35px;
      width: 99px;
      border-radius: 100px; }

.images-gallery-uploader .pop-up-wrapper {
  max-width: 90%;
  min-height: 30%;
  max-height: 90%;
  padding: 3%;
  direction: rtl; }
  .images-gallery-uploader .pop-up-wrapper .col-sm-1,
  .images-gallery-uploader .pop-up-wrapper .col-sm-2,
  .images-gallery-uploader .pop-up-wrapper .col-sm-3,
  .images-gallery-uploader .pop-up-wrapper .col-sm-4,
  .images-gallery-uploader .pop-up-wrapper .col-sm-5,
  .images-gallery-uploader .pop-up-wrapper .col-sm-6,
  .images-gallery-uploader .pop-up-wrapper .col-sm-7,
  .images-gallery-uploader .pop-up-wrapper .col-sm-8,
  .images-gallery-uploader .pop-up-wrapper .col-sm-9,
  .images-gallery-uploader .pop-up-wrapper .col-sm-10,
  .images-gallery-uploader .pop-up-wrapper .col-sm-11,
  .images-gallery-uploader .pop-up-wrapper .col-sm-12 {
    float: right; }
  .images-gallery-uploader .pop-up-wrapper h2 {
    text-align: center;
    font-size: 30px;
    color: #0f2649;
    letter-spacing: 0;
    line-height: 55px; }
  .images-gallery-uploader .pop-up-wrapper nav.popup-navigation {
    text-align: right; }
    .images-gallery-uploader .pop-up-wrapper nav.popup-navigation ul li {
      padding-bottom: 20px; }
      .images-gallery-uploader .pop-up-wrapper nav.popup-navigation ul li a {
        font-size: 16px;
        color: #0f2649;
        letter-spacing: 0;
        line-height: 22px;
        font-weight: 400; }
      .images-gallery-uploader .pop-up-wrapper nav.popup-navigation ul li.selected a {
        font-weight: 700; }
  .images-gallery-uploader .pop-up-wrapper .images-tab {
    text-align: right; }
    .images-gallery-uploader .pop-up-wrapper .images-tab .galley-header .commands {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: stretch;
      align-items: center; }
      .images-gallery-uploader .pop-up-wrapper .images-tab .galley-header .commands ul li {
        display: inline-block;
        padding-left: 20px; }
        .images-gallery-uploader .pop-up-wrapper .images-tab .galley-header .commands ul li form {
          position: relative; }
          .images-gallery-uploader .pop-up-wrapper .images-tab .galley-header .commands ul li form label {
            font-size: 16px;
            color: #0f2649;
            letter-spacing: 0;
            line-height: 22px;
            font-weight: 700;
            background-color: #ffffff;
            cursor: pointer; }
          .images-gallery-uploader .pop-up-wrapper .images-tab .galley-header .commands ul li form input {
            position: absolute;
            opacity: 0;
            display: block;
            width: 110px;
            top: 0;
            cursor: pointer; }
        .images-gallery-uploader .pop-up-wrapper .images-tab .galley-header .commands ul li a {
          font-size: 16px;
          color: #0f2649;
          letter-spacing: 0;
          line-height: 22px;
          font-weight: 400; }
      .images-gallery-uploader .pop-up-wrapper .images-tab .galley-header .commands button {
        height: 35px;
        width: 99px; }
    .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images {
      margin-top: 30px;
      height: 60vh;
      overflow-y: auto; }
      .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: stretch;
        align-items: center; }
        .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li {
          margin: 5px;
          width: 15%; }
          .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-wrapper {
            background-color: white;
            height: 152px;
            position: relative; }
            .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-wrapper a i {
              color: #f85f58;
              font-size: 15px; }
            .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-wrapper .checkbox {
              margin: 0;
              position: absolute;
              top: 5px;
              right: 5px; }
              .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-wrapper .checkbox input {
                margin: 0; }
              .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-wrapper .checkbox input + label:before {
                content: "";
                border: 1px solid #0f2649;
                width: 12px;
                height: 12px;
                margin-left: 5px;
                display: inline-block;
                position: relative;
                top: 1px; }
              .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-wrapper .checkbox input + label:after {
                content: "";
                position: absolute;
                right: 3.5px;
                top: 3.5px;
                width: 5px;
                height: 8px;
                border: solid #163382;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                opacity: 0; }
              .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-wrapper .checkbox input:checked + label:after {
                opacity: 1; }
          .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-wrapper {
            border-radius: 4px;
            border: 1px solid #4b4b4b;
            padding: 5px;
            height: auto;
            box-sizing: border-box;
            margin-bottom: 10px;
            display: block;
            width: 100%; }
            .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-wrapper .gallery-image-details {
              display: flex;
              justify-content: space-between;
              box-sizing: border-box;
              padding: 2px 10px; }
            .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-wrapper img {
              width: 100%;
              height: 120px; }
            .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-wrapper .movie-label {
              color: black;
              text-align: center;
              width: 100%; }
          .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-details {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-content: stretch;
            align-items: center;
            margin-top: 5px; }
            .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-details p {
              font-size: 16px;
              color: #5a5a6c;
              letter-spacing: 0; }
            .images-gallery-uploader .pop-up-wrapper .images-tab .gallery-images ul li .image-details a i {
              color: #f85f58;
              font-size: 15px; }

.green-btn {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background-color: #3ec4a8;
  display: inline-block;
  border-radius: 100px;
  min-height: 35px;
  line-height: 34px;
  transition: background-color 0.5s ease; }
  .green-btn:hover {
    color: #ffffff;
    background-color: #2b806d; }

.red-btn {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background-color: #c43e54;
  display: inline-block;
  border-radius: 100px;
  min-height: 35px;
  line-height: 34px;
  transition: background-color 0.5s ease; }
  .red-btn:hover {
    color: #ffffff;
    background-color: #9f3043; }

.grey-btn {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background-color: #9b9b9b;
  display: inline-block;
  border-radius: 100px;
  min-height: 35px;
  line-height: 34px;
  transition: background-color 0.5s ease; }
  .grey-btn:hover {
    color: #ffffff;
    background-color: #4b4b4b; }

.weights-add-items {
  min-width: 134px;
  margin-top: 20px; }

.save-info-btn {
  width: 100%;
  margin-top: 69px; }

.btn-vertical-group button {
  display: block;
  /* Make the buttons appear below each other */
  margin: 10px; }

.btn-horizental-group button {
  margin: 10px; }

.popup_height {
  min-height: 0px;
  max-height: 150px; }

/*
section {
    width: 400px;
	left: 0px;
	position: relative;
	overflow: visible;
}
*/
#popup-template-select-box-section {
  z-index: 20;
  height: 1024px; }

#popup-sale-codes-box {
  position: relative;
  top: 100.8px;
  left: 8.5px;
  width: 1051.2px;
  height: 822.4px;
  z-index: 10;
  box-shadow: 0px 2px 45px 0px rgba(0, 0, 0, 0.235);
  background-color: white;
  border-radius: 10px;
  right: -8.5px;
  margin: auto; }

#blur {
  position: absolute;
  top: 0px;
  left: 0%;
  width: 101.2%;
  height: 1024px;
  opacity: 0.6;
  background-color: #4f4d4d;
  right: 0%;
  margin: auto;
  z-index: 5; }

#popup-sale-codes-header-image {
  position: absolute;
  top: 0px;
  left: 430px;
  z-index: 10; }

#popup-sale-codes-h1 {
  position: absolute;
  top: 120px;
  width: 100%;
  z-index: 20;
  text-align: center;
  color: #0d1b38;
  font-family: ArialMT;
  font-size: 30px;
  white-space: nowrap;
  direction: rtl; }

#popup-sale-codes-select-box {
  width: 300px;
  height: 36px;
  z-index: 150;
  border: 1px solid #0f2649;
  border-radius: 4px;
  margin: auto; }

#popup-sale-codes-select-text {
  position: absolute;
  top: 8.6px;
  left: -15px;
  width: 100%;
  z-index: 130;
  text-align: right;
  color: #0d1b38;
  font-family: ArialMT;
  font-size: 14px;
  white-space: nowrap;
  direction: rtl;
  right: 35px;
  margin: auto; }

#popup-sale-codes-h2 {
  position: absolute;
  top: 510px;
  right: 40px;
  z-index: 20;
  color: #0d1b38;
  font-family: ArialMT;
  font-size: 16px;
  white-space: nowrap;
  direction: rtl;
  margin: auto; }

#popup-sale-codes-image {
  position: absolute;
  top: 0.5px;
  left: -133.4px;
  width: 33.2px;
  height: 33.1px;
  z-index: 160;
  right: 133.4px;
  margin: auto; }

#popup-sale-codes-item-name {
  position: absolute;
  top: 60.6px;
  left: 22.5px;
  width: 251px;
  z-index: 490;
  text-align: right;
  color: #0d1b38;
  font-family: ArialMT;
  font-size: 14px;
  white-space: nowrap;
  direction: rtl;
  right: -72.5px;
  margin: auto; }

#popup-sale-codes-searched-item-group {
  position: absolute;
  top: 180px;
  right: 340px;
  z-index: 480;
  margin: auto; }

#popup-sale-codes-selected-item-group {
  position: absolute;
  top: 490px;
  right: 340px;
  z-index: 480;
  margin: auto; }

#popup-sale-codes-searched-item-box {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 300px;
  height: 36px;
  z-index: 500;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: 0px;
  margin: auto; }

#popup-sale-codes-searched-item-checkbox {
  position: absolute;
  top: 11px;
  left: 134.5px;
  width: 12px;
  height: 12px;
  z-index: 520;
  border: 1.5px solid #0f2649;
  background-color: white;
  right: -134.5px;
  margin: auto; }

#popup-sale-codes-searched-item-checkbox-checked {
  position: absolute;
  top: 1.5px;
  left: -1.2px;
  width: 8.6px;
  height: 6.5px;
  z-index: 1310;
  right: 1.2px;
  margin: auto; }

#popup-sale-codes-btn-text {
  position: absolute;
  top: 8.6px;
  left: 0.4px;
  width: 53px;
  z-index: 20;
  text-align: center;
  color: #fffefe;
  font-weight: 700;
  font-family: Arial;
  font-size: 14px;
  white-space: nowrap;
  direction: rtl;
  right: -0.4px;
  margin: auto; }

#popup-sale-codes-add-btn-box {
  position: absolute;
  bottom: 40px;
  left: 68.5px;
  width: 99px;
  height: 35px;
  z-index: 40;
  background: #3ec4a8;
  border-radius: 17.5px;
  right: -68.5px;
  margin: auto;
  cursor: pointer;
  outline: none;
  box-sizing: initial;
  border: none;
  transition: all 0.5s; }

#popup-sale-codes-cancel-btn-box {
  position: absolute;
  bottom: 40px;
  left: -50.5px;
  width: 99px;
  height: 35px;
  z-index: 20;
  background: #9b9b9b;
  border-radius: 17.5px;
  right: 50.5px;
  margin: auto;
  cursor: pointer;
  outline: none;
  box-sizing: initial;
  border: none;
  transition: all 0.5s; }

#sale-h1 {
  position: absolute;
  top: 171.1px;
  left: -155.5px;
  width: 62px;
  z-index: 440;
  text-align: right;
  color: #0d1b38;
  font-weight: 700;
  font-family: Arial;
  font-size: 16px;
  white-space: nowrap;
  direction: rtl;
  right: 155.5px;
  margin: auto; }

#sale-h2 {
  position: absolute;
  top: 250.4px;
  left: -151px;
  width: 53px;
  z-index: 450;
  text-align: right;
  color: #0d1b38;
  font-weight: 700;
  font-family: Arial;
  font-size: 16px;
  white-space: nowrap;
  direction: rtl;
  right: 151px;
  margin: auto; }

#sale-h3 {
  position: absolute;
  top: 388px;
  left: -144.5px;
  width: 40px;
  z-index: 460;
  text-align: right;
  color: #0d1b38;
  font-weight: 700;
  font-family: Arial;
  font-size: 16px;
  white-space: nowrap;
  direction: rtl;
  right: 144.5px;
  margin: auto; }

#sale-add-user-message-link {
  position: absolute;
  top: 203.5px;
  left: -196px;
  width: 143px;
  z-index: 470;
  text-align: right;
  white-space: nowrap;
  font-family: Arial;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: #36ba97;
  direction: rtl;
  right: 196px;
  margin: auto;
  text-decoration: none;
  cursor: pointer; }

#sale-add-customer-image-link {
  position: absolute;
  top: 282.8px;
  left: -165.5px;
  width: 82px;
  z-index: 480;
  text-align: right;
  white-space: nowrap;
  font-family: Arial;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: #36ba97;
  direction: rtl;
  right: 165.5px;
  margin: auto;
  text-decoration: none;
  cursor: pointer; }

#sale-add-customer-movie-link {
  position: absolute;
  top: 315px;
  left: -167px;
  width: 85px;
  z-index: 490;
  text-align: right;
  white-space: nowrap;
  font-family: Arial;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: #36ba97;
  direction: rtl;
  right: 167px;
  margin: auto;
  text-decoration: none;
  cursor: pointer; }

#sale-add-customer-template-link {
  position: absolute;
  top: 347px;
  left: -168px;
  width: 87px;
  z-index: 500;
  text-align: right;
  white-space: nowrap;
  font-family: Arial;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: #36ba97;
  direction: rtl;
  right: 168px;
  margin: auto;
  text-decoration: none;
  cursor: pointer; }

#sale-edit-name-link {
  position: absolute;
  top: 411.6px;
  left: -172px;
  width: 95px;
  z-index: 510;
  text-align: right;
  color: #474659;
  font-family: ArialMT;
  font-size: 16px;
  white-space: nowrap;
  text-decoration: none;
  direction: rtl;
  right: 172px;
  margin: auto;
  cursor: pointer; }

#sale-edit-scales-link {
  position: absolute;
  top: 435px;
  left: -188px;
  width: 127px;
  z-index: 520;
  text-align: right;
  color: #474659;
  font-family: ArialMT;
  font-size: 16px;
  white-space: nowrap;
  text-decoration: none;
  direction: rtl;
  right: 188px;
  margin: auto;
  cursor: pointer; }

#sale-edit-codes-link {
  position: absolute;
  top: 459px;
  left: -171px;
  width: 93px;
  z-index: 530;
  text-align: right;
  color: #474659;
  font-family: ArialMT;
  font-size: 16px;
  white-space: nowrap;
  text-decoration: none;
  direction: rtl;
  right: 171px;
  margin: auto;
  cursor: pointer; }

#sale-filled-h1 {
  position: absolute;
  top: 2.1px;
  left: 22px;
  width: 62px;
  z-index: 460;
  text-align: right;
  color: #0d1b38;
  font-weight: 700;
  font-family: Arial;
  font-size: 16px;
  white-space: nowrap;
  direction: rtl;
  right: -22px;
  margin: auto; }

#sale-filled-h2 {
  position: absolute;
  top: 108.4px;
  left: 26.5px;
  width: 53px;
  z-index: 470;
  text-align: right;
  color: #0d1b38;
  font-weight: 700;
  font-family: Arial;
  font-size: 16px;
  white-space: nowrap;
  direction: rtl;
  right: -26.5px;
  margin: auto; }

#sale-filled-h3 {
  position: absolute;
  top: 328.3px;
  left: 33px;
  width: 40px;
  z-index: 480;
  text-align: right;
  color: #0d1b38;
  font-weight: 700;
  font-family: Arial;
  font-size: 16px;
  white-space: nowrap;
  direction: rtl;
  right: -33px;
  margin: auto; }

#sale-filled-edit-name-link {
  position: absolute;
  top: 351.8px;
  left: 5.5px;
  width: 95px;
  z-index: 490;
  text-align: right;
  color: #474659;
  font-family: ArialMT;
  font-size: 16px;
  white-space: nowrap;
  text-decoration: none;
  direction: rtl;
  right: -5.5px;
  margin: auto;
  cursor: pointer; }

#sale-filled-edit-scales-link {
  position: absolute;
  top: 375.3px;
  left: -10.5px;
  width: 127px;
  z-index: 500;
  text-align: right;
  color: #474659;
  font-family: ArialMT;
  font-size: 16px;
  white-space: nowrap;
  text-decoration: none;
  direction: rtl;
  right: 10.5px;
  margin: auto;
  cursor: pointer; }

#sale-filled-edit-codes-link {
  position: absolute;
  top: 399.3px;
  left: 6.5px;
  width: 93px;
  z-index: 510;
  text-align: right;
  color: #474659;
  font-family: ArialMT;
  font-size: 16px;
  white-space: nowrap;
  text-decoration: none;
  direction: rtl;
  right: -6.5px;
  margin: auto;
  cursor: pointer; }

#sale-filled-line {
  position: absolute;
  top: -184.1px;
  left: 83.5px;
  width: 1.8px;
  height: 799.6px;
  z-index: 520;
  right: -83.5px;
  margin: auto; }

#sale-filled-group {
  position: relative;
  top: 100px;
  left: 100px;
  width: 300px;
  height: 300px; }

#sale-filled-edit-image1 {
  position: absolute;
  top: 55.3px;
  left: 40.3px;
  width: 49.6px;
  height: 22.1px;
  z-index: 1350;
  right: -40.3px;
  margin: auto; }

#sale-filled-edit-images2 {
  position: absolute;
  top: 273.1px;
  left: 40.3px;
  width: 49.6px;
  height: 22.1px;
  z-index: 1360;
  right: -40.3px;
  margin: auto; }

#popup-template-select-box {
  position: absolute;
  top: 217.5px;
  left: 8.5px;
  width: 891px;
  height: 589px;
  z-index: 20;
  background-color: white;
  box-shadow: 0px 2px 45px 0px rgba(0, 0, 0, 0.235);
  border-radius: 10px;
  right: -8.5px;
  margin: auto; }

#popup-template-select-box-border {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 890.6px;
  height: 588.6px;
  z-index: 50;
  box-shadow: 0px 2px 45px 0px rgba(0, 0, 0, 0.235);
  background-color: white;
  border-radius: 10px;
  right: 0px;
  margin: auto; }

#popup-template-select-discount-btn-text {
  position: absolute;
  top: 411.8px;
  left: -284px;
  width: 40px;
  z-index: 130;
  text-align: center;
  color: #0d1b38;
  font-family: ArialMT;
  font-size: 14px;
  white-space: nowrap;
  direction: rtl;
  right: 284px;
  margin: auto; }

#popup-template-select-price-btn-text {
  position: absolute;
  top: 411.8px;
  left: 283.6px;
  width: 70px;
  z-index: 80;
  text-align: center;
  color: #0d1b38;
  font-family: ArialMT;
  font-size: 14px;
  white-space: nowrap;
  direction: rtl;
  right: -283.6px;
  margin: auto; }

#popup-template-select-buyget-btn-text {
  position: absolute;
  top: 411.8px;
  left: 0px;
  width: 96px;
  z-index: 120;
  text-align: center;
  white-space: nowrap;
  font-family: ArialMT;
  font-size: 14px;
  line-height: 1;
  color: #0d1b38;
  direction: rtl;
  right: 0px;
  margin: auto; }

#popup-template-select-h2 {
  position: absolute;
  top: 144.3px;
  left: -0.4px;
  width: 100px;
  z-index: 70;
  text-align: center;
  color: #0d1b38;
  font-family: ArialMT;
  font-size: 14px;
  white-space: nowrap;
  direction: rtl;
  right: 0.4px;
  margin: auto; }

#popup-template-select-h1 {
  position: absolute;
  top: 106.3px;
  left: -0.4px;
  width: 157px;
  z-index: 60;
  text-align: center;
  color: #0d1b38;
  font-family: ArialMT;
  font-size: 30px;
  white-space: nowrap;
  direction: rtl;
  right: 0.4px;
  margin: auto; }

#popup-template-select-buyget-btn-box {
  position: absolute;
  top: 234.5px;
  left: -0.5px;
  width: 225px;
  height: 160px;
  z-index: 140;
  background: #3ec4a8;
  right: 0.5px;
  margin: auto;
  cursor: pointer;
  outline: none;
  box-sizing: initial;
  border: none;
  transition: all 0.5s; }

#popup-template-select-discount-btn-box {
  position: absolute;
  top: 234.5px;
  left: -284px;
  width: 226px;
  height: 160px;
  z-index: 170;
  background: #f85f58;
  right: 284px;
  margin: auto;
  cursor: pointer;
  outline: none;
  box-sizing: initial;
  border: none;
  transition: all 0.5s; }

#popup-template-select-price-btn-box {
  position: absolute;
  top: 234.5px;
  left: 284px;
  width: 226px;
  height: 160px;
  z-index: 200;
  background: #16335a;
  right: -284px;
  margin: auto;
  cursor: pointer;
  outline: none;
  box-sizing: initial;
  border: none;
  transition: all 0.5s; }

#popup-template-select-price-btn-image {
  position: absolute;
  top: -12.2px;
  left: -0.9px;
  width: 183.8px;
  height: 183.8px;
  z-index: 220;
  right: 0.9px;
  margin: auto; }

#popup-template-select-discount-btn-image {
  position: absolute;
  top: -12.9px;
  left: -0.5px;
  width: 183.8px;
  height: 185.7px;
  z-index: 190;
  right: 0.5px;
  margin: auto; }

#popup-template-select-buyget-btn-image {
  position: absolute;
  top: 11.4px;
  left: -0.6px;
  width: 113.6px;
  height: 136.8px;
  z-index: 160;
  right: 0.6px;
  margin: auto; }

#popup-template-buy-box {
  position: absolute;
  top: 217.5px;
  left: 8.5px;
  width: 890.6px;
  height: 588.6px;
  z-index: 20;
  box-shadow: 0px 2px 45px 0px rgba(0, 0, 0, 0.235);
  background-color: white;
  border-radius: 10px;
  right: -8.5px;
  margin: auto; }

#popup-template-buy-box-section {
  z-index: 20;
  height: 1024px; }

#popup-template-buy-h1 {
  position: absolute;
  top: 61.7px;
  left: -0.4px;
  width: 79px;
  z-index: 70;
  text-align: center;
  color: #0d1b38;
  font-family: ArialMT;
  font-size: 14px;
  white-space: nowrap;
  direction: rtl;
  right: 0.4px;
  margin: auto; }

#popup-template-buy-btn-group {
  position: absolute;
  top: 523px;
  left: 0.5px;
  width: 218px;
  height: 35px;
  z-index: 280;
  right: -0.5px;
  margin: auto; }

#popup-template-buy-h2 {
  position: absolute;
  top: 23.1px;
  left: -0.4px;
  width: 287px;
  z-index: 60;
  text-align: center;
  white-space: nowrap;
  font-family: ArialMT;
  font-size: 30px;
  line-height: 1;
  color: #0d1b38;
  direction: rtl;
  right: 0.4px;
  margin: auto; }

#popup-template-buy-image-box {
  position: absolute;
  top: 99.5px;
  left: 163.8px;
  width: 290px;
  height: 206px;
  z-index: 30;
  background-color: #3ec4a8;
  right: -163.8px;
  margin: auto; }

#popup-template-buy-fields-group {
  position: absolute;
  top: 100.39px;
  left: -158.2px;
  width: 302px;
  height: 385.79px;
  z-index: 80;
  right: 158.2px;
  margin: auto; }

#popup-template-buy-product-name-group {
  position: absolute;
  top: 56px;
  left: -1px;
  width: 300px;
  height: 37px;
  z-index: 90;
  right: 1px;
  margin: auto; }

#popup-template-buy-product-code-group {
  position: absolute;
  top: 113.7px;
  left: -1px;
  width: 300px;
  height: 37px;
  z-index: 120;
  right: 1px;
  margin: auto; }

#popup-template-buy-product-buy-group {
  position: absolute;
  top: 170.7px;
  left: -1px;
  width: 300px;
  height: 37px;
  z-index: 150;
  right: 1px;
  margin: auto; }

#popup-template-buy-product-get-group {
  position: absolute;
  top: 227.7px;
  left: -1px;
  width: 300px;
  height: 37px;
  z-index: 180;
  right: 1px;
  margin: auto; }

#popup-template-buy-remark-group {
  position: absolute;
  top: 284.8px;
  left: -1px;
  width: 300px;
  height: 101px;
  z-index: 210;
  right: 1px;
  margin: auto; }

#popup-template-buy-search-group {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 302px;
  height: 38px;
  z-index: 240;
  right: 0px;
  margin: auto; }

#popup-template-buy-search-box {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 300px;
  height: 36px;
  z-index: 260;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: 0px;
  margin: auto; }

#popup-template-buy-search-image {
  position: absolute;
  top: 0.5px;
  left: -133.4px;
  width: 33.2px;
  height: 33.1px;
  z-index: 270;
  right: 133.4px;
  margin: auto; }

#popup-template-buy-product-remark-box {
  position: absolute;
  top: 0.3px;
  left: 0px;
  width: 290px;
  height: 99.9px;
  z-index: 230;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: 0px;
  margin: auto;
  outline: none;
  background: none;
  font-size: 14px;
  font-family: ArialMT;
  color: #0d1b38;
  padding-left: 10px; }

#popup-template-buy-product-get-box {
  position: absolute;
  top: 0.3px;
  left: 0px;
  width: 290px;
  height: 36px;
  z-index: 200;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: 0px;
  margin: auto;
  outline: none;
  background: none;
  font-size: 14px;
  font-family: ArialMT;
  color: #0d1b38;
  padding-left: 10px; }

#popup-template-buy-product-buy-box {
  position: absolute;
  top: 0.3px;
  left: 0px;
  width: 290px;
  height: 36px;
  z-index: 170;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: 0px;
  margin: auto;
  outline: none;
  background: none;
  font-size: 14px;
  font-family: ArialMT;
  color: #0d1b38;
  padding-left: 10px; }

#popup-template-buy-product-code-box {
  position: absolute;
  top: 0.3px;
  left: 0px;
  width: 290px;
  height: 36px;
  z-index: 140;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: 0px;
  margin: auto;
  outline: none;
  background: none;
  font-size: 14px;
  font-family: ArialMT;
  color: #0d1b38;
  padding-left: 10px; }

#popup-template-buy-text {
  position: absolute;
  top: 9px;
  left: 113px;
  width: 48px;
  z-index: 100;
  text-align: right;
  white-space: nowrap;
  font-family: ArialMT;
  font-size: 14px;
  line-height: 1;
  color: #0d1b38;
  direction: rtl;
  right: -113px;
  margin: auto; }

#popup-template-buy-product-name-box {
  position: absolute;
  top: 0.3px;
  left: 0px;
  width: 290px;
  height: 36px;
  z-index: 110;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: 0px;
  margin: auto;
  outline: none;
  background: none;
  font-size: 14px;
  font-family: ArialMT;
  color: #0d1b38;
  padding-left: 10px; }

#popup-template-buy-image-group {
  position: absolute;
  top: 14.6px;
  left: -0.9px;
  width: 146.2px;
  height: 176.1px;
  z-index: 50;
  right: 0.9px;
  margin: auto; }

#popup-template-buy-add-btn-box {
  position: absolute;
  top: 0px;
  left: 59.5px;
  width: 99px;
  height: 35px;
  z-index: 290;
  background: #3ec4a8;
  border-radius: 17.5px;
  right: -59.5px;
  margin: auto;
  cursor: pointer;
  outline: none;
  box-sizing: initial;
  border: none;
  transition: all 0.5s; }

#popup-template-buy-cancel-btn-box {
  position: absolute;
  top: 0px;
  left: -59.5px;
  width: 99px;
  height: 35px;
  z-index: 330;
  background: #9b9b9b;
  border-radius: 17.5px;
  right: 59.5px;
  margin: auto;
  cursor: pointer;
  outline: none;
  box-sizing: initial;
  border: none;
  transition: all 0.5s; }

#popup-template-buy-btn-text {
  position: absolute;
  top: 8.6px;
  left: 0.4px;
  width: 53px;
  z-index: 310;
  text-align: center;
  color: #fffefe;
  font-weight: 700;
  font-family: Arial;
  font-size: 14px;
  white-space: nowrap;
  direction: rtl;
  right: -0.4px;
  margin: auto; }

#popup-template-discount-box {
  position: absolute;
  top: 217.5px;
  left: 8.5px;
  width: 890.6px;
  height: 588.6px;
  z-index: 20;
  box-shadow: 0px 2px 45px 0px rgba(0, 0, 0, 0.235);
  background-color: white;
  border-radius: 10px;
  right: -8.5px;
  margin: auto; }

#popup-template-discount-box-section {
  z-index: 20;
  height: 1024px; }

#popup-template-discount-h1 {
  position: absolute;
  top: 61.7px;
  left: -0.4px;
  width: 79px;
  z-index: 40;
  text-align: center;
  color: #0d1b38;
  font-family: ArialMT;
  font-size: 14px;
  white-space: nowrap;
  direction: rtl;
  right: 0.4px;
  margin: auto; }

#popup-template-discount-h2 {
  position: absolute;
  top: 23.1px;
  left: -0.4px;
  width: 202px;
  z-index: 30;
  text-align: center;
  color: #0d1b38;
  font-family: ArialMT;
  font-size: 30px;
  white-space: nowrap;
  direction: rtl;
  right: 0.4px;
  margin: auto; }

#popup-template-discount-btn-group {
  position: absolute;
  top: 523px;
  left: 0.5px;
  width: 218px;
  height: 35px;
  z-index: 250;
  right: -0.5px;
  margin: auto; }

#popup-template-discount-image-box {
  position: absolute;
  top: 100.5px;
  left: 164.2px;
  width: 291px;
  height: 207px;
  z-index: 330;
  background-color: #f85f58;
  right: -164.2px;
  margin: auto; }

#popup-template-discount-fields-group {
  position: absolute;
  top: 100.39px;
  left: -158.7px;
  width: 303px;
  height: 329.1px;
  z-index: 50;
  right: 158.7px;
  margin: auto; }

#popup-template-discount-product-name-group {
  position: absolute;
  top: 56px;
  left: -0.5px;
  width: 300px;
  height: 37px;
  z-index: 60;
  right: 0.5px;
  margin: auto; }

#popup-template-discount-product-code-group {
  position: absolute;
  top: 113.7px;
  left: -0.5px;
  width: 300px;
  height: 37px;
  z-index: 90;
  right: 0.5px;
  margin: auto; }

#popup-template-discount-discount-group {
  position: absolute;
  top: 170.7px;
  left: -1px;
  width: 301px;
  height: 37px;
  z-index: 120;
  right: 1px;
  margin: auto; }

#popup-template-discount-remark-group {
  position: absolute;
  top: 228.1px;
  left: -0.5px;
  width: 300px;
  height: 101px;
  z-index: 180;
  right: 0.5px;
  margin: auto; }

#popup-template-discount-search-group {
  position: absolute;
  top: 0px;
  left: 0.5px;
  width: 302px;
  height: 38px;
  z-index: 210;
  right: -0.5px;
  margin: auto; }

#popup-template-discount-product-remark-box {
  position: absolute;
  top: 0.3px;
  left: 0px;
  width: 300px;
  height: 99.9px;
  z-index: 200;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: 0px;
  margin: auto; }

#popup-template-discount-product-discount-box {
  position: absolute;
  top: 0.3px;
  left: 56.4px;
  width: 190.2px;
  height: 36px;
  z-index: 140;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: -56.4px;
  margin: auto; }

#group {
  position: absolute;
  top: 0px;
  left: -99.5px;
  width: 102px;
  height: 37px;
  z-index: 150;
  right: 99.5px;
  margin: auto;
  pointer-events: none; }

#popup-template-discount-currency {
  position: absolute;
  top: 9px;
  left: 31px;
  width: 12px;
  z-index: 160;
  text-align: right;
  color: #0d1b38;
  font-family: ArialMT;
  font-size: 14px;
  white-space: nowrap;
  direction: ltr;
  right: -31px;
  margin: auto;
  pointer-events: none; }

#popup-template-discount-currency-box {
  position: absolute;
  top: 0.3px;
  left: 0.1px;
  width: 101.5px;
  height: 36px;
  z-index: 170;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: -0.1px;
  margin: auto;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
  background: none;
  font-size: 14px;
  font-family: ArialMT;
  color: #0d1b38;
  padding-left: 10px; }

#popup-template-discount-dropdown-image {
  position: absolute;
  top: 8.4px;
  left: -35.75px;
  width: 11px;
  height: 21.9px;
  z-index: 360;
  right: 35.75px;
  margin: auto;
  pointer-events: none; }

#popup-template-discount-product-code-box {
  position: absolute;
  top: 0.3px;
  left: 0px;
  width: 300px;
  height: 36px;
  z-index: 110;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: 0px;
  margin: auto; }

#popup-template-discount-text {
  position: absolute;
  top: 9px;
  left: 113px;
  width: 48px;
  z-index: 70;
  text-align: right;
  white-space: nowrap;
  font-family: ArialMT;
  font-size: 14px;
  line-height: 1;
  color: #0d1b38;
  direction: rtl;
  right: -113px;
  margin: auto; }

#popup-template-discount-product-name-box {
  position: absolute;
  top: 0.3px;
  left: 0px;
  width: 300px;
  height: 36px;
  z-index: 80;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: 0px;
  margin: auto; }

#popup-template-discount-image-group {
  position: absolute;
  top: 2.1px;
  left: -6.3px;
  width: 215.1px;
  height: 215.1px;
  z-index: 350;
  right: 6.3px;
  margin: auto; }

#popup-template-discount-add-btn-box {
  position: absolute;
  top: 0px;
  left: 59.5px;
  width: 99px;
  height: 35px;
  z-index: 260;
  background-color: #3ec4a8;
  border-radius: 17.5px;
  right: -59.5px;
  margin: auto; }

#popup-template-discount-cancel-btn-box {
  position: absolute;
  top: 0px;
  left: -59.5px;
  width: 99px;
  height: 35px;
  z-index: 300;
  background-color: #9b9b9b;
  border-radius: 17.5px;
  right: 59.5px;
  margin: auto; }

#popup-template-discount-btn-text {
  position: absolute;
  top: 8.6px;
  left: 0.4px;
  width: 53px;
  z-index: 280;
  text-align: center;
  color: #fffefe;
  font-weight: 700;
  font-family: Arial;
  font-size: 14px;
  white-space: nowrap;
  direction: rtl;
  right: -0.4px;
  margin: auto; }

#popup-template-discount-search-box {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 300px;
  height: 36px;
  z-index: 230;
  border: 1px solid #0f2649;
  border-radius: 4px;
  right: 0px;
  margin: auto; }

#popup-template-discount-search-image {
  position: absolute;
  top: 0.5px;
  left: -133.4px;
  width: 33.2px;
  height: 33.1px;
  z-index: 240;
  right: 133.4px;
  margin: auto; }

#sale-list-header {
  position: absolute;
  top: 173.6px;
  left: 338px;
  width: 51px;
  z-index: 320;
  text-align: right;
  color: #0d1b38;
  font-weight: 700;
  font-family: Arial;
  font-size: 16px;
  white-space: nowrap;
  direction: rtl;
  right: -338px;
  margin: auto; }

#sale-list-item-selected {
  position: absolute;
  top: 200.1px;
  left: 318px;
  width: 91px;
  z-index: 360;
  text-align: right;
  color: #0d1b38;
  font-weight: 700;
  font-family: Arial;
  font-size: 13px;
  white-space: nowrap;
  direction: rtl;
  right: -318px;
  margin: auto; }

fieldset, img {
  border: 0; }

#sale-list-edit {
  position: absolute;
  top: 182.1px;
  left: 216.7px;
  width: 46.6px;
  height: 98.1px;
  z-index: 370;
  right: -216.7px;
  margin: auto; }

#sale-list-item {
  position: absolute;
  top: 221.6px;
  left: 214.8px;
  width: 150px;
  z-index: 380;
  text-align: right;
  color: #6b757f;
  font-family: ArialMT;
  font-size: 13px;
  white-space: nowrap;
  direction: rtl;
  right: -360px;
  margin: auto; }
  #sale-list-item .bolet-icon-trash {
    position: absolute;
    left: -10px; }
  #sale-list-item .bolet-icon-edit {
    position: absolute;
    left: 10px; }

#sale-list-btn-new {
  position: absolute;
  top: 279.6px;
  left: 296.5px;
  width: 133.9px;
  height: 35px;
  z-index: 400;
  background: #3ec4a8;
  border-radius: 17.5px;
  right: -296.5px;
  margin: auto;
  cursor: pointer;
  outline: none;
  box-sizing: initial;
  border: none;
  transition: all 0.5s; }

#sale-list-btn-image-new {
  position: absolute;
  top: 163.48px;
  left: 214.8px;
  width: 36.8px;
  height: 36.8px;
  z-index: 350;
  right: -214.8px;
  margin: auto;
  cursor: pointer;
  outline: none;
  box-sizing: initial;
  border: none;
  transition: all 0.5s; }

#sale-list-btn-text {
  position: absolute;
  top: 8.6px;
  left: 0.7px;
  width: 100px;
  z-index: 420;
  text-align: center;
  color: #fffefe;
  font-weight: 700;
  font-family: Arial;
  font-size: 14px;
  white-space: nowrap;
  direction: rtl;
  right: -0.7px;
  margin: auto; }

.presets__container {
  direction: rtl;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: auto;
  grid-template-areas: "groups options" "groups info" "items view"; }

.presets__options {
  direction: ltr;
  grid-area: options;
  border: solid 1px #555;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }
  .presets__options img {
    margin: 5px 30px;
    width: 50px;
    height: 50px; }

.presets__info {
  grid-area: info;
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0 10px; }

.presets__view {
  grid-area: view;
  margin-bottom: 20px;
  min-width: 400px;
  min-height: 570px;
  background-color: #555;
  border-radius: 11px;
  direction: ltr;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-gap: 10px;
  padding: 10px; }

.presets__groups {
  grid-area: groups;
  width: 90%; }
  .presets__groups-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px; }

.presets__items {
  grid-area: items;
  height: 500px;
  overflow-y: auto; }
  .presets__items-container {
    width: 97%; }
    .presets__items-container .form__input-wrap {
      width: 90%;
      margin-bottom: 20px; }
      .presets__items-container .form__input-wrap .sale_input {
        padding-right: 20px; }
  .presets__items-item {
    display: flex;
    align-items: center;
    border: solid 1px #555;
    border-radius: 11px;
    height: 30px;
    width: 90%;
    margin-bottom: 5px;
    padding-right: 10px; }

.cell-enabled {
  color: #806E52;
  border-radius: 15px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #888));
  display: flex;
  flex-direction: column;
  position: relative; }
  .cell-enabled:hover {
    cursor: pointer; }
  .cell-enabled--selected {
    border: 2px solid red; }

.list-image {
  align-self: flex-end;
  right: 5px;
  top: 5px;
  position: relative; }

.list-name {
  color: #333;
  font: 16pt "Arial";
  font-weight: bold;
  text-align: center; }

.product-name {
  flex: 1;
  color: #333;
  font: 12pt "Arial";
  font-weight: bold;
  display: flex;
  text-align: center;
  align-self: center; }

.product-details {
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 10px; }

.product-price {
  color: #2B33A2;
  font: 10pt "Arial";
  font-weight: bold;
  text-align: right;
  margin-right: 10px; }

.preset-num {
  position: relative;
  color: #000;
  font: 10pt "Arial";
  float: left;
  left: 3px;
  top: 5px; }

.product-code {
  position: relative;
  color: #AE3800;
  font: 10pt "Arial";
  font-weight: bold;
  float: right;
  right: 3px;
  top: 5px; }

.table-drag-select td.cell-enabled {
  color: #806E52;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #888)); }

.table-drag-select td.cell-selected {
  border: 2px solid red; }

/* Cells that are in the process of being selected */
.table-drag-select td.cell-being-selected {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, black)); }

.table-drag-select {
  border-collapse: separate;
  border-spacing: 0.75em; }

/* Cells rows */
.table-drag-select tr td {
  padding-bottom: 1em; }

/* Cells columns */
.table-drag-select td {
  border: solid 2px #555;
  border-radius: 11px; }

/* WILL DETERMINE STYLE WHEN DRAGGING OVER */
.md-dragging {
  border: 1px dotted; }

/* WILL DETERMINE STYLE WHEN ACTIVE IN DRAGGING LIST */
.md-active {
  background: #DDDDFF; }

.buttons-wrapper {
  width: 78%;
  border: solid 1px #555;
  border-radius: 11px;
  text-align: center; }
  .buttons-wrapper img {
    margin: 5px 30px;
    width: 50px;
    height: 50px; }

.color-picker {
  position: absolute;
  z-index: 100;
  left: 0px;
  top: 50px; }

.page-number {
  position: relative;
  top: 25px;
  left: 20px; }

.presets-number {
  position: relative;
  top: 25px;
  left: 120px; }

.data-wrapper {
  display: flex; }

.department {
  direction: rtl;
  position: absolute;
  top: 0px;
  right: 10px;
  width: 250px;
  text-align: right; }
  .department #name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px; }
  .department #selection {
    width: 150px;
    font-size: 16px;
    margin-bottom: 10px;
    height: 40px;
    line-height: 30px; }
  .department #update {
    font-size: 16px;
    margin-bottom: 10px; }

.details-wrapper {
  flex: 1.3;
  margin-left: 20px; }
  .details-wrapper .departments-wrapper {
    margin-top: 0px;
    text-align: right;
    font-size: 20px;
    font-weight: bold; }
  .details-wrapper .items-wrapper {
    margin-top: 30px; }
    .details-wrapper .items-wrapper .items {
      text-align: right;
      overflow-y: scroll;
      direction: rtl;
      max-height: 730px; }
    .details-wrapper .items-wrapper .item {
      height: 30px;
      margin-left: 5px;
      margin-bottom: 5px;
      border: solid 1px #555;
      border-radius: 5px; }
      .details-wrapper .items-wrapper .item span {
        margin-right: 5px;
        vertical-align: middle; }

.presets-wrapper {
  flex: 5;
  margin-top: 30px;
  margin-bottom: 20px;
  min-width: 400px;
  min-height: 513.1px;
  background-color: #555;
  border-radius: 11px; }

.react-contextmenu {
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 16px;
  color: #373a3c;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  outline: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease !important; }

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto; }

.react-contextmenu-item {
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer; }

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none; }

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  color: #878a8c;
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15); }

.react-contextmenu-item--divider {
  margin-bottom: 3px;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit; }

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15); }

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0; }

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px; }

.example-multiple-targets::after {
  content: attr(data-count);
  display: block; }

.presets__buttons_group {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0; }

.parameters__container {
  margin-top: 20px;
  height: 468px;
  border-radius: 5px;
  overflow: hidden;
  direction: rtl;
  display: grid;
  grid-template-columns: 180px 0.5fr;
  grid-template-rows: 1; }

.parameters__right-pane {
  grid-column-start: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #3f3f3f;
  font-size: 16px;
  font-weight: bold; }

.parameters__name {
  color: #707070;
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 10px; }
  .parameters__name--selected {
    background-color: #5e7188;
    color: white; }
  .parameters__name:hover {
    color: white;
    cursor: pointer; }

.parameters__fields {
  grid-template-columns: repeat(3, 1fr);
  background-color: #e7e5e5;
  padding: 10px;
  height: 468px;
  overflow: auto; }

.parameters__field {
  padding-bottom: 10px; }
  .parameters__field--small {
    width: 200px; }
  .parameters__field--large {
    width: 300px; }
  .parameters__field--row {
    padding-bottom: 10px;
    display: flex;
    align-items: center; }
  .parameters__field-text__wrapper {
    border: 1px solid black;
    justify-content: space-between; }
  .parameters__field-icon {
    margin-right: 5px;
    display: flex;
    align-items: center;
    fill: orangered; }
    .parameters__field-icon:hover {
      fill: red; }

.indent {
  padding-right: 20px; }

.react-switch {
  vertical-align: middle;
  margin-left: 7px;
  direction: ltr; }

.scalegroups__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #555;
  border-radius: 5px;
  height: 30px;
  width: 90%;
  padding: 10px;
  color: inherit;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px; }
  .scalegroups__item--selected {
    background-color: #ddddff; }
  .scalegroups__item:hover {
    cursor: pointer; }
  .scalegroups__item-icon {
    display: flex;
    align-items: center;
    fill: orangered; }
    .scalegroups__item-icon:hover {
      fill: red; }

.param-tooltip-wrapper {
  display: flex; }

/* Tooltip container */
.param-tooltip {
  position: relative; }

.param-tooltip .tooltiptext {
  border: 2px solid white;
  visibility: hidden;
  width: 240px;
  min-height: 50px;
  left: -250px;
  top: -10px;
  background-color: #245973;
  color: white;
  border-radius: 4px;
  font-size: 12pt;
  text-align: right;
  position: absolute;
  padding: 10px;
  z-index: 1; }

.param-tooltip:hover .tooltiptext {
  visibility: visible; }

.param-tooltip .tooltiptext:before,
.param-tooltip .tooltiptext:after {
  content: " ";
  position: absolute;
  top: 8px;
  left: 100%;
  border-style: solid; }

.param-tooltip .tooltiptext:after {
  margin-top: -2px;
  border-width: 8px;
  border-color: transparent transparent transparent #245973; }

.param-tooltip .tooltiptext:before {
  border-color: rgba(194, 225, 245, 0);
  border-left-color: white;
  border-width: 11px;
  margin-top: -5px; }

.presetscales_container {
  display: flex;
  margin-bottom: 5px; }

.presetscales_selectors {
  display: flex;
  flex: 1; }

.sort_presetScales {
  direction: ltr;
  flex: 1;
  padding-bottom: 5px;
  padding-top: 16px;
  align-self: flex-end; }

.reacttable_select_align {
  line-height: 16px; }

.ReactTable div.rt-td {
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  /* CAUSE PROBLEM WITH EDITING CELL    
    display: flex;
    align-items: center;
    justify-content: center;
*/ }

.departments-grid {
  direction: rtl;
  background-color: red; }

.align-center {
  color: red; }

.header-wrapper .plus-icon {
  position: relative;
  right: 20px; }

.groups-wrapper {
  margin-top: 0px;
  text-align: right;
  font-size: 16px;
  font-weight: bold;
  overflow-y: scroll;
  max-height: 550px; }
  .groups-wrapper:hover {
    overflow-y: scroll; }
  .groups-wrapper .items-wrapper {
    margin-top: 30px; }
    .groups-wrapper .items-wrapper .items-header {
      color: blue; }
    .groups-wrapper .items-wrapper .items {
      text-align: right;
      overflow-y: scroll;
      direction: rtl;
      max-height: 730px; }
    .groups-wrapper .items-wrapper .item {
      display: flex;
      align-items: center;
      height: 30px;
      margin-left: 5px;
      margin-bottom: 5px;
      border: solid 1px #555;
      border-radius: 5px;
      justify-content: space-between; }
      .groups-wrapper .items-wrapper .item span {
        margin-right: 5px;
        vertical-align: middle; }
      .groups-wrapper .items-wrapper .item .bolet-icon-trash {
        color: orangered;
        position: relative;
        left: 5px; }
      .groups-wrapper .items-wrapper .item:hover {
        cursor: pointer; }
    .groups-wrapper .items-wrapper .item-selected {
      display: flex;
      align-items: center;
      height: 30px;
      margin-left: 5px;
      margin-bottom: 5px;
      border: solid 1px #555;
      border-radius: 5px;
      background-color: #ddddff;
      justify-content: space-between; }
      .groups-wrapper .items-wrapper .item-selected span {
        margin-right: 5px;
        vertical-align: middle; }
      .groups-wrapper .items-wrapper .item-selected .bolet-icon-trash {
        color: orangered;
        position: relative;
        left: 5px; }
      .groups-wrapper .items-wrapper .item-selected:hover {
        cursor: pointer; }

.ReactTable {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px; }

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 -3px 0 0 rgba(255, 255, 0, 0.6); }

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(255, 255, 0, 0.6); }

.ReactTable .rt-expander:after {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg); }

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0); }

.ReactTable .rt-th {
  font-size: 16px;
  color: #fff;
  background-color: #16325b;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.ReactTable .rt-th:first-child {
  border-top-right-radius: 20px;
  border: none;
  overflow: hidden; }

.ReactTable .rt-th:last-child {
  border-top-left-radius: 20px;
  overflow: hidden; }

.ReactTable .rt-td {
  overflow: visible; }

.react-select__menu {
  height: 85px; }
  .react-select__menu-list {
    height: 85px; }

.group__container {
  display: flex;
  flex-direction: column; }

.group__buttons {
  display: flex;
  margin-bottom: 25px; }

.group__details {
  display: flex;
  flex-direction: column; }

.group__row {
  display: flex;
  margin-bottom: 10px; }
  .group__row-item {
    display: flex;
    flex-direction: column; }
    .group__row-item:not(:first-child) {
      margin-right: 10px; }

.group__item--small {
  flex: 1; }

.group__item--large {
  flex: 3; }

.container {
  margin-right: auto;
  margin-left: auto;
  min-width: 1600px; }
