.choose__filter {
  margin-left: 10px;
}

.choose__dropdown {
  position: relative;
  font-size: 16px;
  &:before {
    content: "+";
    position: absolute;
    left: -20px;
    top: 0;
    line-height: 1;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
  }
}

.choose__dropdown_open {
  &:before {
    content: "-";
    font-size: 24px;
    top: -5px;
  }
  .choose__list {
    display: block;
  }
}

.choose__list {
  margin-top: 12px;
  margin-left: 20px;
  font-size: 14px;
  display: none;
  .checkbox__label {
    font-weight: normal;
  }
  .choose__item {
    margin-bottom: 12px;
  }
}

.search {
  .form__search {
    display: inline-block;
    vertical-align: middle;
    width: 260px;
  }
  .checkbox {
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px;
  }
}

.report__button {
  width: 100%;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.choose__filter_mobile {
  padding: 0 15px;
  margin-bottom: 20px;
  display: none;
  .title{
    cursor: pointer;
    &:before{
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -4px;
      font-size: 7px;
    }
  }
}

.table__found-items {
  margin-left: 10px;
  margin-bottom: 15px;
}

.table__found-item {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: #16325b;
  border-color: #16325b;
  color: #fff;
}

@media only screen and (max-width: 1200px) {
  .table__found-items {
    float: right;
  }
}

@media only screen and (max-width: 767px) {
  .choose__filter_mobile {
    display: block;
  }
  .table__found-items {
    float: none;
  }
}
