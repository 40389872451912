.search_container {
   background-color: lightgray;
   direction: rtl;
   width: 100%;
   height: 230px;
}

.search_header {
   font-size: 12;
   font-weight: bold;
   margin-bottom: 5px;
   display: flex;
   justify-content: space-around;
   align-items: center;
}

.search_text {}

.search_input {
   text-align: center;
   direction: rtl;
   display: flex;
   width: 98%;
}

.search_table {
   direction: ltr;
   text-align: right;
   width: 97%;
}

.search_button {
   margin: 5px;
   height: 25px;
   width: 25px;
   // path {
   //    fill: red;
   // }
}