.rc-tree-title {
  color: #333;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}

.rc-tree li {
  margin-bottom: 12px;
}

.rc-tree-child-tree {
  li {
    margin-bottom: 12px;
    span.rc-tree-switcher {
      display: none;
    }
  }
  .rc-tree-title {
    font-weight: 400;
    font-size: 14px;
  }
}

.rc-tree li .rc-tree-child-tree {
  margin-top: 12px;
  margin-left: 20px;
}

.rc-tree li span.rc-tree-checkbox {
  width: 17px;
  height: 17px;
  line-height: 15px;
  margin-right: 10px;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  background-image: none;
  position: relative;
  &.rc-tree-checkbox-checked {
    &:before {
      content: "\E808";
      font-family: font-icon;
      transform: rotate(-90deg);
      position: absolute;
      top: 0;
      left: 5px;
    }
  }
}

.rc-tree li span.rc-tree-switcher {
  width: 17px;
  height: 17px;
  line-height: 15px;
  position: relative;
  background: none;
  &:before {
    content: "+";
    position: absolute;
    left: 0;
    top: 0;
    line-height: 1;
    font-size: 17px;
    font-weight: 700;
    cursor: pointer;
  }
  &.rc-tree-noline_open {
    &:before {
      content: "-";
    }
  }
}

.tree__childs_opened,
.tree__childs_closed {
  display: inline-block;
  vertical-align: top;
  width: 17px;
  height: 17px;
  line-height: 17px;
  font-size: 0;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.tree__childs_opened {
  &:before {
    content: "-";
    font-size: 18px;
  }
}

.tree__childs_closed {
  &:before {
    content: "+";
    font-size: 18px;
  }
}


.tree__parent {
  .checkbox {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
  .checkbox__label {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
  }
}

.tree__child {
  .checkbox__label {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
  }
  .checkbox {
    margin-bottom: 12px;
  }
}