.custom-select {
  .Select-control {
    position: relative;
    height: 39px;
    border-radius: 20px;
    border: 1px solid $dark_slate_blue;
  }
  .Select-clear-zone {
    display: none;
  }
  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    padding-left: 30px;
    line-height: 37px;
  }
  .Select-arrow-zone {
    padding-right: 20px;
  }
  .Select-arrow {
    border-width: 7px 5px 0;
    border-color: $dark_slate_blue transparent transparent;
  }
  .Select-input {
    height: 37px;
    line-height: 37px;
    padding-left: 30px;
  }
  .is-open {
    .Select-control {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.header__language .has-value:not(.Select--multi):not(.is-focused) > .Select-control > .Select-value .Select-value-label, .header__language .has-value.is-pseudo-focused:not(.Select--multi) > .Select-control > .Select-value .Select-value-label {
  color: #0ff;
}
