.app__language_he {
  h2,
  .form__element,
  .form__search .form__input,
  .form_datepicker,
  tbody .table__item,
  .sort,
  .info,
  .category__graph,
  .report-list,
  .vendors,
  .nav {
    direction: rtl;
    text-align: right;
  }
  .vendors {
    .table__wrap {
      direction: initial;
      .table {
        direction: rtl;
      }
    }
  }
  tbody .table__item {
    border-color: #e1e1e1;
    &.table__item-date {
      direction: ltr;
    }
  }
  // .header {
  //   .col-sm-2 {
  //     left: 83.33333%;
  //   }
  //   .col-sm-4 {
  //     right: 66.66667%;
  //   }
  // }
  .profile__logout .profile__icon {
    margin-right: 20px;
  }
  .nav {
    .he__profile {
      left: 83.33333%;
    }
    .he__logout {
      left: auto;
      right: 16.66667%;
    } 
    .he__menu {
      right: auto;
      left: 41.66667%;
    }
    .he__breadcrumbs {
      right: 25%;
    }
  }
  .sort {
    .col-md-4 {
      right: 66.66667%;
    }
    .col-md-8 {
      left: 33.33333%;
    }
    .custom-select {
      .Select-arrow-zone {
        padding-left: 20px;
      }
    }
  }
  .menu__wrap {
    left: 82%;
  }
  .main__content-wrap {
    right: 10%;
  }
  .menu__item {
    direction: rtl;
    .dropdown {
      text-align: right;
      &:before {
        right: auto;
        left: 20px;
        transform: scale(-1);
      }
    }
    .dropdown__link {
      padding-right: 45px;
      padding-left: 10px;
    }
    .no_items {
      font-size: 16px;
      padding-right: 30px;
      padding-left: 10px;
    }
  }
  .info__icon {
    margin-right: 0;
    margin-left: 30px;
  }
  .category__graph {
    .graph__wrap {
      left: 50%;
    }
    .griddle__wrap {
      right: 50%;
    }
  }
  .table__icon {
    margin-right: 0;
    margin-left: 15px;
  }
  .summary {
    .table__item:last-of-type {
      border: none;
    }
  }
  .table__item:last-of-type {
    border-right: 1px solid #e1e1e1;
  }
  .vendors {
    .datepicker__group {
      .scale__wrap {
        left: 50%;
      }
      .trans__wrap {
        right: 25%;
      }
    }
    .buttons__group {
      display: flex;
    }
    // .table__item:first-of-type {
    //   border: transparent;
    // }
  }
  .create-report {
    .form__label {
      &:before {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
  .checkbox__label:before {
    margin-right: 0;
    margin-left: 10px;
  }
  .table__item .checkbox {
    text-align: center;
  }
  .search .checkbox {
    margin-left: 0;
    margin-right: 12px;
  }
  thead {
    .table__item {
      &:first-of-type {
        .table__item_fix {
          border-top-left-radius: 0;
          border-top-right-radius: 20px;
        }
      }
      &:last-of-type {
        .table__item_fix {
          border-top-right-radius: 0;
          border-top-left-radius: 20px;
        }
      }
    }
  }
  .header__language .custom-select .has-value.Select--single:not(.is-focused) > .Select-control > .Select-value .Select-value-label,
  .header__language .custom-select .has-value.is-pseudo-focused.Select--single > .Select-control > .Select-value .Select-value-label {
    color: #fff;
  }
  .header__language .custom-select .Select--single > .Select-control .Select-value {
    color: #fff;
  }
  .report-page .datepicker input {
    direction: ltr;
  }
  .griddle-next {
    float: left;
    transform: scale(-1);
  }
  .griddle-previous {
    transform: scale(1);
  }
  .form_datepicker .form__input-wrap {
    direction: ltr;
  }
  .table {
    thead {
      .table__item {
        &:first-of-type {
          border-top-left-radius: 0;
          border-top-right-radius: 20px;
        }
        &:last-of-type {
          border-top-right-radius: 0;
          border-top-left-radius: 20px;
        }
      }
    }
  }
  .table__foot .table__item:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 20px;
  }
}

.content {
  padding-bottom: 59px;
}

.report-list {
  .sort__descr {
    width: auto;
    padding-left: 15px;
    margin-bottom: 20px;
    display: block;
  }
  .sort__list {
    margin-bottom: 20px;
  }
  .form__radio {
    width: 24.8%;
  }
  .table__wrap {
    // max-height: 616px;
    margin-bottom: 20px;
    overflow-y: auto;
  }
  .table__item {
    .icon-arrow-down {
      right: -20px;
    }
  }
  .table__head {
    // display: block;
    color: $white;
    .table__item {
      height: 46px;
    }
  }
  .table__header_bg {
    height: 46px;
  }
  // .table__body {
  //   display: block;
  //   height: 616px;
  // }
  // .table__body,
  // .table__head {
  //   .table__item {
  //     width: 281px;
  //   }
  // }
  // .table__head {
  //   .table__item {
  //     &:last-child {
  //       width: 296px;
  //     }
  //   }
  // }
}

.create-report {
  .form__label {
    padding-left: 15px;
    margin-bottom: 20px;
    font-size: 14px;
    color: $black;
    &.icon-report {
      font-size: 16px;
    }
    &:before {
      display: inline-block;
      margin-right: 10px;
      font-size: 19px;
      color: $dark_slate_blue;
    }
  }
  .form__input {
    height: 39px;
    line-height: 37px;
  }
  .title {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .checkbox {
    .checkbox__label {
      padding-left: 15px;
    }
  }
  .table__item {
    .checkbox {
      .checkbox__label {
        padding-left: 0;
      }
    }
    .icon-arrow-down {
      right: -20px;
    }
  }
}

.report-page {
  .react-datepicker__input-container {
    width: 160px;
    &:before {
      content: "\e805";
      position: absolute;
      right: 15px;
      top: 8px;
      font-family: 'font-icon';
      font-size: 18px;
      color: $white;
      z-index: 1;
      cursor: pointer;
      pointer-events: none;
    }
    &:after{
      content: "";
      width: 45px;
      height: 39px;
      position: absolute;
      right: 0;
      top: 0;
      background-color: $dark_slate_blue;
      cursor: pointer;
      pointer-events: none;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  .datepicker {
    input {
      padding-left: 20px;
      padding-right: 45px;
      text-align: left;
    }
  }
  .form {
    margin-top: 10px;
  }
  .report__button {
    padding: 9px 12px;
    background-color: $white_two;
    border-radius: 20px;
    font-size: 14px;
    color: $dark_slate_blue;
  }
  .table__wrap {
    max-height: 550px;
    margin-bottom: 50px;
  }
  .table {
    border: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .table__row {
    border-left: 1px solid $white_two;
    border-right: 1px solid $white_two;
  }
  .table__body {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    .table__item {
      text-align: center;
    }
  }
  .table__item {
    .icon-arrow-down {
      right: -20px;
    }
  }
  .table__wrap .griddle-body > div > .table {
    width: 100%;
  }
}

.griddle-body {
  .checkbox {
    text-align: center;
  }
}

.modal-content {
  .table {
    border: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    .table__head {
      .table__row {
        .table__item {
          padding: 10px 15px;
          padding-right: 20px;
        }
      }
    }
  }
  .table__item {
    .icon-arrow-down {
      right: -15px;
    }
  }
}

.modal__trans {
  // .table__item:nth-of-type(2),
  // .table__item:nth-of-type(3) {
  //   white-space: nowrap;
  // }
  .table__wrap {
    max-height: inherit;
  }
  .table{
    width: 100%;
    .table__head {
      .table__row {
        .table__item {
          text-align: left;
        }
      }
    }
    .table__row {
      .table__item {
        padding: 10px 15px;
        text-align: center;
      }
    }
  }
}

.trans__table {
  min-height: 670px;
}

@media only screen and (max-width: 1200px) {
  // .report-list {
  //   .table__body,
  //   .table__head {
  //     .table__item {
  //       width: 231px;
  //     }
  //   }
  //   .table__head {
  //     .table__item {
  //       &:last-child {
  //         width: 246px;
  //       }
  //     }
  //   }
  // }
  .app__language_he {
    
  }
  .modal-dialog {
    .table__found-items {
      float: none;
    }
  }
}

@media only screen and (max-width: 991px) {
  // .report-list {
  //   .table__body,
  //   .table__head {
  //     .table__item {
  //       width: 176px;
  //     }
  //   }
  //   .table__head {
  //     .table__item {
  //       &:last-child {
  //         width: 191px;
  //       }
  //     }
  //   }
  // }
  .create-report {
    .search {
      .form__search {
        display: block;
      }
    }
    .form__label {
      padding-left: 5px;
    }
  }
  // .report-page {
  //   .table {
  //     width: 925px;
  //   }
  // }
  .report-list__wrapper {
    .griddle-container,
    .table__foot {
      width: 925px;
    }
  }
  .app__language_he {
    .nav {
      .he__profile {
        left: 50%;
      }
      .he__logout {
        right: 50%;
      }
      // .he__menu {
      //   left: 50%;
      // }
      // .he__breadcrumbs {
      //   right: 50%;
      // }
      .he__menu {
        left: 83.3333%;
      }
      .he__breadcrumbs {
        right: 18%;
      }
    }
    .sort {
      .col-md-4 {
        right: 0;
      }
      .col-md-8 {
        left: 0;
      }
    }
    .profile__logout {
      text-align: left;
    }
  }
  .trans__table {
    .griddle-container,
    .table__foot {
      width: 1280px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .content {
    padding-bottom: 88px;
  }
  .content__wrap {
    .col-sm-9,
    .col-sm-12 {
      padding: 0;
    }
  }
  .dashboard {
    .row {
      margin: 0;
    }
    .info {
      .col-sm-4 {
        padding: 0;
      }
    }
  }
  .report-list {
    .sort__descr {
      width: 100px;
      display: inline-block;
    }
    .sort__mobile {
      vertical-align: top;
    }
    .table__wrap {
      margin-bottom: 0;
    }
  }
  .report-page {
    .datepicker__group,
    .buttons__group {
      margin: 0;
    }
    .title {
      padding: 0 15px;
    }
  }
  .create-report {
    .row {
      margin: 0;
    }
    .table__wrap {
      margin-bottom: 20px;
    }
    .search {
      padding: 0 15px;
    }
    .form__label {
      padding-left: 15px;
    }
  }
  .app__language_he {
    // .header {
    //   .col-sm-2 {
    //     left: 50%;
    //   }
    //   .col-sm-4 {
    //     right: 50%;
    //   }
    // }
    .sort__descr {
      margin-left: 10px;
      margin-right: 0;
    }
    .title {
      padding-right: 15px;
    }
    .category__graph {
      .graph__wrap {
        left: 0;
        .title {
          padding-right: 0;
        }
      }
      .griddle__wrap {
        right: 0;
      }
    }
    .menu__wrap {
      left: 0;
    }
    .main__content-wrap {
      right: 0;
    }
    .sort__mobile {
      margin-right: 15px;
      .custom-select .Select-arrow-zone {
        padding-left: 20px;
        padding-right: 0;
      }
    }
    .create-report_top {
      .checkbox {
        padding: 0 15px;
      }
    }
    .vendors {
      .datepicker__group {
        .scale__wrap {
          left: 0;
        }
        .trans__wrap {
          right: 0;
        }
      }
    }
  }
  .text-danger,
  .text-success {
    padding: 0 15px;
  }
}

// @media only screen and (max-width: 616px) {
//   .report-list {
//     .table__wrap{
//       .table{
//         width: 616px;
//       }
//     }
//   }
// }

@media only screen and (max-width: 480px) {
  .report-list {
    .table__wrap{
      .griddle-container{
        width: 616px;
      }
    }
    .sort__block {
      margin-left: 15px;
    }
    .form__search {
      // margin: 0 15px;
      margin-top: 20px;
    }
  }
  .create-report {
    .search {
      .checkbox {
        display: block;
      }
    }
    .col-sm-8 {
      padding: 0;
    }
    .griddle-container {
      width: 500px;
    }
  }
  .report-page {
    .col-lg-9 {
      padding: 0;
    }
    // .title {
    //   padding: 0 15px;
    // }
    // .buttons__group,
    // .datepicker__group {
    //   margin: 0;
    // }
  }
  .trans__table {
    min-height: inherit;
  }
  
}

.text-danger,
.text-success {
  margin-bottom: 10px;
}

.submit__block {
  margin-top: 30px;
}

// .griddle {
//   margin-bottom: 40px;
// }

@media only screen and (max-width: 320px) {
  .modal__trans .table__wrap {
    max-height: 300px;
  }
}