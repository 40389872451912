.users {
    &__containter {}
    &__table {
        direction: rtl;
        width: 60%;
        margin-left: 40%;
    }
    &__buttons {
        width: 60%;
        margin-left: 40%;
        display: flex;
        margin-bottom: 10px;
    }
    &__button {
        width: 200px;
        margin-right: 20px;
    }
}

.vertical_center {
    position: relative;
    top:50%;
    transform: translateY(-50%); 
}
