.wrapper {
    background-color: #ffffff;
    box-shadow: 0 2px 45px 0 rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    padding: 10px;
    min-width: 1100px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image {
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
    }

    .text {
        margin: 0 0 20px;

        h2 {
            font-size: 30px;
            color: #0f2649;
            line-height: 34px;
            font-weight: 400;
        }

        h3 {
            font-size: 20px;
            color: #0f2649;
            line-height: 34px;
            font-weight: 400;
            text-align: center;
        }
    }

    .fields {
        width: 80%;

        .error {
            font-size: 20px;
            font-weight: bold;
            color: red;
            text-align: center;
        }
    }

    .buttons {
        width: 80%;
        display: flex;
        justify-content: space-around;

        button {
            height: 35px;
            width: 100px;
            border-radius: 100px;
        }
    }

    .right_buttons {
        width: 300px;
        display: flex;
        justify-content: space-around;
    }
}

.table {
    direction: rtl;
    margin-bottom: 10px;
}