.media-list {
  max-height: calc(100vh - 300px);
  overflow: auto;

  img {
    width: 300px;
    height: auto;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin: 20px 0;
  }
}
