.wrapper {
    background-color: #ffffff;
    box-shadow: 0 2px 45px 0 rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    padding: 10px;
    min-width: 320px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .image {
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
    }
    .text {
        h2 {
            font-size: 30px;
            color: #0f2649;
            line-height: 34px;
            margin: 0 0 40px;
            font-weight: 400;
        }
    }
    .fields {
        width: 80%;
        .error {
            font-size: 20px;
            font-weight: bold;
            color: red;
            text-align: center;
        }
    }
    .buttons {
        width: 80%;
        display: flex;
        justify-content: space-between;
        button {
            height: 35px;
            width: 99px;
            border-radius: 100px;
        }
    }
}