.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 17px 0;
  background-color: $dark_indigo;
}

.copyright {
  color: $white;
  font-size: 13px;
  opacity: .5;
}

.update {
  color: $white;
  font-size: 16px;
}

.update__icon {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  margin-left: 20px;
}

.update__descr {
  display: inline-block;
  vertical-align: middle;
}

@media only screen and (max-width: 767px) {
  .copyright,
  .update {
    text-align: center;
  }
  .update {
    margin-bottom: 15px;
  }
}