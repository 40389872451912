.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.users {
    &__table {
        direction: rtl;
    }

    &__buttons {
        display: flex;
        margin-bottom: 10px;
    }

    &__button {
        width: 200px;
        margin-right: 20px;
    }
}

.child_button {
    width: 100px;
}

.color_blue {
    color: #16325b;
}

.vertical_center {
    position: relative;
    top: 50%;
    display: flex;
    justify-content: right;
    align-items: center;
    transform: translateY(-50%);
}