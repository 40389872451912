.users {
    &__table {
        direction: rtl;
    }
    &__buttons {
        display: flex;
        margin-bottom: 10px;
    }
    &__button {
        width: 200px;
        margin-right: 20px;
    }
}

::-webkit-scrollbar {
    width: 8px;
}
.vertical_center {
    position: relative;
    top:50%;
    transform: translateY(-50%); 
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}