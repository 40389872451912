strong {
    font-weight: bold;
}

.managment-system {
    .hide-div {
        display: none;
    }

    direction: rtl;
    padding: 15px 0 60px;

    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12 {
        float: right;
    }

    aside.sidebar {
        .sidebar-logo {
            height: 77.9px;
            text-align: center;
            border: 1px solid #e7e7e7;
            padding: 10px;

            img.logo {
                max-height: 100%;
                width: auto;
            }
        }

        nav.sidebar-navigation {
            min-height: 513.1px;
            background-color: #ebebeb;

            ul {
                height: 100%;
                width: 100%;

                li {
                    padding: 13px 10px;
                    transition: background-color 0.5s ease;

                    &.Selected,
                    &:hover {
                        background-color: #d8d8d8;
                    }

                    a {
                        font-size: 14px;
                        color: #0f2649;
                        letter-spacing: 0;
                    }
                }
            }
        }
    }

    // .scalegroups {
    //     &__items {}
    //     &__item {
    //         display: flex;
    //         align-items: center;
    //         justify-content: space-between;
    //         border: solid 1px #555;
    //         border-radius: 11px;
    //         height: 30px;
    //         width: 90%;
    //         margin-bottom: 5px;
    //         padding-right: 10px;
    //         &--selected {
    //             color: #ffffff;
    //             background-color: rgb(43, 128, 109);
    //         }
    //         &:hover {
    //             cursor: pointer;
    //             color: #ffffff;
    //             background-color: rgb(43, 128, 109);
    //         }
    //         &-icon {
    //             margin-left: 5px;
    //             fill: silver;
    //             &:hover {
    //                 fill:red;
    //             }
    //         }
    //     }
    // }
    .weight-groups {
        margin: 40px -15px 0;
        border-left: 1px solid #e7e7e7;
        height: 550px;
        padding-left: 5px;
        overflow-y: auto;
        overflow-x: hidden;

        h2 {
            font-size: 16px;
            line-height: 21px;
            color: #0f2649;
            letter-spacing: 0;
            font-weight: 700;
            margin-bottom: 5px;

            a {
                width: 21px;
                height: 21px;
                border-radius: 100%;
                text-align: center;
                float: left;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-content: center;
                align-items: center;

                i img {
                    width: 21px;
                    height: 21px;
                }
            }
        }

        .scalegroups__item {
            margin-bottom: 5px;
        }

        .weight-group-list {
            p {
                font-size: 13px;
                line-height: 15px;
                color: #7f8892;
            }

            .weight-group {
                .weight-group-header {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-content: flex-start;
                    align-items: flex-start;
                    margin-top: 10px;

                    h3 {
                        font-size: 13px;
                        color: #7f8892;
                        order: 0;
                        flex: 1 1 auto;
                        align-self: auto;
                    }

                    .group-buttons {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-content: flex-start;
                        align-items: flex-start;
                        order: 0;
                        flex: 0 1 auto;
                        align-self: flex-end;

                        a {
                            font-size: 10px;

                            &:first-child {
                                color: #163382;
                                margin-left: 5px;
                            }

                            &:last-child {
                                color: #f85f58;
                            }
                        }
                    }
                }
            }
        }
    }

    button.sides {
        float: left;
        margin-bottom: 61px;

        i {
            margin-right: 5px;
        }
    }

    .sales {
        margin: 40px -15px 0;
        min-height: 513.1px;
        min-width: 200px;
        border-left: 1px solid #e7e7e7;

        h2 {
            font-size: 16px;
            color: #0f2649;
            font-weight: 700;
            line-height: 21px;
            margin-bottom: 5px;
        }

        .selected {
            background-color: #ddddff;
        }

        .list__buttons {
            display: flex;
        }

        .list__items {}

        .list__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: solid 1px #555;
            border-radius: 5px;
            height: 30px;
            width: 100%;
            margin-bottom: 5px;
            padding-right: 10px;
            color: inherit;
            font-size: 16px;
            font-weight: bold;

            &:hover {
                cursor: pointer;
            }

            .bolet-icon-trash {
                color: orangered;
            }

            .bolet-icon-edit {
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        .item-selected {
            background-color: #ddddff;
        }

        .btn-new {
            position: absolute;
            left: 15px;
            top: 0px;
            margin: auto;
            cursor: pointer;
        }
    }

    .sales-items {
        margin: 40px 0 0;
    }

    .sales-customer-view {
        margin: 0px -60px 0;
        min-width: 200px;

        .sale-add-link {
            display: block;
            margin-bottom: 10px;
            text-align: right;
            white-space: nowrap;
            font-family: Arial;
            font-weight: 700;
            font-size: 16px;
            line-height: 1;
            color: rgb(54, 186, 151);
            direction: rtl;
            text-decoration: none;
            cursor: pointer;
        }

        h2 {
            font-size: 16px;
            color: #0f2649;
            font-weight: 700;
            line-height: 21px;
            margin-bottom: 10px;
        }

        .readonly-editor-wrapper {
            height: 110px;
            direction: rtl;

            .editor {
                z-index: -1000;
                position: relative;
                border: solid 1px lightgray;
                height: 110px;
                width: 270px;
                font-family: inherit;
            }
        }

        .bolet-icon-trash {
            color: orangered;
        }
    }

    .sales-details {
        margin: 40px -40px 0;
        min-height: 513.1px;
        min-width: 200px;

        h2 {
            font-size: 16px;
            color: #0f2649;
            font-weight: 700;
            line-height: 21px;
            margin-bottom: 10px;
        }

        .sale-add-link {
            display: block;
            margin-bottom: 10px;
            text-align: right;
            white-space: nowrap;
            font-family: Arial;
            font-weight: 700;
            font-size: 16px;
            line-height: 1;
            color: rgb(54, 186, 151);
            direction: rtl;
            text-decoration: none;
            cursor: pointer;
        }

        .sale-edit-link {
            display: block;
            margin-bottom: 10px;
            text-align: right;
            color: rgb(71, 70, 89);
            font-family: ArialMT;
            font-size: 16px;
            cursor: pointer;
        }

        .readonly-editor-wrapper {
            height: 110px;
            direction: rtl;

            .editor {
                position: relative;
                border: solid 1px lightgray;
                height: 110px;
                width: 300px;
                font-family: inherit;
            }
        }

        .sale-user-text {
            border: solid 1px lightgray;
            padding: 5px;
            width: 300px;
            height: 100px;
        }

        .details-image,
        .details-template,
        .details-movie {
            width: 176px;
            height: auto;
            margin-bottom: 10px;

            .details-image img {
                width: 100%;
                height: auto;
                max-height: 100%;
            }
        }

        .duration {
            margin-top: 10px;

            label {
                width: 200px;
                font-size: 14px;
                color: #0f2649;
                transition: background-color 0.5s ease;
                font-weight: 400;
                margin-bottom: 5px;
            }

        }

        .bolet-icon-trash {
            color: orangered;
        }
    }

    .details {
        margin: 40px -60px 0;

        //min-height: 350px;
        h2 {
            font-size: 16px;
            color: #0f2649;
            font-weight: 700;
            line-height: 21px;
            margin-bottom: 10px;
        }

        .big-image {
            img {
                width: 390px;
                height: 252px;
            }

            .duration {
                margin-top: 10px;
                margin-right: 15px;
                order: 0;
                flex: 1 1 auto;
                width: 55%;
                position: relative;

                label {
                    font-size: 14px;
                    color: #0f2649;
                    transition: background-color 0.5s ease;
                    font-weight: 400;
                    margin-bottom: 5px;
                }

                .select-duration {
                    width: 150px;
                }
            }
        }

        form {
            fieldset {
                margin-top: 20px;
                margin-right: 20px;
                margin-bottom: 20px;

                .radio-options {
                    display: block;

                    .radio-group {
                        position: relative;

                        input+label.radio-option-label {
                            font-size: 14px;
                            color: #0f2649;
                            font-weight: 400;
                            position: relative;
                            transition: background-color 0.5s ease;
                            width: 100%;

                            &:before {
                                content: "";
                                display: inline-block;
                                border-radius: 100%;
                                border: 1px solid #0f2649;
                                width: 8px;
                                height: 8px;
                                margin-left: 3px;
                            }
                        }

                        input:checked+label.radio-option-label {
                            &:after {
                                content: "";
                                display: inline-block;
                                border-radius: 100%;
                                width: 8px;
                                height: 8px;
                                border: 1px solid #0f2649;
                                background-color: #0f2649;
                                position: absolute;
                                top: 5px;
                                right: 0px;
                                box-shadow: inset 0 0 0px 1.5px #ffffff;
                            }
                        }

                        .date-picker-inputs {
                            margin-top: 7px;
                            display: flex;
                            width: 230px;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-content: flex-start;
                            align-items: flex-start;

                            .from-date {
                                margin: 0 0 0 10%;
                            }

                            .from-date,
                            .until-date {
                                order: 0;
                                flex: 1 1 auto;
                                align-self: auto;
                                width: 45%;
                                position: relative;

                                label {
                                    font-size: 14px;
                                    color: #0f2649;
                                    transition: background-color 0.5s ease;
                                    font-weight: 400;
                                    margin-bottom: 5px;
                                }

                                .react-datepicker__input-container {
                                    border: 1px solid #0f2649;
                                    transition: background-color 0.5s ease;
                                    border-radius: 4px;
                                    height: 36px;
                                    line-height: 36px;

                                    input {
                                        background-color: transparent;
                                        padding: 0 4px 0 25px;
                                        font-size: 14px;
                                        color: #0f2649;
                                        transition: background-color 0.5s ease;
                                        position: relative;
                                    }
                                }

                                .bolet-icon-calendar {
                                    position: absolute;
                                    font-size: 16px;
                                    color: #0f2649;
                                    transition: background-color 0.5s ease;
                                    left: 6px;
                                    top: 30px;
                                }
                            }
                        }

                        .hours-picker-inputs {
                            margin-top: 7px;
                            display: flex;

                            .from-time,
                            .until-time {
                                //width: 100%;
                                margin-left: 28px;
                                position: relative;

                                label {
                                    font-size: 14px;
                                    color: #0f2649;
                                    transition: background-color 0.5s ease;
                                    font-weight: 400;
                                    margin-bottom: 5px;
                                }
                            }

                            .select-time {
                                &__control {
                                    width: 100px;
                                }

                                &__menu {
                                    height: 95px;

                                    &-list {
                                        height: 95px;
                                    }
                                }
                            }
                        }

                        .days-picker-inputs {
                            margin-top: 7px;
                            display: grid;
                            grid-template-columns: repeat(2, 50px);
                            grid-template-rows: repaet(4, 10px);
                            grid-gap: 10px;
                        }

                        input+label {
                            .date-picker-inputs {

                                .from-date,
                                .until-date {
                                    label {
                                        color: #e7e7e7;
                                    }

                                    .react-datepicker__input-container {
                                        border: 1px solid #e7e7e7;

                                        input {
                                            color: #e7e7e7;
                                        }
                                    }

                                    .bolet-icon-calendar {
                                        color: #e7e7e7;
                                    }
                                }
                            }
                        }

                        input:checked+label.radio-option-label {
                            .date-picker-inputs {

                                .from-date,
                                .until-date {
                                    label {
                                        color: #0f2649;
                                    }

                                    .react-datepicker__input-container {
                                        border: 1px solid #0f2649;

                                        input {
                                            color: #0f2649;
                                        }
                                    }

                                    .bolet-icon-calendar {
                                        color: #0f2649;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .images-gallery-container {
        h2 {
            font-size: 16px;
            color: #0f2649;
            font-weight: 700;
            line-height: 21px;
            margin-bottom: 10px;
        }

        margin-top: 40px; //min-height: 513.1px;
        width: 250px; //background-color: #EBEBEB;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: flex-start;

        .small-images-container {
            background-color: #d8d8d8;
            width: 100%;
            padding: 15px;
            float: left;

            .saved-detailes {
                display: none;
                padding-bottom: 8px;

                &.shown {
                    display: block;
                }

                p {
                    font-size: 16px;
                    font-weight: 700;
                    color: #4f4d4d;
                    line-height: 18px;
                }
            }

            ul {
                padding: 20px 0 10px;
                direction: ltr;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-content: flex-start;
                align-items: flex-start;

                li {
                    display: inline-block;
                    padding-bottom: 10px;

                    .img-wrapper {
                        background-color: #ebebeb;
                        width: 58px;
                        height: 48px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: center;
                        align-content: center;
                        align-items: center;
                        position: relative;

                        a.delete-images-button {
                            position: absolute;
                            top: 50px;
                            left: 0px;

                            i {
                                font-size: 12px;
                                color: orangered;
                            }
                        }

                        .place-holder {
                            width: 45px;
                            height: 29px;
                        }

                        .real-image {
                            width: 52px;
                            height: 42px;
                        }

                        .real-movie {
                            width: 45px;
                            height: 29px;
                        }
                    }

                    .time-select {
                        font-size: 13px;
                        color: #0f2649;
                        margin-top: 5px;
                        padding: 0 5px;
                        direction: rtl;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-content: flex-start;
                        align-items: flex-start;

                        &.editor {
                            width: 100%;
                            display: block;
                            position: relative;
                            padding: 0;
                            margin: 0;

                            input {
                                width: 100%;
                                max-width: 58px;
                                padding: 4px 5px 4px 20px;
                            }

                            a {
                                position: absolute;
                                left: 3px;
                                top: 3px;
                            }
                        }

                        .duration-read {
                            max-width: 35px;

                            &:disabled {
                                background-color: transparent;
                                color: #0f2649;
                            }

                            &::placeholder {
                                color: #0f2649;
                            }
                        }

                        i {
                            color: #0f2649;
                        }
                    }
                }
            }
        }
    }
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: hidden;
    z-index: -9999;
    top: 0;
    right: 0;
    background-color: rgba(79, 77, 77, 0.6);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: linear 0.5s ease;

    &.shown-popup {
        opacity: 1;
        visibility: visible;
        z-index: 9999;
    }

    .pop-up-wrapper {
        background-color: #ffffff;
        box-shadow: 0 2px 45px 0 rgba(0, 0, 0, 0.23);
        border-radius: 10px;
        padding: 5% 6%;
        max-width: 487px;
        min-height: 376px;
        width: 100%;
        text-align: center;

        .popup-image {
            margin-bottom: 10px;
            width: 100%;
        }

        .pop-up-text {
            width: 100%;
            height: 20%;

            h2 {
                font-size: 30px;
                color: #0f2649;
                line-height: 34px;
                display: block;
                text-align: center;
                margin: 0 0 40px;
                font-weight: 400;
            }

            form {
                margin: 0 0 36px;

                label {
                    text-align: right;
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    color: #0f2649;
                }

                input {
                    border: 1px solid #0f2649;
                    border-radius: 4px;
                    width: 100%;
                    height: 36px;
                    margin-top: 5px;
                    padding: 5px 10px;
                }
            }
        }

        .pop-up-empty-fields {
            height: 45%;
        }

        .pop-up-fields {
            text-align: right;
            height: 50%;

            .pop-up-error {
                font-size: 20px;
                font-weight: bold;
                color: red;
                text-align: center;
            }
        }

        .pop-up-buttons {
            width: 40%;
            height: 30%;
            display: flex;
            text-align: bottom;
            justify-content: space-evenly;

            button {
                height: 35px;
                width: 99px;
                border-radius: 100px;
            }
        }

        .center-text {
            width: 100%;
        }
    }
}

.edit-items {
    direction: rtl;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 20px;
    right: 20px;
    height: auto;
    width: auto;

    .header-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .header-text {
        position: relative;
        top: -25px;
        text-align: center;
        font-size: 30px;
        color: #0f2649;
        letter-spacing: 0;
        line-height: 35px;
    }

    .header-search {
        position: relative;
        text-align: center;
        direction: rtl;
        top: 100px;
    }

    .searched {
        position: relative;
        right: 20px;
        height: 220px;
        width: 90%;
        margin: auto;
        overflow-y: auto;
    }

    .sub-header-text {
        position: relative;
        top: 15px;
        right: 80px;
        text-align: right;
        font-size: 16px;
        color: #0f2649;
        letter-spacing: 0;
    }

    .selected {
        position: relative;
        right: 20px;
        top: 20px;
        height: 134px;
        padding-bottom: 40px;
        width: 90%;
        margin: auto;
        overflow-y: auto;
    }

    .item-label {
        float: right;
        margin: 10px;
    }

    .sale_search {
        position: relative;
        top: -120px;
        width: 300px;
        height: 36px;
    }

    .sale_input {
        border: 1px solid rgb(15, 38, 73);
        border-radius: 4px;
    }

    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: right;

        li {
            margin: 4px;
            padding: 0;
            width: 32%;

            .item-wrapper {
                width: 300px;
                height: 36px;
                border: 1px solid rgb(15, 38, 73);
                border-radius: 4px;

                .checkbox {
                    float: right;
                    margin: 8px;
                    margin-bottom: 0;

                    input {
                        margin: 0;
                    }

                    input+label.days-option-label {
                        font-size: 14px;
                        color: #0f2649;
                        font-weight: 400;
                        line-height: 20px;
                        vertical-align: middle;

                        &:before {
                            content: "";
                            border: 1px solid #0f2649;
                            width: 12px;
                            height: 12px;
                            margin-left: 5px;
                            display: inline-block;
                            position: relative;
                            top: 1px;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            right: 3.5px;
                            top: 5px;
                            width: 5px;
                            height: 8px;
                            border: solid #163382;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                            opacity: 0;
                        }
                    }

                    input:checked+label.days-option-label {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .pop-up-buttons {
        position: relative;
        top: 40px;
        display: flex;
        justify-content: space-around;

        button {
            height: 40px;
            width: 200px;
            border-radius: 100px;
            margin: auto;
        }
    }
}

.advertising-popup {
    direction: rtl;

    .pop-up-wrapper {
        width: 20%;
        height: 45%;
        padding: 3%;
        direction: rtl;

        .col-sm-1,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12 {
            float: right;
        }

        .pop-up-buttons {
            width: 100%;
            padding: 3%;
            margin: auto;
            text-align: center;

            button {
                height: 35px;
                width: 99px;
                border-radius: 100px;
            }
        }
    }
}

.user-text {
    .pop-up-wrapper {
        max-width: 60%;
        min-height: 60%;
        max-height: 90%;
        padding: 3%;
        direction: rtl;

        .col-sm-1,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12 {
            float: right;
        }

        h2 {
            text-align: center;
            font-size: 30px;
            color: #0f2649;
            letter-spacing: 0;
            line-height: 35px;
        }

        .template-wrapper {
            width: 800px;
            height: 600px;
        }

        .editor-wrapper {
            direction: ltr;

            .editor {
                border: solid 1px lightgray;
                min-height: 300px;
                font-family: inherit;
            }
        }

        .pop-up-buttons {
            width: 300px;
            margin: auto;
            text-align: center;

            button {
                height: 35px;
                width: 99px;
                border-radius: 100px;
            }
        }
    }
}

.template-selection {
    .pop-up-wrapper {
        max-width: 50%;
        min-height: 30%;
        max-height: 90%;
        padding: 3%;
        direction: rtl;

        .col-sm-1,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12 {
            float: right;
        }

        h1 {
            text-align: center;
            font-size: 20px;
            color: #0f2649;
            letter-spacing: 0;
        }

        h2 {
            text-align: center;
            font-size: 30px;
            color: #0f2649;
            letter-spacing: 0;
            line-height: 35px;
        }

        h3 {
            direction: rtl;
            margin: 20px 20px 0px 20px;
            text-align: right;
            font-size: 20px;
            font-style: italic;
            color: #0f2649;
            letter-spacing: 0;
            line-height: 35px;
        }

        .image-wrapper {
            border-radius: 4px;
            border: 1px solid #4b4b4b;
            padding: 5px;

            width: 150px;
            height: 140px;
            margin-bottom: 10px;

            .image-box {
                width: 100%;
                height: auto;
            }

            .gallery-image-box {
                max-height: 100%;
                object-fit: contain;
                overflow: hidden;
                background-color: white;
                margin: 0;

            }

            .gallery-image-details {
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 2px 10px;

            }

            .bolet-icon-trash {
                color: orangered;
                position: relative;
                right: 10px;
            }
        }

        .name-box {
            text-align: right;
            white-space: nowrap;
            font-family: Arial;
            font-size: 14px;
            color: rgb(13, 27, 56);
            direction: rtl;
            margin: 20px;
            margin-bottom: 30px;
            border: 1px solid #0f2649;
            width: 250px;
            height: 35px;
            border-radius: 4px;
            padding-right: 5px;
            display: block;

            .label {
                font-family: Arial;
                font-size: 14px;
                color: rgb(13, 27, 56);
                line-height: 35px;
                position: relative;
                bottom: 28px;
                left: 185px;
            }
        }

        .remark-box {
            position: relative;
            left: 4px;
            text-align: right;
            white-space: nowrap;
            font-family: Arial;
            font-size: 14px;
            color: rgb(13, 27, 56);
            direction: rtl;
            border: 1px solid #0f2649;
            width: 250px;
            height: 100px;
            border-radius: 4px;
            padding-right: 5px;
        }

        .sale-color-picker {
            margin: 0px 15px;
            direction: rtl;
            text-align: right;
            position: relative;
            bottom: 20px;

            span {
                position: relative;
                top: 18px;
                right: 40px;
            }
        }

        .pop-up-buttons {
            width: 300px;
            padding-top: 30px;
            margin: auto;
            text-align: center;

            button {
                height: 35px;
                width: 99px;
                border-radius: 100px;
            }
        }

        .images-box {
            margin: 0;
            padding: 0;
            border: 0;
        }

        .fields-box {
            margin: 0;
            padding: 0;
            border: 0;
            left: 40px;
            width: 300px;
        }

        .sale-images {
            position: relative;
            top: 40px;
            margin: 20px;
            line-height: 40px;
            direction: rtl;
            text-align: right;
        }

        .sale-add-link {
            color: darkblue;
            cursor: pointer;
        }

        #c1,
        #c2,
        #c3 {
            display: inline-block;
            margin: 20px 30px 0 20px;
            width: 200px;
            height: 140px;

            img {
                height: 140px;
                width: auto;
                transform: translateY(5%);
            }

            label {
                display: inline-block;
            }
        }

        #c1 {
            background-color: rgb(22, 51, 90);
        }

        #c2 {
            background-color: rgb(62, 196, 168);
        }

        #c3 {
            background-color: rgb(248, 95, 88);
        }

        #template-labels-wrapper {
            #label {
                display: inline-block;
                margin: 0 30px;
                width: 220px;
            }
        }
    }
}

.template-payget {
    .pop-up-wrapper {
        min-width: 20%;
        max-width: 45%;
        min-height: 50%;
        max-height: 90%;
        padding: 3%;
        direction: rtl;

        .col-sm-1,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12 {
            float: right;
        }

        h2 {
            text-align: center;
            font-size: 30px;
            color: #0f2649;
            letter-spacing: 0;
        }

        h1 {
            text-align: center;
            font-size: 20px;
            color: #0f2649;
            letter-spacing: 0;
            line-height: 40px;
        }

        .item_search {
            position: relative;
            left: 253px;
            width: 300px;
            height: 36px;
        }

        .item_input {
            border: 1px solid rgb(15, 38, 73);
            border-radius: 4px;
        }

        .name-box {
            text-align: right;
            white-space: nowrap;
            font-family: Arial;
            font-size: 14px;
            color: rgb(13, 27, 56);
            direction: rtl;
            margin: 20px;
            border: 1px solid #0f2649;
            width: 300px;
            height: 35px;
            border-radius: 4px;
            padding-right: 5px;
            display: block;
        }

        .image-box {
            width: 290px;
            height: 206px;
            background-color: rgb(62, 196, 168);
        }

        .remark-box {
            position: relative;
            left: 253px;
            text-align: right;
            white-space: nowrap;
            font-family: Arial;
            font-size: 14px;
            color: rgb(13, 27, 56);
            direction: rtl;
            border: 1px solid #0f2649;
            width: 300px;
            height: 100px;
            border-radius: 4px;
            padding-right: 5px;
        }

        .pop-up-buttons {
            width: 300px;
            padding-top: 30px;
            margin: auto;
            text-align: center;

            button {
                height: 35px;
                width: 99px;
                border-radius: 100px;
            }
        }
    }
}

.images-gallery-uploader {
    .pop-up-wrapper {
        max-width: 90%;
        min-height: 30%;
        max-height: 90%;
        padding: 3%;
        direction: rtl;

        .col-sm-1,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12 {
            float: right;
        }

        h2 {
            text-align: center;
            font-size: 30px;
            color: #0f2649;
            letter-spacing: 0;
            line-height: 55px;
        }

        nav.popup-navigation {
            text-align: right;

            ul {
                li {
                    padding-bottom: 20px;

                    a {
                        font-size: 16px;
                        color: #0f2649;
                        letter-spacing: 0;
                        line-height: 22px;
                        font-weight: 400;
                    }

                    &.selected {
                        a {
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        .images-tab {
            text-align: right;

            .galley-header {
                .commands {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-content: stretch;
                    align-items: center;

                    ul {
                        li {
                            display: inline-block;
                            padding-left: 20px;

                            form {
                                position: relative;

                                label {
                                    font-size: 16px;
                                    color: #0f2649;
                                    letter-spacing: 0;
                                    line-height: 22px;
                                    font-weight: 700;
                                    background-color: #ffffff;
                                    cursor: pointer;
                                }

                                input {
                                    position: absolute;
                                    opacity: 0;
                                    display: block;
                                    width: 110px;
                                    top: 0;
                                    cursor: pointer;
                                }
                            }

                            a {
                                font-size: 16px;
                                color: #0f2649;
                                letter-spacing: 0;
                                line-height: 22px;
                                font-weight: 400;
                            }
                        }
                    }

                    button {
                        height: 35px;
                        width: 99px;
                    }
                }
            }

            .gallery-images {
                margin-top: 30px;
                height: 60vh;
                overflow-y: auto;

                ul {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap; //justify-content: space-between;
                    align-content: stretch;
                    align-items: center;

                    li {
                        margin: 5px;
                        width: 15%;

                        .image-wrapper {
                            background-color: white;
                            height: 152px;
                            position: relative;

                            a {
                                i {
                                    color: #f85f58;
                                    font-size: 15px;
                                }
                            }

                            .checkbox {
                                margin: 0;
                                position: absolute;
                                top: 5px;
                                right: 5px;

                                input {
                                    margin: 0;
                                }

                                input+label {
                                    &:before {
                                        content: "";
                                        border: 1px solid #0f2649;
                                        width: 12px;
                                        height: 12px;
                                        margin-left: 5px;
                                        display: inline-block;
                                        position: relative;
                                        top: 1px;
                                    }

                                    &:after {
                                        content: "";
                                        position: absolute;
                                        right: 3.5px;
                                        top: 3.5px;
                                        width: 5px;
                                        height: 8px;
                                        border: solid #163382;
                                        border-width: 0 2px 2px 0;
                                        transform: rotate(45deg);
                                        opacity: 0;
                                    }
                                }

                                input:checked+label {
                                    &:after {
                                        opacity: 1;
                                    }
                                }
                            }
                        }

                        .image-wrapper {
                            border-radius: 4px;
                            border: 1px solid #4b4b4b;
                            padding: 5px;

                            height: auto;
                            box-sizing: border-box;
                            margin-bottom: 10px;

                            display: block;
                            width: 100%;

                            .gallery-image-details {
                                display: flex;
                                justify-content: space-between;
                                box-sizing: border-box;
                                padding: 2px 10px;

                            }

                            img {
                                width: 100%;
                                height: 120px;
                            }

                            .movie-label {
                                color: black;
                                text-align: center;
                                width: 100%;
                            }
                        }

                        .image-details {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-content: stretch;
                            align-items: center;
                            margin-top: 5px;

                            p {
                                font-size: 16px;
                                color: #5a5a6c;
                                letter-spacing: 0;
                            }

                            a {
                                i {
                                    color: #f85f58;
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.green-btn {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
    background-color: #3ec4a8;
    display: inline-block;
    border-radius: 100px;
    min-height: 35px;
    line-height: 34px;
    transition: background-color 0.5s ease;

    &:hover {
        color: #ffffff;
        background-color: rgb(43, 128, 109);
    }
}

.red-btn {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
    background-color: #c43e54;
    display: inline-block;
    border-radius: 100px;
    min-height: 35px;
    line-height: 34px;
    transition: background-color 0.5s ease;

    &:hover {
        color: #ffffff;
        background-color: darken(#c43e54, 10%);
    }
}

.grey-btn {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
    background-color: #9b9b9b;
    display: inline-block;
    border-radius: 100px;
    min-height: 35px;
    line-height: 34px;
    transition: background-color 0.5s ease;

    &:hover {
        color: #ffffff;
        background-color: #4b4b4b;
    }
}

.weights-add-items {
    min-width: 134px;
    margin-top: 20px;
}

.save-info-btn {
    width: 100%;
    margin-top: 69px;
}

.btn-vertical-group button {
    display: block;
    /* Make the buttons appear below each other */
    margin: 10px;
}

.btn-horizental-group button {
    margin: 10px;
}

.popup_height {
    min-height: 0px;
    max-height: 150px;

}

@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 480px) {}