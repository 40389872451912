.nav {
    padding: 18px 0;
    margin-bottom: 30px;
    background-color: $dark_slate_blue;
}

.nav__separator {
    margin-bottom: 10px;
    height: 1px;
    background-color: $white;
    clear: both;
}

.profile {
    background-color: $dark_indigo;
    border-radius: 20px;

    .profile__icon {
        width: 35px;
        height: 35px;
        line-height: 35px;
        border-radius: 50%;
        background-color: $white;
        color: $dark_slate_blue;
    }

    .profile__descr {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 16px;
    }
}

.profile__icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    color: $white;
    text-align: center;
}

.profile__descr {
    display: inline-block;
    vertical-align: middle;
    color: $white_two;
}

.profile__logout {
    padding-top: 6px;

    .profile__icon {
        margin-left: 20px;
    }
}

// .menu_open {
//   .menu__list {
//     transform: translateX(0);
//   }
// }
.menu__button {
    display: block;
    margin: 0 auto;
    padding-top: 6px;
    font-size: 20px;
    color: $white;
}

.menu__list {
    // position: absolute;
    // left: -615px;
    // transform: translateX(-100%);
    // top: 53px;
    // width: 700px;
    // min-height: calc(100vh - 201px);
    background-color: $white_two;
    text-align: right;
    transition: .5s;
    z-index: 1; // display: none;
}

.menu__item {
    &:last-of-type {
        .dropdown {
            border-bottom: none;
        }
    }
}

.menu__icon {
    object-fit: contain;
}

.dropdown {
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid $white;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
    color: $black_two;

    &.dropdown_open {
        &:before {
            transform: rotate(45deg);
        }
    }

    &:before {
        position: absolute;
        right: 20px;
        top: 22px;
        color: $dark_slate_blue;
        font-size: 10px;
    }

    i {
        display: inline-block;
        margin-left: 10px;
        margin-right: 20px;
        font-size: 21px;
    }
}

.dropdown__menu {
    width: 100%;
    margin-top: 15px;
}

.dropdown__link {
    display: block;
    padding: 12px 0;
    padding-left: 45px;
    font-size: 14px;
    color: $black_two;

    &.dropdown__link_active,
    &:hover {
        color: $dark_slate_blue;
        background-color: $white_three;
    }
}

.breadcrumbs__wrapper {
    padding-top: 9px;
}

.breadcrumbs {
    letter-spacing: 0.3px;
    font-size: 13px;
    display: inline-block;
    vertical-align: top; // &:before {
    //   content: '/';
    //   display: inline-block;
    //   vertical-align: middle;
    //   margin: 0 10px;
    //   color: $white_two;
    // }
}

.breadcrumbs__item {
    display: inline-block;
    vertical-align: middle;
    color: grey;

    &:first-child {
        &:before {
            content: '/';
            display: inline-block;
            vertical-align: middle;
            margin: 0 10px;
            color: $white_two;
        }
    }

    &:after {
        content: '/';
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;
        color: $white_two;
    }

    &:last-of-type {
        &:after {
            display: none;
        }
    }

    a {
        color: $white_two;
        cursor: pointer;

        &:hover {
            color: $white_two;
            text-decoration: underline;
        }
    }
}

.breadcrumbs__home-link {
    display: inline-block;
    vertical-align: top;
    color: #fff;
}

@media only screen and (max-width: 991px) {
    .profile {
        margin-bottom: 10px;
    }

    .menu__button {
        margin: 0;
    }
}

@media only screen and (max-width: 767px) {
    .menu__list {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .breadcrumbs {
        // max-width: 120px;
        max-width: inherit;
        margin-left: -15px;
    }

    .breadcrumbs__item:first-of-type {
        margin-left: 15px;
    }
}

.status {
    &__header_wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__header {
        display: flex;
        align-items: center;
        padding-bottom: 15px;
    }

    &__sum {
        font-size: 14px;
    }

    &__select {
        width: 155px;
        height: 36px;
        border-radius: 4px;
        margin-left: 100px;
        border: 1px solid #0f2649;
        appearance: none;
        padding: 2px 2px 2px 2px;
        background: transparent url("../../images/dropdown-arrow.png") no-repeat 60px;
        background-position: 5px;
    }

    &__buttons-header {
        margin-left: 20px;
        font-size: 14px;
        font-weight: bold;
        color: #0f2649;
    }

    &__button {
        width: 58px;
        height: 20px;
        border-radius: 10px;
        font-family: Arial;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        padding-top: 2px;
        color: #ffffff;
        margin-left: 10px;
        background-color: #f85f58;

        &:hover {
            color: #ffffff;
        }

        &--green {
            background-color: #3ec4a8;
        }

        &--yellow {
            background-color: #f2c819;
        }

        &--red {
            background-color: #f85f58;
        }

        &--gray {
            background-color: #d8d8d8;
        }

        &--darkgray {
            background-color: #a5a5a5;
        }

    }

    &__grid {
        &-header {
            display: grid;
            grid-template-columns: repeat(12, 86px);
            grid-template-rows: 44px;
            background-color: #f6f6f6;
            color: black;

            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-weight: bold;
                color: #0f2649;
            }

            &-fixed {
                display: flex;
                align-items: center;
                justify-content: start;
                padding-right: 4px;
                font-size: 12px;
                cursor: pointer;
            }

            &-button {
                grid-column-start: 12;
            }
        }

        &-container {
            display: grid;
            grid-template-columns: repeat(11, 81px);
            grid-auto-rows: 64px;
            grid-gap: 5px;
            max-height: 480px;
            overflow-y: auto;
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &__item {
        &-image {
            padding-bottom: 4px;

            &--small {
                width: 26px;
                height: 30px;
            }

            &--large {
                width: 40px;
                height: 40px;
            }
        }

        &-text {
            font-size: 11px;
            letter-spacing: 1px;
        }

        &--green {
            background-color: #3ec4a8;
        }

        &--yellow {
            background-color: #f2c819;
        }

        &--red {
            background-color: #f85f58;
        }

        &--gray {
            background-color: #e7e7e7;
        }

        &--darkgray {
            background-color: #a5a5a5;
        }

    }
}

.return-button {
    width: 100px;
    height: 20px;
    border-radius: 10px;
    font-family: Arial;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding-top: 2px;
    color: #ffffff;
    background-color: $dark_slate_blue;
    margin-right: 320px;

    &:hover {
        width: 110px;
        color: #ffffff;
    }
}

/* Tooltip container */

.item-tooltip {
    position: relative;
}

.item-tooltip .tooltiptext {
    visibility: hidden;
    width: 170px;
    height: 270px;
    left: -170px;
    top: 0px;
    background-color: #f6f6f6;
    border: 1px solid #b8b3b3;
    color: black;
    text-align: right;
    padding: 5px 0;
    position: absolute;
    z-index: 1;

    pre {
        border: none;
    }
}

.item-tooltip:hover .tooltiptext {
    visibility: visible;
}

.item-tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 30px;
    left: 100%;
    /* To the right of the tooltip */
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #f6f6f6;
}

.details {
    &__name {
        font-size: 16px;
        font-weight: bold;
        color: #0f2649;
        padding-bottom: 10px;
    }

    &__container {
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
    }

    &__right {
        width: 154px;
        height: 468px;
        background-color: #f6f6f6;
    }

    &__center {
        margin-right: 30px;
        margin-top: 20px;
        width: 45%;
        display: grid;
        grid-template-columns: repeat(6, 80px) auto;
        grid-auto-rows: 35px;
    }

    &__left {
        position: relative;
        top: -90px;
        width: 411px;
        margin-right: 50px;
        display: flex;
        flex-direction: column;

        &-image-container {
            height: 321px;
            background-color: #e1e1e1;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-image {
            width: 164px;
            height: auto;
            cursor: pointer;
        }

        &-button {
            position: relative;
            display: block;
            width: 81px;
            height: 28px;
            top: -14px;
            right: 50px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 14px;
            font-weight: bold;
        }

        &-text-container {
            height: 400px;
            background-color: #f6f6f6;
        }

        &-name {
            margin-right: 50px;
            margin-bottom: 20px;
            font-size: 34px;
            color: #0f2649;
        }

        &-text {
            display: grid;
            grid-template-columns: 170px 1fr;
            margin-right: 30px;
            color: #0f2649;
            font-size: 16px;
            line-height: 30px;

            strong {
                font-size: 18px;
            }
        }
    }
}

.line {
    grid-column: 1 / -1;
    height: 2px;
    border-bottom: 1px solid #cecbcb;
    width: 100%;
    padding-bottom: 10px;
}

.row {
    &__name {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
    }

    &__items {
        display: flex;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-text {
            font-size: 9px;
            color: #9b9b9b;
            padding-bottom: 4px;
        }

        &-button {
            display: block;
            width: 58px;
            height: 20px;
            border-radius: 10px;
            font-family: Arial;
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            padding-top: 2px;
            color: #ffffff;
            margin-left: 10px;

            &--green {
                background-color: #3ec4a8;
            }

            &--yellow {
                background-color: #f2c819;
            }

            &--red {
                background-color: #f85f58;
            }

            &--gray {
                background-color: #d8d8d8;
            }

            &--darkgray {
                background-color: #a5a5a5;
            }
        }
    }
}

.scales-formatter {
    background-color: red;
}

.red-icon {
    text-align: center;
}

.meir {
    width: 700px;
    position: relative;
    top: 23px;
    right: 340px;
}

.pointer {
    width: 100px;
    height: 40px;
    position: relative;
    background: red;

    &:hover {
        cursor: pointer;
    }
}

.pointer:after {
    content: "";
    position: absolute;
    right: -5px;
    bottom: 0;
    width: 0;
    height: 0;
    transform: rotate(-180deg);
    border-left: 20px solid white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.pointer:before {
    content: "";
    position: absolute;
    right: 60px;
    bottom: 0;
    width: 0;
    height: 0;
    transform: rotate(-180deg);
    border-left: 20px solid red;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.advd {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    width: 800px;
    top: 5px;
    right: 50px;
    background-color: black;
}