.summary__check {
  font-size: 16px;
}

.summary__title {
  font-weight: bold;
}

.summary__item {
  background-color: $dark_slate_blue;
  color: $white;
}

.report-page {
  .datepicker {
    .form_datepicker {
      display: inline-block;
    }
  }
}
