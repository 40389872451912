.form {
  border-radius: 20px;
  background-color: $white;
}

.form__body {
  padding: 30px;
}

.form__title {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px 30px;
  border-bottom: 1px solid $warm_grey;
  background-color: $white_two;
  color: $dark_slate_blue;
  font-size: 30px;
  font-weight: bold;
}

.form__input-wrap {
  position: relative;
  margin-bottom: 15px;

  &:hover {
    .input__icon {
      color: $white;
    }
  }
}

.form__input-wrap2 {
  position: relative;
  //margin-bottom: 3px;

  &:hover {
    .input__icon {
      display: none;
      //color: $white;
    }
  }
}

.form__input {
  width: 100%;
  background-color: $white;
  border: 1px solid $dark_slate_blue;
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 60px;
  transition: .5s;

  &:hover {
    background-color: $white-two;
    border-color: $white-two;
  }
}

.form__input_error {
  border-color: #a94442;
}

.input__icon {
  position: absolute;
  right: 20px;
  top: 16px;
  color: $greyish_two;
  font-size: 18px;
  transition: .5s;
  pointer-events: none;
}

.form__label {
  color: $dark_slate_blue;
  font-weight: normal;
}

.form__login {
  width: 460px;

  .form__input {
    width: 100%;
    height: 52px;
    line-height: 50px;
  }

  .form__label {
    margin-left: 27px;
    margin-bottom: 5px;
  }

  .form__button {
    display: block;
    width: 100%;
    height: 52px;
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 2px 2px $navy;
  }
}

.form__link {
  float: right;
  margin-bottom: 15px;
  font-size: 16px;
  color: $dark_slate_blue;
  border-bottom: 1px dashed transparent;
  transition: .5s;

  &:hover {
    color: $dark_slate_blue;
    border-color: $dark_slate_blue;
  }
}

.form__search {
  .form__input-wrap {
    margin-bottom: 25px;
  }

  .form__input {
    height: 39px;
    padding-left: 60px;
    line-height: 37px;
  }

  .input__icon {
    right: auto;
    left: 15px;
    top: 10px;
    font-size: 19px;
    color: $dark_slate_blue;
  }
}

.checkbox {
  .checkbox__label {
    padding-left: 0;
  }
}

.checkbox__label {
  min-height: auto;

  &:before {
    content: "";
    display: inline-block;
    vertical-align: top;
    width: 17px;
    height: 17px;
    line-height: 15px;
    margin-right: 10px;
    border: 1px solid $white_four;
    background-color: $white;
    text-align: center;
  }
}

.checkbox__input:checked+.checkbox__label {
  &:before {
    content: "\e808";
    font-family: 'font-icon';
    transform: rotate(-90deg);
  }
}


@media only screen and (max-width: 767px) {
  .form__search {
    margin: 0 15px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .form__login {
    width: 100%;
  }

  .form__body {
    padding: 30px 15px;
  }
}